import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import { getFormatedPrice } from '../../../shared/utils/price';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getAdminMain } from '../../../entities/User/model/admin/selectors';

const Transactions = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    const mainData = useSelector(getAdminMain);

    return (
        <div className={`${cl.widget} ${className}`}>
            <TextV variant="h3" className={cl.title}>
                Транзакции
            </TextV>
            <div className={cl.inputs}>
                <div className={cl.read}>
                    <TextV className={cl.input_label} variant="small">
                        Всего транзакций
                    </TextV>
                    <TextV className={cl.input_value} variant="subtitle">
                        {mainData.totalTransaction.toLocaleString()}
                    </TextV>
                </div>
                <div className={cl.read}>
                    <TextV className={cl.input_label} variant="small">
                        Сумма транакций
                    </TextV>
                    <TextV className={cl.input_value} variant="subtitle">
                        {getFormatedPrice(mainData.totalSumTransaction)}
                    </TextV>
                </div>
            </div>
            <GradientButton onClick={() => navigate('/admin/transaction')} className={cl.button}>
                <TextV variant="small">Подробнее</TextV>
            </GradientButton>
        </div>
    );
};

export default Transactions;
