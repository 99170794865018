import { WithClassName } from '../../../types';
import TextV from '../../TextV';
import cl from './index.module.scss';

interface ContactMailProps extends WithClassName {
    title: string;
    mail: string;
    img: string;
    alt?: string;
}

const ContactMail = ({ className = '', title, mail, img, alt }: ContactMailProps): JSX.Element => {
    return (
        <div className={`${cl.contact} ${className}`}>
            <div className={cl.bg}></div>
            <div className={cl.img}>
                <img src={img} alt={alt ? alt : ''} />
            </div>
            <TextV className={cl.text}>{title}</TextV>
            <TextV className={cl.mail}>
                <a href={`mailto:${mail}`}>{mail}</a>
            </TextV>
        </div>
    );
};

export default ContactMail;
