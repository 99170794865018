import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
//@ts-ignore
import { AnnotationLabel } from 'react-annotation';
import cl from './index.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';

const geoUrl = '/assets/worldMap.json';

const WorldMap = () => {
    const [isShowAnotationRUS, setIsShowAnotationRUS] = useState(false);
    const markerRUSRef = useRef(null);

    const [isShowAnotationTHA, setIsShowAnotationTHA] = useState(false);
    const markerTHARef = useRef(null);

    const [isShowAnotationCOL, setIsShowAnotationCOL] = useState(false);
    const markerCOLRef = useRef(null);

    const [isShowAnotationUSA, setIsShowAnotationUSA] = useState(false);
    const markerUSARef = useRef(null);

    const [isShowAnotationGBR, setIsShowAnotationGBR] = useState(false);
    const markerGBRRef = useRef(null);

    const [isShowAnotationARE, setIsShowAnotationARE] = useState(false);
    const markerARERef = useRef(null);

    const [isShowAnotationSAU, setIsShowAnotationSAU] = useState(false);
    const markerSAURef = useRef(null);

    const [isShowAnotationTUR, setIsShowAnotationTUR] = useState(false);
    const markerTURRef = useRef(null);

    const [isShowAnotationMEX, setIsShowAnotationMEX] = useState(false);
    const markerMEXRef = useRef(null);

    const [isShowAnotationBRA, setIsShowAnotationBRA] = useState(false);
    const markerBRARef = useRef(null);

    const [isShowAnotationIDN, setIsShowAnotationIDN] = useState(false);
    const markerIDNRef = useRef(null);

    const [isShowAnotationKEN, setIsShowAnotationKEN] = useState(false);
    const markerKENRef = useRef(null);

    const navigate = useNavigate();

    const handleMouseEnter = (geo: any) => {
        console.log(geo);

        const id = geo.id;

        // const path = geoPath();
        if (id === 'CIS') {
            setIsShowAnotationRUS(true);
            document
                .querySelectorAll('.data-cis')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'THA') {
            setIsShowAnotationTHA(true);
            document
                .querySelectorAll('.data-tha')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'COL') {
            setIsShowAnotationCOL(true);
            document
                .querySelectorAll('.data-col')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'USA') {
            setIsShowAnotationUSA(true);
            document
                .querySelectorAll('.data-usa')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'GBR') {
            setIsShowAnotationGBR(true);
            document
                .querySelectorAll('.data-gbr')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'ARE') {
            setIsShowAnotationARE(true);
            document
                .querySelectorAll('.data-are')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'SAU') {
            setIsShowAnotationSAU(true);
            document
                .querySelectorAll('.data-sau')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'TUR') {
            setIsShowAnotationTUR(true);
            document
                .querySelectorAll('.data-tur')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'MEX') {
            setIsShowAnotationMEX(true);
            document
                .querySelectorAll('.data-mex')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'BRA') {
            setIsShowAnotationBRA(true);
            document
                .querySelectorAll('.data-bra')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'IDN') {
            setIsShowAnotationIDN(true);
            document
                .querySelectorAll('.data-idn')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
        if (id === 'KEN') {
            setIsShowAnotationKEN(true);
            document
                .querySelectorAll('.data-ken')
                .forEach((el) => el.classList.add(cl['country--active']));
        }
    };

    const handleMouseLeave = (geo: any) => {
        const id = geo.id;

        if (id === 'CIS') {
            setIsShowAnotationRUS(false);
            document
                .querySelectorAll('.data-cis')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'THA') {
            setIsShowAnotationTHA(false);
            document
                .querySelectorAll('.data-tha')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'COL') {
            setIsShowAnotationCOL(false);
            document
                .querySelectorAll('.data-col')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'USA') {
            setIsShowAnotationUSA(false);
            document
                .querySelectorAll('.data-usa')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'GBR') {
            setIsShowAnotationGBR(false);
            document
                .querySelectorAll('.data-gbr')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'ARE') {
            setIsShowAnotationARE(false);
            document
                .querySelectorAll('.data-are')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'SAU') {
            setIsShowAnotationSAU(false);
            document
                .querySelectorAll('.data-sau')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'TUR') {
            setIsShowAnotationTUR(false);
            document
                .querySelectorAll('.data-tur')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'MEX') {
            setIsShowAnotationMEX(false);
            document
                .querySelectorAll('.data-mex')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'BRA') {
            setIsShowAnotationBRA(false);
            document
                .querySelectorAll('.data-bra')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'IDN') {
            setIsShowAnotationIDN(false);
            document
                .querySelectorAll('.data-idn')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
        if (id === 'KEN') {
            setIsShowAnotationKEN(false);
            document
                .querySelectorAll('.data-ken')
                .forEach((el) => el.classList.remove(cl['country--active']));
        }
    };

    /*scroll */
    const trackRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const handleScrollContent = () => {
        const thumbEle = thumbRef.current;
        const contentEle = contentRef.current;
        if (!thumbEle || !contentEle) {
            return;
        }
        thumbEle.style.left = `${(contentEle.scrollLeft * 100) / contentEle.scrollWidth}%`;
    };

    const handleClickTrack = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const trackEle = trackRef.current;
        const contentEle = contentRef.current;
        if (!trackEle || !contentEle) {
            return;
        }
        const bound = trackEle.getBoundingClientRect();
        const percentage = (e.clientX - bound.left) / bound.width;
        contentEle.scrollLeft = percentage * (contentEle.scrollWidth - contentEle.clientWidth);
    };

    useEffect(() => {
        const thumbEle = thumbRef.current;
        const contentEle = contentRef.current;
        if (!thumbEle || !contentEle) {
            return;
        }
        contentEle.scrollLeft = contentEle.offsetWidth / 2;
        thumbEle.style.left = '50%';
    }, []);

    const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const ele = thumbRef.current;
        const contentEle = contentRef.current;
        if (!ele || !contentEle) {
            return;
        }
        const startPos = {
            left: contentEle.scrollLeft,
            x: e.clientX,
            y: e.clientY,
        };

        const handleMouseMove = (e: MouseEvent) => {
            const dx = e.clientX - startPos.x;
            const dy = e.clientY - startPos.y;
            const scrollRatio = contentEle.clientWidth / contentEle.clientWidth;
            contentEle.scrollLeft = startPos.left + dx / scrollRatio;
            updateCursor(ele);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor(ele);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        const ele = thumbRef.current;
        const contentEle = contentRef.current;
        if (!ele || !contentEle) {
            return;
        }
        const touch = e.touches[0];
        const startPos = {
            left: contentEle.scrollLeft,
            x: touch.clientX,
            y: touch.clientY,
        };

        const handleTouchMove = (e: TouchEvent) => {
            const touch = e.touches[0];
            const dx = touch.clientX - startPos.x;
            const dy = touch.clientY - startPos.y;
            const scrollRatio = contentEle.clientWidth / contentEle.scrollWidth;
            contentEle.scrollLeft = startPos.left + dx / scrollRatio;
            updateCursor(ele);
        };

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            resetCursor(ele);
        };

        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const updateCursor = (ele: HTMLDivElement) => {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
        document.body.style.cursor = 'grabbing';
        document.body.style.userSelect = 'none';
    };

    const resetCursor = (ele: HTMLDivElement) => {
        ele.style.cursor = 'grab';
        ele.style.userSelect = '';
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
    };

    return (
        <>
            <div className={cl.map_container} ref={contentRef} onScroll={handleScrollContent}>
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography
                                    className={
                                        geo.properties?.area
                                            ? cl.country +
                                              ` data-${String(geo.id).toLowerCase()}`
                                            : cl['country--disabled']
                                    }
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onMouseEnter={() => handleMouseEnter(geo)}
                                    onMouseLeave={() => handleMouseLeave(geo)}
                                    // onClick={() => {
                                    //     handleClick(geo);
                                    // }}
                                />
                            ))
                        }
                    </Geographies>
                    {/* RUS */}
                    <CSSTransition
                        nodeRef={markerRUSRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationRUS}>
                        <Marker
                            coordinates={[90.87646708493985, 61.98089918284615]}
                            ref={markerRUSRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={-80}
                                dx={20}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Russia',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    bgPadding: 20,
                                    padding: 5,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* THA */}
                    <CSSTransition
                        nodeRef={markerTHARef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationTHA}>
                        <Marker
                            coordinates={[99.21, 13.27]}
                            ref={markerTHARef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={30}
                                dx={-80}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Thailand',
                                    align: 'right',
                                    padding: -25,
                                    bgPadding: 4,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* COL */}
                    <CSSTransition
                        nodeRef={markerCOLRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationCOL}>
                        <Marker
                            coordinates={[-72.36, 8]}
                            ref={markerCOLRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={10}
                                dx={75}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Columbia',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    bgPadding: 20,
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* USA */}
                    <CSSTransition
                        nodeRef={markerUSARef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationUSA}>
                        <Marker
                            coordinates={[-104.46, 29.57]}
                            ref={markerUSARef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={115}
                                dx={50}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'USA',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    bgPadding: 20,
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* GBR */}
                    <CSSTransition
                        nodeRef={markerGBRRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationGBR}>
                        <Marker
                            coordinates={[-3.62, 50.23]}
                            ref={markerGBRRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={70}
                                dx={80}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'UK',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    bgPadding: 20,
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* ARE */}
                    <CSSTransition
                        nodeRef={markerARERef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationARE}>
                        <Marker
                            coordinates={[55.89, 24.92]}
                            ref={markerARERef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={50}
                                dx={110}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'United Arab Emirates',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    bgPadding: 20,
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* SAU */}
                    <CSSTransition
                        nodeRef={markerSAURef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationSAU}>
                        <Marker
                            coordinates={[46.57, 29.1]}
                            ref={markerSAURef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={60}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Saudic Arabia',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* TUR */}
                    <CSSTransition
                        nodeRef={markerTURRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationTUR}>
                        <Marker
                            coordinates={[30.39, 36.26]}
                            ref={markerTURRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={60}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Turkey',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* MEX */}
                    <CSSTransition
                        nodeRef={markerMEXRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationMEX}>
                        <Marker
                            coordinates={[-109.43, 23.19]}
                            ref={markerMEXRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={60}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Mexico',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* BRA */}
                    <CSSTransition
                        nodeRef={markerBRARef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationBRA}>
                        <Marker
                            coordinates={[-63.09, 3.77]}
                            ref={markerBRARef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={60}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Brazil',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* IDN */}
                    <CSSTransition
                        nodeRef={markerIDNRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationIDN}>
                        <Marker
                            coordinates={[126.18, -3.61]}
                            ref={markerIDNRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={-60}
                                color={'#fff'}
                                bgPadding={0}
                                padding={0}
                                note={{
                                    title: 'Indonesia',
                                    align: 'right',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                    {/* KEN */}
                    <CSSTransition
                        nodeRef={markerKENRef}
                        timeout={300}
                        classNames={'fade'}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={isShowAnotationKEN}>
                        <Marker
                            coordinates={[34.01, 4.25]}
                            ref={markerKENRef}
                            className={cl.marker}>
                            <AnnotationLabel
                                x={0}
                                y={0}
                                dy={35}
                                dx={60}
                                color={'#fff'}
                                bgPadding={20}
                                padding={20}
                                note={{
                                    title: 'Kenia',
                                    align: 'left',
                                    orientation: 'topBottom',
                                    padding: -25,
                                    titleColor: '#fff',
                                    lineType: 'horizontal',
                                }}
                            />
                        </Marker>
                    </CSSTransition>
                </ComposableMap>
            </div>
            <div className={cl.scrollbar}>
                <div className={cl.scrollbar__track} ref={trackRef} onClick={handleClickTrack} />
                <div
                    className={cl.scrollbar__thumb}
                    ref={thumbRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                />
            </div>
        </>
    );
};

export default WorldMap;
