import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSocket } from '../../../entities/Application/model/selectors';
import { Application, Message } from '../../../entities/Application/model/types';
import AddFileButton from '../../../features/AddFile/ui/AddFileButton';
import { WithClassName } from '../../../shared/types';
import ColorButton from '../../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import Input from '../../../shared/ui/Input';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import MessagesList from '../../../features/admin/MessageList';
import { Translator } from '@vitalets/google-translate-api';

interface ChatProps extends WithClassName {
    application: Application;
}

const Chat = ({ application, className = '' }: ChatProps): JSX.Element => {
    const [message, setMessage] = useState('');

    const socket = useSelector(getSocket);

    useEffect(() => {
        if (application.read && socket) {
            const data = {
                typeWS: 'enterChat',
                ticketId: application.id,
            };
            socket.send(JSON.stringify(data));
            console.log('enterChat');
        }

        const translate = async () => {
            const texts = application.messages.reduce((acc, cur) => acc + '<>' + cur.text, '');
            const t = new Translator(texts, { to: 'ru' });
            const translated = await t.translate();
            console.log(translated.text);
        };

        translate();
    }, [application.id]);

    const handleSendValue = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!socket || !message.trim()) return;
        const data = {
            typeWS: 'chatMessage',
            ticketId: application.id,
            message,
        };
        socket.send(JSON.stringify(data));
        setMessage('');
    };

    const handleClose = () => {
        if (!socket) return;

        const data = {
            typeWS: 'closeTicket',
            ticketId: application.id,
        };
        socket.send(JSON.stringify(data));
        setMessage('');
    };

    return (
        <div className={`${cl.chat} ${className}`}>
            <div className={cl.chat_up}>
                <TextV variant="h3" className={cl.title}>
                    {application.title}
                </TextV>
                {application.status && (
                    <div className={cl.chat_up_btns}>
                        <GradientButton onClick={handleClose} className={cl.btn_close}>
                            <TextV variant="small">Закрыть тикет</TextV>
                        </GradientButton>
                    </div>
                )}
            </div>
            <MessagesList
                data={application.messages}
                className={cl.messages}
                user={{ name: application.author.username, avatart: application.author.avatar }}
            />
            {application.status ? (
                <form className={cl.panel} onSubmit={handleSendValue}>
                    <Input
                        className={cl.input}
                        startValue={message}
                        placeholder="Messagee"
                        textarea
                        onChange={(v) => setMessage(v)}
                    />
                    <div className={cl.bths}>
                        <AddFileButton accept="image/png, image/jpeg" />
                        <GradientButton formButton className={`${cl.btn} ${socket ? '' : cl.wait}`}>
                            <TextV variant="small">{socket ? 'Отправить' : 'Синхронизация'}</TextV>
                        </GradientButton>
                    </div>
                </form>
            ) : (
                <TextV className={cl.closed}>Заявка закрыта</TextV>
            )}
        </div>
    );
};

export default Chat;
