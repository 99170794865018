import { WithClassName } from '../../../shared/types';
import NavigationWithSub from './NavigationWithSub';
import cl from './index.module.scss';

const routes = [
    {
        text: 'About the company',
        to: '/#platform',
    },
    {
        text: 'Partnerships',
        to: '/partnerships',
    },
    {
        text: 'Contact us',
        to: '/contacts',
    }
    // {
    //     text: 'Vacancies',
    //     to: '/vacancies',
    // },
    // {
    //     text: 'Presentation',
    //     to: '/#',
    // },
];

const NavigationLanding = ({ className }: WithClassName) => {
    const scrollOnClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
        e.preventDefault();
        const el = document.getElementById(id);
        if (!el) return;
        el.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <nav className={`${cl.nav} ${cl.nav__landing}${className ? className : ''}`}>
            <a href="#tools" onClick={(e) => scrollOnClick(e, 'tools')}>
                Tools
            </a>
            <a href="#" onClick={(e) => scrollOnClick(e, 'platform')}>
                Multi-platform
            </a>
            <NavigationWithSub title="Information Center" mainHref="/contacts" subMenu={routes} />
        </nav>
    );
};

export default NavigationLanding;
