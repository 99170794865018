import axios from 'axios';
import { Event } from '../model/types';

const url = 'https://intelfin.io/api';

export const fetchEvents = async (offset: number, count: number) => {
    const { data } = await axios.post(
        `${url}/info/events`,
        { offset, count },
        { withCredentials: true },
    );

    return data;
};

export const fetchEventById = async (id: string): Promise<Event | null> => {
    const { data } = await axios.post(`${url}/info/events/post`, { id }, { withCredentials: true });

    if (!data.status) return null;

    return data.data.post;
};
