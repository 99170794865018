import { WithChildren, WithClassName } from '../../types';
import TextV from '../TextV';
import cl from './index.module.scss';

interface WidgetContainerProps extends WithChildren, WithClassName {
    title: string;
}

const WidgetContainer = ({ title, className, children }: WidgetContainerProps): JSX.Element => {
    return (
        <div className={`${cl.widget} ${className}`}>
            <TextV variant="h4" container="h2" className={cl.title}>
                {title}
            </TextV>
            <div className={cl.content}>{children}</div>
        </div>
    );
};

export default WidgetContainer;
