import { WithClassName } from '../../../types';

const ArrowSelect = ({ className = '' }: WithClassName): JSX.Element => {
    return (
        <svg
            className={className}
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Group 687">
                <rect
                    id="Rectangle 224"
                    x="10.0703"
                    y="1.53955"
                    width="6.52347"
                    height="0.978521"
                    rx="0.48926"
                    transform="rotate(135 10.0703 1.53955)"
                    fill="white"
                />
                <rect
                    id="Rectangle 225"
                    x="1.53516"
                    y="0.847656"
                    width="6.52347"
                    height="0.978521"
                    rx="0.48926"
                    transform="rotate(45 1.53516 0.847656)"
                    fill="white"
                />
            </g>
        </svg>
    );
};

export default ArrowSelect;
