import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import { TabSwitch } from '../model/types';
import cl from './index.module.scss';

interface TabsSwitcherProps extends WithClassName {
    tabs: TabSwitch[];
    curentValue: string;
    setValue: (value: string) => void;
}

const TabsSwitcher = ({
    tabs,
    curentValue,
    setValue,
    className = '',
}: TabsSwitcherProps): JSX.Element => {
    return (
        <div className={`${cl.tabs} ${className}`}>
            {tabs.map((tab) => (
                <GradientButton
                    key={tab.value}
                    onClick={() => setValue(tab.value)}
                    className={`${cl.tab} ${
                        tab.value != curentValue ? cl['tab--none-cheked'] : ''
                    }`}>
                    <TextV variant="small">{tab.label}</TextV>
                </GradientButton>
            ))}
        </div>
    );
};

export default TabsSwitcher;
