import { Link } from 'react-router-dom';
import Navigation from '../../features/Navigation';
import ProfilePanel from '../../features/Session/ui/ProfilePanel';
import Container from '../../shared/ui/Container';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import NavigationMobile from '../../features/Navigation/ui/NavigationMobile/NavigationMobile';
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import { showModal, hideModal } from '../../shared/ui/ModalContainer/api/modal';

interface HeaderProps {
    type?: 'landing' | 'default';
}

const Header = ({ type = 'default' }: HeaderProps) => {
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
    const mobNavRef = useRef<HTMLDivElement>(null);

    const handleClickMobMenu = () => {
        isShowMobileMenu ? hideModal() : showModal();
        setIsShowMobileMenu((prev) => !prev);
    };

    return (
        <header className={`${cl.header} ${type == 'default' ? cl['header--default'] : ''}`}>
            <Container className={cl.header__content}>
                <>
                    <div className={cl.mob_navigation} onClick={handleClickMobMenu}>
                        <img
                            src={
                                isShowMobileMenu
                                    ? '/assets/icons/mob_menu_open.svg'
                                    : '/assets/icons/burger-menu.svg'
                            }
                            alt="burger-menu"
                        />
                    </div>
                    <Link to="/" className={cl.link}>
                        <div className={cl.logo}>
                            <img src="/logo.png" alt="logo" />
                            <TextV variant="h3">Intelfin</TextV>
                        </div>
                    </Link>
                    <Navigation type={type}/>
                    <ProfilePanel />
                </>
            </Container>
            <CSSTransition
                nodeRef={mobNavRef}
                in={isShowMobileMenu}
                classNames="fade"
                mountOnEnter
                unmountOnExit
                timeout={300}>
                <div ref={mobNavRef} className={cl.wrapper_mob}>
                    <NavigationMobile
                        type={type}
                        onClick={() => {
                            setIsShowMobileMenu(false);
                            hideModal();
                        }}
                    />
                </div>
            </CSSTransition>
        </header>
    );
};

export default Header;
