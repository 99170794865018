import { adminWithdrawAprove } from '../../../../../entities/User/api/admin';
import { WithdrawStatus as WithdrawStatusT } from '../../../../../entities/User/model/admin/types';
import { WithClassName } from '../../../../../shared/types';
import TextV from '../../../../../shared/ui/TextV';
import cl from './index.module.scss';

interface WithdrawStatusProps extends WithClassName {
    id: string;
    status: WithdrawStatusT;
    updateStatus: () => void;
}

const WithdrawStatus = ({ id, status, updateStatus, className = '    ' }: WithdrawStatusProps) => {
    const handleClickButton = async (approve: boolean) => {
        const res = await adminWithdrawAprove(id, approve);
        if (!res.status) return;
        updateStatus();
    };

    switch (status) {
        case 'cancelled':
            return (
                <div className={cl.status}>
                    <TextV variant="subtitle" className={`${cl.error} ${className}`}>
                        Отклонено
                    </TextV>
                    <div className={cl.error_img}></div>
                </div>
            );
        case 'waiting':
            return (
                <div className={cl.buttons}>
                    <div className={cl.approve} onClick={() => handleClickButton(true)}></div>
                    <div className={cl.cancelled} onClick={() => handleClickButton(false)}></div>
                </div>
            );
        case 'init':
            return (
                <div className={cl.status}>
                    <TextV variant="subtitle" className={`${cl.success} ${className}`}>
                        Завершено
                    </TextV>
                    <div className={cl.success_img}></div>
                </div>
            );
        default:
            const neverCheck: never = status;
            return <></>;
    }
};

export default WithdrawStatus;
