import { useId, useState } from 'react';
import TextV from '../TextV';
import { WithClassName } from '../../types';
import cl from './index.module.scss';

interface InputSmallProps extends WithClassName {
    onChange?: (newValue: string) => void;
    startValue?: string;
    label?: string;
    placeholder?: string;
    error?: boolean;
    success?: boolean;
    readOnly?: boolean;
}

const InputSmall = ({
    onChange,
    label,
    placeholder = '',
    className = '',
    startValue = '',
    error = false,
    success = false,
    readOnly = false,
}: InputSmallProps) => {
    const [localValue, setLocalValue] = useState(startValue);
    const id = useId();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = e.target.value;
        setLocalValue(val);
        if (onChange) onChange(val);
    };

    return (
        <label
            htmlFor={id}
            className={`${cl.label} ${className} ${error ? cl['label--error'] : ''} ${
                success ? cl['label--success'] : ''
            } ${readOnly ? cl.readonly : ''}`}>
            {label && (
                <TextV className={cl.text} variant="small">
                    {label}
                </TextV>
            )}
            <input
                type="text"
                id={id}
                value={localValue}
                onChange={handleChange}
                placeholder={placeholder}
                className={cl.input}
                readOnly={readOnly}
            />
        </label>
    );
};

export default InputSmall;
