import { Outlet } from 'react-router-dom';
import cl from './index.module.scss';
import ApplicationList from '../../../widgets/admin/ApplicationList';

const ApplicationsAdminPage = () => {
    return (
        <div className={cl.page}>
            <ApplicationList className={cl.list} />
            <div className={cl.chat_widget}>
                <Outlet />
            </div>
        </div>
    );
};

export default ApplicationsAdminPage;
