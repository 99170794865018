import { useSelector } from 'react-redux';
import Panel from './Panel';
import RegisterButton from './RegisterButton';
import { getCurrentUser } from '../../model/selectors';

const ProfilePanel = () => {
    const currentUser = useSelector(getCurrentUser);

    if (!currentUser) return <RegisterButton />;

    return <Panel />;
};

export default ProfilePanel;
