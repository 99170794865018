import { useSelector } from 'react-redux';
import cl from './index.module.scss';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../../Session/model/selectors';
import { WithClassName } from '../../../../shared/types';
import NavigationWithSubMobile from './NavigationWithSubMobile';

const routesUser = [
    {
        text: 'Cabinet',
        to: '/profile',
    },
    {
        text: 'Referrals',
        to: '/team',
    },
    {
        text: 'History',
        to: '/history',
    },
    {
        text: 'Support',
        to: '/support',
    },
];
const routesInfo = [
    {
        text: 'About the company',
        to: '/#platform',
    },
    {
        text: 'Marketing',
        to: '/marketing',
    },
    {
        text: 'Press & Media',
        to: '/media',
    },
    {
        text: 'Contact us',
        to: '/contacts',
    }
    // {
    //     text: 'Vacancies',
    //     to: '/vacancies',
    // },
    // {
    //     text: 'Presentation',
    //     to: '/#',
    // },
];

interface NavigationMobileDefaultProps extends WithClassName {
    onClick?: () => void;
}


const NavigationMobileDefault = ({className, onClick}: NavigationMobileDefaultProps ) => {
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser)
        return (
            <nav className={`${cl.nav} ${cl.nav__default}${className ? className : ''}`}>
                <Link to="/login" onClick={onClick} >User</Link>
                <Link to="/login" onClick={onClick} >Company products</Link>
                <NavigationWithSubMobile
                    title="Information Center"
                    mainHref="/contacts"
                    subMenu={routesInfo}
                    onClick={onClick}
                />
            </nav>
        );

    return (
        <nav className={`${cl.nav} ${cl.nav__default} ${className ? className : ''}`}>
            <Link to="/main" onClick={onClick}>
                Main
            </Link>
            <NavigationWithSubMobile
                title="User"
                mainHref="/profile"
                subMenu={routesUser}
                onClick={onClick}
            />
            <Link to="/contract" onClick={onClick}>
                Contract
            </Link>
            <NavigationWithSubMobile
                title="Information Center"
                mainHref="/contacts"
                subMenu={routesInfo}
                onClick={onClick}
            />
        </nav>
    );
};

export default NavigationMobileDefault;
