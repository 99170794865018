import SocalAuthes from '../../features/SocalAuthes';
import RegisterForm from '../../widgets/RegisterForm';
import cl from './index.module.scss';

const RegisterPage = () => {
    return (
        <div className={cl.wrapper}>
            <RegisterForm className={cl.form} />
            {/* <SocalAuthes className={cl.socal} /> */}
        </div>
    );
};

export default RegisterPage;
