import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../../shared/types';
import ColorButton from '../../../shared/ui/Buttons/ColorButton';
import TextV from '../../../shared/ui/TextV';
import { cuteFormateDate } from '../../../shared/utils/date';
import { getFormatedImg } from '../../User/lib/img';
import { News } from '../model/types';
import cl from './index.module.scss';

interface NewsCardProps extends WithClassName {
    news: News;
}

const NewsCard = ({ news, className }: NewsCardProps): JSX.Element => {
    const navigate = useNavigate();
    return (
        <div className={`${cl.card} ${className ? className : ''}`}>
            <img src={getFormatedImg(news.image)} alt="" />
            <TextV className={cl.date} variant="small">
                {cuteFormateDate(new Date(news.date).getTime())}
            </TextV>
            <TextV className={cl.title} variant="subtitle">
                {news.title}
            </TextV>
            <TextV className={cl.desc}>{news.descr}</TextV>
            <ColorButton onClick={() => navigate(`/blog/${news._id}`)} className={cl.button}>
                <TextV variant="small">Read More</TextV>
            </ColorButton>
        </div>
    );
};

export default NewsCard;
