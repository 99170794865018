import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    AdminHistoryDeposit,
    AdminHistoryTransfer,
    AdminHistoryWithdraw,
    AdminMainInformation,
    AdminUser,
    AdminUsers,
} from './types';
import { setAdminUsers, setAdminHistory, updateAdminMain } from './async';

interface AdminSlice {
    main: AdminMainInformation;
    users: AdminUsers;
    history: {
        deposit: AdminHistoryDeposit;
        withdraw: AdminHistoryWithdraw;
        transfer: AdminHistoryTransfer;
    };
}

const initialState: AdminSlice = {
    main: {
        totalDeposit: 0,
        totalDividends: 0,
        totalNewSupport: 0,
        totalSumTransaction: 0,
        totalSupport: 0,
        totalTransaction: 0,
        totalUsers: 0,
        totalWithdraw: 0,
    },
    users: {
        page: 0,
        total: 0,
        data: [],
    },
    history: {
        deposit: {
            page: 0,
            total: 0,
            data: [],
        },
        withdraw: {
            page: 0,
            total: 0,
            data: [],
        },
        transfer: {
            page: 0,
            total: 0,
            data: [],
        },
    },
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(updateAdminMain.fulfilled, (state, action) => {
            state.main = action.payload;
        });
        builder.addCase(updateAdminMain.rejected, (_, action) => {
            console.log(action);
        });
        builder.addCase(setAdminUsers.fulfilled, (state, action) => {
            if (action.payload) state.users = action.payload;
        });
        builder.addCase(setAdminUsers.rejected, (_, action) => {
            console.log(action);
        });
        builder.addCase(setAdminHistory.fulfilled, (state, action) => {
            if (action.payload) state.history[action.payload.type] = action.payload.content;
        });
        builder.addCase(setAdminHistory.rejected, (_, action) => {
            console.log(action);
        });
    },
});

export default adminSlice.reducer;
