import axios from 'axios';
import { User } from '../../../entities/User/model/types';
import { WithStatus } from '../../../shared/types';

const url = 'https://intelfin.io/api';

interface registerUserProps {
    username: string;
    email: string;
    password: string;
    ref?: string;
}

export const registerUser = async (props: registerUserProps) => {
    const { data } = await axios.post(`${url}/auth/register`, props, {
        withCredentials: true,
    });

    return data;
};

interface registerUserVerifyProps {
    username: string;
    email: string;
    ref?: string;
}

export const registerUserVerify = async (props: registerUserVerifyProps) => {
    const { data } = await axios.post(`${url}/auth/register/verify`, props, {
        withCredentials: true,
    });

    return data;
};

interface loginUserProps {
    login: string;
    password: string;
    remember: boolean;
}

export const loginUser = async (props: loginUserProps) => {
    const { data } = await axios.post<{ data: User } & WithStatus>(`${url}/auth/login`, props, {
        withCredentials: true,
    });

    if (!data.status) return data;

    return data;
};

export const sendRecoveryToEmail = async (email: string) => {
    const { data } = await axios.post(
        `${url}/auth/recoverycode`,
        {
            email,
        },
        { withCredentials: true },
    );

    return data;
};

interface recoveryPasswordProps {
    email: string;
    recoveryCode: number;
    password: string;
}

export const recoveryPassword = async (dataSend: recoveryPasswordProps) => {
    const { data } = await axios.post(`${url}/auth/recovery`, dataSend, { withCredentials: true });

    return data;
};

export const logoutUser = async (all: boolean) => {
    const { data } = await axios.post(
        `${url}/user/logout`,
        {
            all,
        },
        { withCredentials: true },
    );

    return data;
};

export const googleAuth = async () => {
    const { data } = await axios.post(
        `${url}/user/profile/googleAuth`,
        {},
        { withCredentials: true },
    );

    return data;
};