import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from './types';
import { setNews } from './async';

interface BlogSlise {
    news: {
        total: number;
        data: News[];
        offset: number;
    };
}

const initialState: BlogSlise = {
    news: {
        total: 0,
        data: [],
        offset: 0,
    },
};

const blogSlise = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setNews.fulfilled, (state, action) => {
            if (action.payload) state.news = action.payload;
        });
        builder.addCase(setNews.rejected, (_, action) => {
            console.log(action);
        });
    },
});

export default blogSlise.reducer;
