import { useEffect, useLayoutEffect, useState } from 'react';
import cl from './index.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabSwitch } from '../../../features/TabsSwitcher/model/types';
import TabsSwitcher from '../../../features/TabsSwitcher/ui';
import { WithClassName, WithChildren } from '../../../shared/types';

const tabs: TabSwitch[] = [
    {
        label: 'Пополнения',
        value: 'deposit',
    },
    {
        label: 'Выводы',
        value: 'withdraw',
    },
];

interface TransactionsHistoryProps extends WithClassName, WithChildren {}

const TransactionsHistory = ({ children, className = '' }: TransactionsHistoryProps) => {
    const [currentTab, setCurrentTab] = useState('transfer');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.replaceAll('/', '') == 'admintransaction') {
            navigate('/admin/transaction/withdraw');
        }
    }, []);

    useLayoutEffect(() => {
        if (location.pathname.replaceAll('/', '') !== 'admintransaction') {
            setCurrentTab(location.pathname.split('/transaction/')[1]);
        }
    });

    const onChangeTab = (value: string) => {
        setCurrentTab(value);
        navigate(`/admin/transaction/${value}`);
    };

    return (
        <div className={`${cl.history} ${className}`}>
            <TabsSwitcher
                tabs={tabs}
                curentValue={currentTab}
                setValue={onChangeTab}
                className={cl.tabs}
            />
            {children}
        </div>
    );
};

export default TransactionsHistory;
