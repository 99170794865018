import { Outlet } from 'react-router-dom';
import Header from '../../../widgets/Header';
import Footer from '../../../widgets/Footer';
import Container from '../../../shared/ui/Container';
import Configurate from '../../../app/Configurate';

const PageContainer = () => {
    return (
        <>
            <Configurate />
            <Header />
            <main>
                <Container>
                    <Outlet />
                </Container>
            </main>
            <Footer />
        </>
    );
};

export default PageContainer;
