import { NavigationElement } from '../../model/types';
import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

interface NavigationWithSubProps {
    title: string;
    mainHref?: string;
    subMenu: NavigationElement[];
}

const NavigationWithSub = ({ title, mainHref, subMenu }: NavigationWithSubProps): JSX.Element => {
    const [isShowSub, setIsShowSub] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const subRef = useRef(null);

    const handleCloseSub = () => {
        const timer = setTimeout(() => {
            setIsShowSub(false);
        }, 150);

        setTimerId(timer);
    };

    const handleOpenSub = () => {
        setIsShowSub(true);
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
    };

    return (
        <div className={cl.has_sub} onMouseEnter={handleOpenSub} onMouseLeave={handleCloseSub}>
            <Link to={mainHref ? mainHref : ''}>{title}</Link>
            <CSSTransition
                in={isShowSub}
                mountOnEnter
                unmountOnExit
                timeout={300}
                nodeRef={subRef}
                classNames="fade">
                <div className={cl.sub_menu} ref={subRef}>
                    {subMenu.map((el, i) => (
                        <Link to={el.to} key={i}>
                            {el.text}
                        </Link>
                    ))}
                </div>
            </CSSTransition>
        </div>
    );
};

export default NavigationWithSub;
