import { useEffect, useMemo, useRef, useState } from 'react';
import Info from '../../shared/ui/Info';
import RadianBox from '../../shared/ui/RadianBox';
import TextV from '../../shared/ui/TextV';
import { getFormatedPrice } from '../../shared/utils/price';
import ContractInfo from '../../widgets/ContractInfo';
import ContractInvest from '../../widgets/ContractInvest';
import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getContract } from '../../entities/Contract/model/selectors';
import { useAppDispatch } from '../../app/store';
import { getActiveContracts } from '../../entities/Contract/api';
import { setContract } from '../../entities/Contract/model/slice';
import { getCurrentUser } from '../../features/Session/model/selectors';
import { getUserRangInfoBySum } from '../../features/UserRangs/lib/utils';

const ContractPage = () => {
    const [isInvestOpen, setIsInvestOpen] = useState(false);
    const investRef = useRef<HTMLDivElement>(null);
    const currentUser = useSelector(getCurrentUser);
    const contract = useSelector(getContract);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const f = async () => {
            const contract = await getActiveContracts();
            console.log(contract);

            dispatch(setContract(contract));
        };
        f();
    }, [currentUser]);

    const handleInvestClick = () => {
        window.scrollTo({
            top: investRef?.current?.scrollHeight,
            behavior: 'smooth',
        });

        setIsInvestOpen(true);
    };

    console.log(contract);

    const divPerDay = useMemo(() => {
        let res = 0;
        [contract].map((el) => {
            if (!el) return;
            const dateStart = new Date(el.invests[0].investDate);
            const dateEnd: Date = new Date(el.invests[0].dividendsDate);
            const progressEnd = dateEnd.getTime() - Date.now();
            let nowDividendsVar = 0;
            let dividendsPerDayVar = 0;

            el.invests.map((i) => {
                const percent = i.percent;

                let fullDividendsDate = new Date(i.investDate); //divDate
                let fullPeriod = dateEnd.getTime() - i.investDate; //difDate
                let nowPeriod = Date.now() - i.investDate;

                nowDividendsVar +=
                    (((i.cost / 100) * percent) / fullPeriod) * nowPeriod > 0
                        ? (((i.cost / 100) * percent) / fullPeriod) * nowPeriod
                        : 0; //right now dividends

                fullDividendsDate.setHours(fullDividendsDate.getHours() + 24);

                dividendsPerDayVar +=
                    ((i.cost / 100) * i.percent) /
                    ((fullDividendsDate.getTime() - i.investDate) / 86400000); //perDay
            });
            res += dividendsPerDayVar;
        });
        return res;
    }, [contract]);

    if (!currentUser) return <></>;

    const rang = getUserRangInfoBySum(contract ? contract.cost : 0);

    return (
        <div className={cl.page}>
            <TextV variant="h3" className={cl.title}>
                Contract
            </TextV>
            <div className={cl.content}>
                <div className={cl.up_infos}>
                    <RadianBox active={true} className={cl.info_box}>
                        <div className={cl.info_box_text}>
                            <TextV className={cl.info_box_title}>Profit for the whole time</TextV>
                            <TextV variant="h2" className={cl.info_box_value}>
                                {getFormatedPrice(contract?.dividends ? contract.dividends : 0)}
                            </TextV>
                        </div>
                    </RadianBox>
                    <RadianBox active={false} className={cl.info_box}>
                        <div className={cl.info_box_text}>
                            <TextV className={cl.info_box_title}>Current percentage</TextV>
                            <TextV variant="h2" className={cl.info_box_value}>
                                {`${contract?.percent ? contract.percent.toFixed(2) : 0}%`}
                            </TextV>
                        </div>
                    </RadianBox>
                    <RadianBox active={false} className={cl.info_box}>
                        <div className={cl.info_box_text}>
                            <TextV className={cl.info_box_title}>Profit for the month</TextV>
                            <TextV variant="h2" className={cl.info_box_value}>
                                {getFormatedPrice(divPerDay * 30)}
                            </TextV>
                        </div>
                    </RadianBox>
                    <RadianBox active={false} className={cl.info_box}>
                        <div className={cl.info_box_text}>
                            <TextV className={cl.info_box_title}>Profit for the day</TextV>
                            <TextV variant="h2" className={cl.info_box_value}>
                                {getFormatedPrice(divPerDay)}
                            </TextV>
                        </div>
                    </RadianBox>
                </div>
                {contract ? (
                    <ContractInfo onInvestClick={() => handleInvestClick()} />
                ) : (
                    <Info className={cl.info}>
                        <TextV variant="small">You have no active contracts.</TextV>
                    </Info>
                )}
                <CSSTransition
                    nodeRef={investRef}
                    in={isInvestOpen || !contract}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit
                    timeout={300}>
                    <div className={cl.invest} ref={investRef}>
                        <TextV variant="h3" className={cl.invest_title}>
                            {contract ? 'Reinvest' : 'Invest'}
                        </TextV>
                        <ContractInvest />
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default ContractPage;
