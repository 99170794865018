import { WithChildren, WithClassName } from '../../../types';
import cl from './index.module.scss';

interface ColorButtonProps extends WithChildren, WithClassName {
    onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    type?: 'ORANGE' | 'BLUE' | 'GREEN' | 'PURPLE' | 'WHITE';
    disabled?: boolean;
}

const classObj = {
    ORANGE: cl['button-orange'],
    BLUE: cl['button-blue'],
    GREEN: cl['button-green'],
    PURPLE: cl['button-purple'],
    WHITE: cl['button-white'],
};

const ColorButton = ({
    children,
    className,
    onClick,
    type = 'WHITE',
    disabled,
}: ColorButtonProps) => {
    const handleClick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <button
            onClick={handleClick}
            className={`${cl.button} ${classObj[type]} ${disabled ? cl.disabled : ''} ${
                className ? className : ''
            }`}>
            {children}
        </button>
    );
};

export default ColorButton;
