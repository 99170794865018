import { useEffect } from 'react';
import { getUser } from '../../entities/User/api';
import { setCurrentUser } from '../../features/Session/model/slice';
import { useAppDispatch } from '../store';
import { getActiveContracts } from '../../entities/Contract/api';
import { setContract } from '../../entities/Contract/model/slice';

const Configurate = () => {
    const dispath = useAppDispatch();

    useEffect(() => {
        const f = async () => {
            const user = await getUser();
            if (user.status) dispath(setCurrentUser(user.data));
        };
        f();
    }, []);
    return <></>;
};

export default Configurate;
