import TextV from '../../shared/ui/TextV';
import ContractsTable from '../../widgets/ContractsTable';
import cl from './index.module.scss';

const MarketingPage = () => {
    return (
        <div className={cl.page}>
            <TextV variant="h3" container="h1" className={cl.title}>
                Marketing
            </TextV>
            <ContractsTable className={cl.marketing} />
        </div>
    );
};

export default MarketingPage;
