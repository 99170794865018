import { useEffect } from 'react';
import TextV from '../../shared/ui/TextV';
import { getFormatedPrice } from '../../shared/utils/price';
import Blog from '../../widgets/admin/Blog';
import Media from '../../widgets/admin/Media';
import Support from '../../widgets/admin/Support';
import Transactions from '../../widgets/admin/Transactions';
import Users from '../../widgets/admin/Users';
import cl from './index.module.scss';
import { updateAdminMain } from '../../entities/User/model/admin/async';
import { useSelector } from 'react-redux';
import { getAdminMain } from '../../entities/User/model/admin/selectors';
import { useAppDispatch } from '../../app/store';

const AdminPage = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(updateAdminMain());
    }, []);

    const mainData = useSelector(getAdminMain);

    return (
        <div className={cl.page}>
            <TextV variant="h3" className={cl.title}>
                Админ-панель
            </TextV>
            <div className={cl.content}>
                <div className={cl.ups}>
                    <div className={cl.up_info}>
                        <TextV className={cl.up_value}>
                            {getFormatedPrice(mainData.totalDeposit)}
                        </TextV>
                        <TextV variant="small" className={cl.up_label}>
                            Replenishment amount
                        </TextV>
                    </div>
                    <div className={cl.up_info}>
                        <TextV className={cl.up_value}>
                            {mainData.totalWithdraw.toLocaleString()}
                        </TextV>
                        <TextV variant="small" className={cl.up_label}>
                            Sum of findings
                        </TextV>
                    </div>
                    <div className={cl.up_info}>
                        <TextV className={cl.up_value}>
                            {mainData.totalUsers.toLocaleString()}
                        </TextV>
                        <TextV variant="small" className={cl.up_label}>
                            Number of users
                        </TextV>
                    </div>
                    <div className={cl.up_info}>
                        <TextV className={cl.up_value}>
                            {getFormatedPrice(mainData.totalDividends)}
                        </TextV>
                        <TextV variant="small" className={cl.up_label}>
                            Amount of dividends
                        </TextV>
                    </div>
                </div>
                <Users className={cl.users} />
                <Transactions className={cl.transactions} />
                <div className={cl.socal}>
                    <Media className={cl.media} />
                    <Blog className={cl.blog} />
                </div>
                <Support className={cl.support} />
            </div>
        </div>
    );
};

export default AdminPage;
