import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavigationElement } from '../../../model/types';
import FinAccordion from '../../../../../shared/ui/FinAccordion';

interface NavigationWithSubProps {
    title: string;
    mainHref?: string;
    subMenu: NavigationElement[];
    onClick?: () => void;
}

const NavigationWithSubMobile = ({
    title,
    mainHref,
    subMenu,
    onClick
}: NavigationWithSubProps): JSX.Element => {
    return (
        <div className={cl.has_sub}>
            <FinAccordion title={title} className={cl.acc}>
                <div className={cl.sub_menu}>
                    {subMenu.map((el, i) => (
                        <Link to={el.to} key={i} onClick={onClick}>
                            {el.text}
                        </Link>
                    ))}
                </div>
            </FinAccordion>
        </div>
    );
};

export default NavigationWithSubMobile;
