import { useEffect, useState } from 'react';
import FinPagination from '../../features/FinPagination/ui';
import { WithClassName } from '../../shared/types';
import TextV from '../../shared/ui/TextV';
import { getFormatedPrice } from '../../shared/utils/price';
import cl from './index.module.scss';
import FinSelect from '../../features/FinSelect/ui';
import { getReferrals } from '../../entities/User/api';
import { useSelector } from 'react-redux';
import {
    getSelectedReferalLine,
    getSelectedUserReferals,
} from '../../entities/User/model/selectors';
import { useAppDispatch } from '../../app/store';
import {
    setCurrentLine,
    setReferals,
    setReferalsPage,
    setReferalsTotal,
} from '../../entities/User/model/slice';
import { getUserAvatar } from '../../entities/User/lib/img';

const lines = ['Line 1', 'Line 2', 'Line 3', 'Line 4', 'Line 5', 'Line 6', 'Line 7', 'Line 8',];
const perPage = 10;

const Team = ({ className = '' }: WithClassName) => {
    const [isLoading, setIsLoading] = useState(true);

    const currentLine = useSelector(getSelectedReferalLine);
    const { data, total, currentPage } = useSelector(getSelectedUserReferals);

    const totalPages = Math.ceil(total / perPage);

    const dispatch = useAppDispatch();

    /* получаем данные об рефераллах */
    useEffect(() => {
        const f = async () => {
            const l1 = getReferrals(0, perPage, 1);
            const l2 = getReferrals(0, perPage, 2);
            const l3 = getReferrals(0, perPage, 3);
            const l4 = getReferrals(0, perPage, 4);
            const l5 = getReferrals(0, perPage, 5);
            const l6 = getReferrals(0, perPage, 6);
            const l7 = getReferrals(0, perPage, 7);
            const l8 = getReferrals(0, perPage, 8);
            const res = await Promise.all([l1, l2, l3, l4, l5, l6, l7, l8]);

            dispatch(setReferals({ referals: res[0].data.referrals, line: 1 }));
            dispatch(setReferalsTotal({ total: res[0].data.total, line: 1 }));

            dispatch(setReferals({ referals: res[1].data.referrals, line: 2 }));
            dispatch(setReferalsTotal({ total: res[1].data.total, line: 2 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 3 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 3 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 4 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 4 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 5 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 5 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 6 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 6 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 7 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 7 }));

            dispatch(setReferals({ referals: res[2].data.referrals, line: 8 }));
            dispatch(setReferalsTotal({ total: res[2].data.total, line: 8 }));

            setIsLoading(false);
        };
        f();
    }, []);

    const handleChagePage = async (p: number) => {
        setIsLoading(true);
        dispatch(setReferalsPage({ page: p, line: currentLine }));
        const res = await getReferrals((p - 1) * perPage, perPage, currentLine);
        dispatch(setReferals({ referals: res.data.referrals, line: currentLine }));
        setIsLoading(false);
    };

    const handleChageSelect = (v: string) => {
        const line = Number(v.split(' ')[1]) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

        dispatch(setCurrentLine(line));
    };

    return (
        <div className={`${cl.team} ${className}`}>
            <div className={cl.up}>
                <span></span>
                <TextV variant="h3" container="h1" className={cl.title}>
                    Structure
                </TextV>
                <div className={cl.wrapper}>
                    <FinSelect
                        onChange={handleChageSelect}
                        values={lines}
                        currentValue={`Line ${currentLine}`}
                        className={cl.select}
                    />
                </div>
            </div>
            {isLoading ? (
                <TextV className={cl.loading}>Loading</TextV>
            ) : data.length > 0 ? (
                <table className={cl.table}>
                    <tbody>
                        <tr className={cl.table_up}>
                            <td></td>
                            <td>
                                <TextV className={cl.row_value_up}>Name</TextV>
                            </td>
                            <td>
                                <TextV className={cl.row_value_up}>Invested</TextV>
                            </td>
                            <td>
                                <TextV className={cl.row_value_up}>Profit</TextV>
                            </td>
                        </tr>

                        {data.map((el, i) => (
                            <tr className={cl.row} key={el.id}>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {`${i + 1 + (currentPage - 1) * perPage}.`}
                                    </TextV>
                                </td>
                                <td>
                                    <div className={cl.user}>
                                        <div className={cl.img}>
                                            <img
                                                src={getUserAvatar(el.avatar)}
                                                alt="profile photo"
                                            />
                                        </div>
                                        <TextV variant="subtitle">{el.username}</TextV>
                                    </div>
                                </td>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {getFormatedPrice(el.profit)}
                                    </TextV>
                                </td>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {getFormatedPrice(el.profit)}
                                    </TextV>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <TextV className={cl.empty}>You have no referrals on this line</TextV>
            )}
            {totalPages > 1 && (
                <FinPagination
                    count={totalPages}
                    curPage={currentPage}
                    onChangePage={handleChagePage}
                    className={cl.pagination}
                />
            )}
        </div>
    );
};

export default Team;
