import { createPortal } from 'react-dom';
import { WithChildren, WithClassName } from '../../../types';
import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import { useRef } from 'react';

interface ModalContainerProps extends WithChildren, WithClassName {
    isShowModal: boolean;
    handleClose: () => void;
}

const ModalContainer = ({
    children,
    handleClose,
    isShowModal,
    className = '',
}: ModalContainerProps): JSX.Element => {
    const modalRef = useRef(null);

    return createPortal(
        <CSSTransition
            nodeRef={modalRef}
            timeout={300}
            classNames="fade"
            in={isShowModal}
            mountOnEnter
            unmountOnExit>
            <div className={cl.modal} ref={modalRef}>
                <div className={`${cl.content} ${className}`}>
                    <div className={cl.close} aria-label="close modal" onClick={handleClose}>
                        <img src="/assets/icons/close.svg" alt="close modal" />
                    </div>
                    {children}
                </div>
            </div>
        </CSSTransition>,
        document.body,
    );
};

export default ModalContainer;