import axios from 'axios';

const url = 'https://intelfin.io/api';

export const getActiveContracts = async () => {
    const { data } = await axios.post(
        `${url}/contract/active`,
        { type: 'contract' },
        { withCredentials: true },
    );

    if (!data.status) return null;

    const contract = data.data.contracts.find((el: any) => el.name == 'contract');

    if (!contract) return null;

    return contract;
};

export const createContract = async (sum: number) => {
    const { data } = await axios.post(
        `${url}/contract/invest`,
        { sum },
        { withCredentials: true },
    );

    return data;
};
