import { useNavigate, useParams } from 'react-router-dom';
import cl from './index.module.scss';
import { useLayoutEffect } from 'react';
import TextV from '../../../../shared/ui/TextV';
import Chat from '../../../../widgets/admin/Chat';
import { useSelector } from 'react-redux';
import { getApplicationById } from '../../../../entities/Application/model/selectors';

const ApplicationAdminChatPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const application = useSelector(getApplicationById(id || 'null'));

    useLayoutEffect(() => {
        const windowWidth = document.body.offsetWidth;

        if (windowWidth <= 640) {
            navigate(`/admin/support/mob/${id}`);
        }
    }, [id]);

    const hadleClickBack = () => {
        navigate(`/admin/support/`);
    };

    if (!application) return <></>;

    return (
        <div className={cl.page}>
            <div className={cl.wrapper} onClick={hadleClickBack}>
                <TextV variant="small" className={cl.back}>
                    Back
                </TextV>
            </div>
            <Chat className={cl.chat} application={application} />
        </div>
    );
};

export default ApplicationAdminChatPage;
