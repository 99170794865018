import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application, Message } from './types';

interface ChatSlice {
    applications: Application[];
    socket: WebSocket | null;
}

const initialState: ChatSlice = {
    applications: [],
    socket: null,
};

const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        setApplications: (state, action: PayloadAction<Application[]>) => {
            state.applications = action.payload;
        },
        setSocket: (state, action: PayloadAction<WebSocket | null>) => {
            state.socket = action.payload;
        },
        addApplication: (state, action: PayloadAction<Application>) => {
            state.applications = [action.payload, ...state.applications];
        },
        addMessage: (state, action: PayloadAction<{ id: string; message: Message }>) => {
            const findedChat = state.applications.find((el) => el.id === action.payload.id);
            if (!findedChat) return;

            //выводим чат на первое место
            const chats = state.applications.filter((el) => el.id !== action.payload.id);

            findedChat.messages = [...findedChat.messages, action.payload.message];

            state.applications = [findedChat, ...chats];
        },

        setMessages: (state, action: PayloadAction<{ id: string; messages: Message[] }>) => {
            const findedChat = state.applications.find((el) => el.id === action.payload.id);
            if (!findedChat) return;

            findedChat.messages = action.payload.messages;
        },

        closeChat: (state, action: PayloadAction<string>) => {
            const finded = state.applications.find((el) => el.id === action.payload);
            if (!finded) return;

            finded.status = false;
        },
        changeStatus: (state, action: PayloadAction<{ ticketId: string; read: boolean }>) => {
            const finded = state.applications.find((el) => el.id === action.payload.ticketId);
            if (!finded) return;

            finded.read = action.payload.read ? action.payload.read : false;
        },
    },
});

export const {
    setApplications,
    setSocket,
    addApplication,
    addMessage,
    closeChat,
    changeStatus,
    setMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
