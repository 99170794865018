import CreateNewsForm from '../../../widgets/admin/CreateNewsForm';
import cl from './index.module.scss';

const CreateNews = () => {
    return (
        <div className={cl.page}>
            <CreateNewsForm />
        </div>
    );
};

export default CreateNews;
