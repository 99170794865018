import { useState } from 'react';
import { WithClassName } from '../../../../shared/types';
import ColorButton from '../../../../shared/ui/Buttons/ColorButton';
import { googleAuth } from '../../../Session/api';
import cl from './index.module.scss';
import { hideModal, showModal } from '../../../../shared/ui/ModalContainer/api/modal';
import ModalContainer from '../../../../shared/ui/ModalContainer/ui';
import TextV from '../../../../shared/ui/TextV';
import { getFormatedImg } from '../../../../entities/User/lib/img';
import Input from '../../../../shared/ui/Input';
import GradientButton from '../../../../shared/ui/Buttons/GradientButton';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../Session/model/selectors';
import { useAppDispatch } from '../../../../app/store';
import { setCurrentUser } from '../../../Session/model/slice';

const GoogleAuthButton = ({ className }: WithClassName) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [popUpData, setPopUpData] = useState({
        manualEntryKey: '',
        url: '',
    });

    const [timerId, setTimerId] = useState<null | NodeJS.Timeout>(null);

    const currentUser = useSelector(getCurrentUser);
    const dispatch = useAppDispatch();

    if (!currentUser) return <></>;

    const handleClose = () => {
        setIsShowModal(false);
        hideModal();
    };

    const handleClick = async () => {
        setIsLoading(true);
        const res = await googleAuth();

        setIsLoading(false);
        if (!res.status) {
            return;
        }

        if (currentUser.twoFactorSecretEnable) {
            dispatch(setCurrentUser(res.data.profile));
        } else {
            showModal();
            setIsShowModal(true);
            setPopUpData({
                manualEntryKey: res.data.manualEntryKey,
                url: res.data.url,
            });
            dispatch(setCurrentUser(res.data.profile));
        }
    };

    const handleClicCopy = () => {
        if (timerId) clearTimeout(timerId);

        navigator.clipboard.writeText(popUpData.manualEntryKey);

        const t = setTimeout(() => {
            clearTimeout(t);
            setTimerId(null);
        }, 4000);

        setTimerId(t);
    };

    return (
        <>
            <ColorButton onClick={handleClick} className={`${className} ${cl.button}`}>
                <>
                    <img src="/assets/icons/googleAuth.png" alt="googleAuth" />
                    {isLoading
                        ? 'Connecting...'
                        : currentUser.twoFactorSecretEnable
                        ? 'Disconnect Google Auth'
                        : 'Connect Google Auth'}
                </>
            </ColorButton>
            <ModalContainer
                isShowModal={isShowModal && popUpData.url?.length > 0}
                handleClose={handleClose}>
                <div className={cl.content}>
                    <TextV className={cl.title} variant="h3">
                        QR code
                    </TextV>
                    <div className={cl.img}>
                        <img src={popUpData.url} alt="qr-code" />
                    </div>
                    <div className={cl.inputs}>
                        <Input
                            readOnlyWithoutClass
                            startValue={popUpData.manualEntryKey}
                            className={cl.input}
                        />
                        <ColorButton onClick={handleClicCopy} className={cl.btn_copy}>
                            <TextV variant="small">{timerId ? 'Copied!' : 'Copy'}</TextV>
                        </ColorButton>
                    </div>
                    <GradientButton onClick={handleClose} className={cl.btn_done}>
                        <TextV variant="small">Done</TextV>
                    </GradientButton>
                </div>
            </ModalContainer>
        </>
    );
};

export default GoogleAuthButton;