import { SwiperSlide } from 'swiper/react';
import NewsCard from '../../entities/News/ui/NewsCard';
import SwiperBlur from '../../features/SwiperBlur/ui';
import { WithClassName } from '../../shared/types';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/store';
import { setNews } from '../../entities/News/model/async';
import { getNews } from '../../entities/News/model/selectors';

const NewsSlider = ({ className }: WithClassName): JSX.Element => {
    const { total, data } = useSelector(getNews);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (total == 0) {
            dispatch(setNews({ offset: 0, count: 100 }));
        }
    }, []);

    if (total === 0) return <></>;

    return (
        <section className={`${className ? className : ''}`}>
            <TextV variant="h2" container="h2" className={cl.title}>
                Блог
            </TextV>
            <SwiperBlur>
                {data.map((el, i) => (
                    <SwiperSlide className={cl.slide} key={i}>
                        <NewsCard news={el} key={i} className={cl.el} />
                    </SwiperSlide>
                ))}
            </SwiperBlur>
        </section>
    );
};

export default NewsSlider;
