import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WithChildren } from '../../../../shared/types';
import { getCurrentUser } from '../../model/selectors';
import { getUser } from '../../../../entities/User/api';
import { setCurrentUser } from '../../model/slice';
import { useAppDispatch } from '../../../../app/store';

const OnlyAuthorizedRoute = ({ children }: WithChildren) => {
    const navigate = useNavigate();
    const user = useSelector(getCurrentUser);
    const dispath = useAppDispatch();
    useEffect(() => {
        const f = async () => {
            if (user) return;
            const userNew = await getUser();
            if (!user && !userNew.status) navigate('/login');
            if (userNew.status) dispath(setCurrentUser(userNew.data));
        };
        f();
    }, [user]);

    if (!user) return <></>;

    return <>{children}</>;
};

export default OnlyAuthorizedRoute;
