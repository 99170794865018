import { useLayoutEffect, useState } from 'react';
import { WithClassName } from '../../shared/types';
import ColorButton from '../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import TextV from '../../shared/ui/TextV';
import { getFormatedPrice } from '../../shared/utils/price';
import cl from './index.module.scss';
import { cuteFormateDate, cuteTimerFormatObj } from '../../shared/utils/date';
import UserRangText from '../../features/UserRangs/ui/UserRangText';
import { getUserNextRangBySum, getUserRangInfoBySum } from '../../features/UserRangs/lib/utils';
import { useNavigate } from 'react-router-dom';
import { Contract } from '../../entities/Contract/model/types';
import { useSelector } from 'react-redux';
import { getContract } from '../../entities/Contract/model/selectors';
import { getCurrentUser } from '../../features/Session/model/selectors';

// const moneyNow = 13941;
// const moneyNext = 1432;

// const paymentTime = new Date(time.getTime() + 45400000);
// const startTime = new Date(time.getTime() - 45400000);

// const investSum = 4500;

interface ContractInfoProps extends WithClassName {
    onInvestClick: () => void;
}

const ContractInfo = ({ onInvestClick, className = '' }: ContractInfoProps) => {
    const time = new Date();
    const [timer, setTimer] = useState({
        hours: 0,
        minutes: 0,
        sec: 0,
    });
    const [moneyNext, setMoneyNext] = useState(0.0);
    const navigate = useNavigate();
    const currentUser = useSelector(getCurrentUser);
    const contract = useSelector(getContract);

    useLayoutEffect(() => {
        timerFunc();
        const i = setInterval(timerFunc, 1000);
        return () => clearInterval(i);
    }, []);

    if (!currentUser || !contract) return <></>;

    const investSum = currentUser.deposit;
    const moneyNow = contract.cost;
    const startTime = contract.invests[0].lastDate
        ? contract.invests[0].lastDate
        : contract.invests[0].investDate;
    const paymentTime = contract.invests[0].dividendsDate;

    const progress: number = ((new Date().getTime() - startTime) / (paymentTime - startTime)) * 100;

    const timerFunc = () => {
        const obj = cuteTimerFormatObj((paymentTime - new Date().getTime()) / 1000);
        setTimer(obj);
        handlePrice();
    };

    const handlePrice = () => {
        const dateStart = new Date(contract.invests[0].investDate);
        const dateEnd: Date = new Date(contract.invests[0].dividendsDate);
        const progressEnd = dateEnd.getTime() - Date.now();
        let nowDividendsVar = 0;
        let dividendsPerDayVar = 0;

        contract.invests.map((i) => {
            const percent = i.percent;

            let fullDividendsDate = new Date(i.investDate); //divDate
            let fullPeriod = dateEnd.getTime() - i.investDate; //difDate
            let nowPeriod = Date.now() - i.investDate;

            nowDividendsVar +=
                (((i.cost / 100) * percent) / fullPeriod) * nowPeriod > 0
                    ? (((i.cost / 100) * percent) / fullPeriod) * nowPeriod
                    : 0; //right now dividends

            fullDividendsDate.setHours(fullDividendsDate.getHours() + 24);

            dividendsPerDayVar +=
                ((i.cost / 100) * i.percent) /
                ((fullDividendsDate.getTime() - i.investDate) / 86400000); //perDay
        });

        console.log(nowDividendsVar)

        setMoneyNext(nowDividendsVar);
    };

    const moneyNextValue = Number(moneyNext.toFixed(4)) > 0 ? `${
        moneyNext.toFixed(4)
    }$` : `0,0000$`;

    const rang = getUserRangInfoBySum(contract.cost);
    const nextRang = getUserNextRangBySum(contract.cost);

    return (
        <div className={`${cl.widget} ${className}`}>
            <div className={cl.money}>
                <div className={cl.big_sums}>
                    <div className={cl.now}>
                        <TextV className={cl.sum_title}>The contract now</TextV>
                        <TextV className={cl.sum_value}>{getFormatedPrice(moneyNow)}</TextV>
                    </div>
                    <div className={cl.next}>
                        <TextV className={cl.sum_title}>Next installment</TextV>
                        <TextV className={cl.sum_value}>{'+' + moneyNextValue}</TextV>
                    </div>
                </div>
                <div className={cl.timer}>
                    <TextV className={cl.time_title}>Payment through</TextV>
                    <div className={cl.timer_numbers}>
                        <div className={cl.timer_numbers_value}>
                            <TextV variant="h1" className={cl.timer_number}>
                                {`${'00'.slice(timer.hours.toString().length)}${timer.hours}`}
                            </TextV>
                            <TextV variant="small" className={cl.timer_label}>
                                Hours
                            </TextV>
                        </div>
                        <TextV className={cl.timer_dots} variant="h1">
                            :
                        </TextV>
                        <div className={cl.timer_numbers_value}>
                            <TextV variant="h1" className={cl.timer_number}>
                                {`${'00'.slice(timer.minutes.toString().length)}${timer.minutes}`}
                            </TextV>
                            <TextV variant="small" className={cl.timer_label}>
                                Minutes
                            </TextV>
                        </div>
                        <TextV className={cl.timer_dots} variant="h1">
                            :
                        </TextV>
                        <div className={cl.timer_numbers_value}>
                            <TextV variant="h1" className={cl.timer_number}>
                                {`${'00'.slice(timer.sec.toString().length)}${timer.sec}`}
                            </TextV>
                            <TextV variant="small" className={cl.timer_label}>
                                Seconds
                            </TextV>
                        </div>
                    </div>
                    <div className={cl.timer_progress}>
                        <div
                            className={cl.timer_progress_value}
                            style={{ width: `${progress}%` }}></div>
                    </div>
                </div>
                <div className={cl.btns}>
                    <GradientButton onClick={() => onInvestClick()} className={cl.btn_invest}>
                        <TextV variant="small">Reinvest</TextV>
                    </GradientButton>
                    <ColorButton
                        onClick={() => navigate('/marketing')}
                        className={cl.btn_marketing}>
                        <TextV variant="small">Marketing</TextV>
                    </ColorButton>
                </div>
            </div>
            <div className={cl.details}>
                <TextV variant="subtitle" className={cl.details_title}>
                    Contract
                </TextV>
                <div className={cl.details_rows}>
                    <div className={cl.details_row}>
                        <TextV className={cl.row_title}>Start date</TextV>
                        <TextV className={cl.row_value} variant="subtitle">
                            {cuteFormateDate(new Date(contract.dateStart).getTime())}
                        </TextV>
                    </div>
                    <div className={cl.details_row}>
                        <TextV className={cl.row_title}>Profit</TextV>
                        <TextV className={cl.row_value} variant="subtitle">
                            {getFormatedPrice(contract.dividends)}
                        </TextV>
                    </div>
                    <div className={cl.details_row}>
                        <TextV className={cl.row_title}>It's been days</TextV>
                        <TextV className={cl.row_value} variant="subtitle">
                            {`${Math.floor(
                                new Date(
                                    time.getTime() - new Date(contract.dateStart).getTime(),
                                ).getTime() /
                                    1000 /
                                    60 /
                                    60 /
                                    24,
                            )} days`}
                        </TextV>
                    </div>
                    <div className={cl.details_row}>
                        <TextV className={cl.row_title}>Current status</TextV>
                        <TextV className={cl.row_value} variant="subtitle">
                            {currentUser.status != null ? currentUser.status : 'null'}
                        </TextV>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractInfo;
