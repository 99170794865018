import { WithClassName } from '../../../shared/types';
import Pagination from '@mui/material/Pagination';
import cl from './index.module.scss';
import { ChangeEvent } from 'react';

interface PaginationProps extends WithClassName {
    count: number;
    curPage: number;
    onChangePage: (newPage: number) => void;
}

const FinPagination = ({
    curPage,
    count,
    onChangePage,
    className = '',
}: PaginationProps): JSX.Element => {
    const onChange = (event: ChangeEvent<unknown>, page: number) => {
        onChangePage(page);
    };

    return (
        <Pagination
            hideNextButton
            hidePrevButton
            classes={{
                root: `${cl.pagination} ${className}`,
                ul: cl.paginations,
            }}
            page={curPage}
            onChange={onChange}
            count={count}
        />
    );
};

export default FinPagination;
