import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../../entities/User/model/types';

interface SliceSession {
    currnetUser: User | null;
}

const initialState: SliceSession = {
    currnetUser: null,
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<User | null>) => {
            state.currnetUser = action.payload;
        },
    },
});

export const { setCurrentUser } = sessionSlice.actions;

export default sessionSlice.reducer;
