import { useNavigate } from 'react-router-dom';
import GradientButton from '../../../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../../../shared/ui/TextV';
import cl from './index.module.scss';

const RegisterButton = () => {
    const navigator = useNavigate();
    return (
        <GradientButton onClick={() => navigator('/registration')} className={cl.button}>
            <TextV variant="small">Registration</TextV>
        </GradientButton>
    );
};

export default RegisterButton;
