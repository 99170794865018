import { WithChildren, WithClassName } from '../../types';
import cl from './index.module.scss';

interface InfoProps extends WithChildren, WithClassName {}

const Info = ({ children, className = '' }: InfoProps) => {
    return (
        <div className={`${cl.info} ${className}`}>
            <div className={cl.info_left}>
                <img src="/assets/icons/info.svg" alt="Информируем вас" />
            </div>
            <div className={cl.info_right}>{children}</div>
        </div>
    );
};

export default Info;
