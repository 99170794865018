import { WithClassName } from '../../../shared/types';
import cl from './index.module.scss';
import { Message } from '../../../entities/Application/model/types';
import { useEffect, useLayoutEffect, useRef } from 'react';
import MessageBox from '../../../entities/Application/ui/admin/MessageBox';
import { getUserAvatar } from '../../../entities/User/lib/img';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../Session/model/selectors';

interface MessagesListProps extends WithClassName {
    data: Message[];
    user: {
        name: string;
        avatart: string;
    };
}

const MessagesList = ({ user, className = '', data }: MessagesListProps): JSX.Element => {
    const messageRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!messageRef.current) return;

        messageRef.current.scrollTo({
            left: 0,
            top: messageRef.current.scrollHeight,
            behavior: 'smooth',
        });
    }, [messageRef, data]);

    return (
        <div className={`${cl.messages_wrapper} ${className}`} ref={messageRef}>
            <div className={cl.messages}>
                {data.map((m, i) => (
                    <MessageBox
                        userName={user.name}
                        message={m}
                        key={i}
                        needName={i === 0 || data[i - 1].isAdmin !== m.isAdmin}
                        isCurrentUserMessage={m.isAdmin}
                        isLastForUser={data.length - 1 === i || data[i + 1].isAdmin !== m.isAdmin}
                        avatar={m.isAdmin ? getUserAvatar(null) : getUserAvatar(user.avatart)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MessagesList;
