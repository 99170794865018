import { dataRang } from '../model/types';

export const dataRangs: dataRang[] = [
    {
        rang: 'default',
        minSum: 0,
        percent: 0,
    },
    {
        rang: 'partner',
        minSum: 100,
        percent: 4,
    },
    {
        rang: 'iron',
        minSum: 500,
        percent: 6,
    },
    {
        rang: 'aluminimum',
        minSum: 1500,
        percent: 8,
    },
    {
        rang: 'bronze',
        minSum: 3500,
        percent: 10,
    },
    {
        rang: 'tin',
        minSum: 5000,
        percent: 12,
    },
    {
        rang: 'nicel',
        minSum: 15000,
        percent: 14,
    },
    {
        rang: 'silver',
        minSum: 35000,
        percent: 16,
    },
    {
        rang: 'golden',
        minSum: 50000,
        percent: 18,
    },
    {
        rang: 'platinum',
        minSum: 75000,
        percent: 20,
    },
    {
        rang: 'diamond',
        minSum: 100000,
        percent: 22,
    },
];

/* получить информацию о ранге по текущей сумме */
export const getUserRangInfoBySum = (sum: number) => {
    const finded = [...dataRangs].reverse().find((el) => sum >= el.minSum);
    if (!finded) return dataRangs[0];

    return finded;
};

/* получить информацию о следущем ранге */
export const getUserNextRangBySum = (sum: number) => {
    const currentRang = getUserRangInfoBySum(sum);
    const i = dataRangs.findIndex((el) => el.rang == currentRang.rang);
    if (i == dataRangs.length - 1) return null;

    return dataRangs[i + 1];
};

/* получить информацию о предыдущем ранге */
export const getUserPrevRangBySum = (sum: number) => {
    const currentRang = getUserRangInfoBySum(sum);
    const i = dataRangs.findIndex((el) => el.rang == currentRang.rang);
    if (i == 0) return null;

    return dataRangs[i - 1];
};

/* получить список следущих рангов */
export const getUserNextRangesBySum = (sum: number) => {
    const currentRang = getUserRangInfoBySum(sum);
    const i = dataRangs.findIndex((el) => el.rang == currentRang.rang);
    if (i == dataRangs.length - 1) return [];

    return dataRangs.slice(i + 1);
};
