import axios from 'axios';
import { News } from '../model/types';

const url = 'https://intelfin.io/api';

export const fetchNews = async (offset: number, count: number) => {
    const { data } = await axios.post(
        `${url}/info/blog`,
        { offset, count },
        { withCredentials: true },
    );

    return data;
};

export const fetchNewsById = async (id: string): Promise<News | null> => {
    const { data } = await axios.post(`${url}/info/blog/post`, { id }, { withCredentials: true });

    if (!data.status) return null;

    return data.data.post;
};
