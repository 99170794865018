import { useSelector } from 'react-redux';
import { WithClassName } from '../../shared/types';
import TextV from '../../shared/ui/TextV';
import WidgetContainer from '../../shared/ui/WidgetContainer';
import { cuteFormateDateOnlyNumbers } from '../../shared/utils/date';
import { getFormatedPrice } from '../../shared/utils/price';
import cl from './index.module.scss';
import { getCurrentUser } from '../../features/Session/model/selectors';
import { getContract } from '../../entities/Contract/model/selectors';
import { useEffect, useMemo } from 'react';
import { getActiveContracts } from '../../entities/Contract/api';
import { setContract } from '../../entities/Contract/model/slice';
import { useAppDispatch } from '../../app/store';

// const userInfo = {
//     dateRegister: 1706578912,
//     inviter: 'Username',
//     days: 365,
//     money: 1351,
// };

const UserStats = ({ className = '' }: WithClassName): JSX.Element => {
    const currentUser = useSelector(getCurrentUser);
    const contract = useSelector(getContract);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const f = async () => {
            if (!contract) {
                const contractNew = await getActiveContracts();
                dispatch(setContract(contractNew));
            }
        };
        f();
    }, [currentUser]);

    const divPerDay = useMemo(() => {
        let res = 0;
        [contract].map((el) => {
            if (!el) return;
            const dateStart = new Date(el.invests[0].investDate);
            const dateEnd: Date = new Date(el.invests[0].dividendsDate);
            const progressEnd = dateEnd.getTime() - Date.now();
            let nowDividendsVar = 0;
            let dividendsPerDayVar = 0;

            el.invests.map((i) => {
                const percent = i.percent;

                let fullDividendsDate = new Date(i.investDate); //divDate
                let fullPeriod = dateEnd.getTime() - i.investDate; //difDate
                let nowPeriod = Date.now() - i.investDate;

                nowDividendsVar +=
                    (((i.cost / 100) * percent) / fullPeriod) * nowPeriod > 0
                        ? (((i.cost / 100) * percent) / fullPeriod) * nowPeriod
                        : 0; //right now dividends

                fullDividendsDate.setHours(fullDividendsDate.getHours() + 24);

                dividendsPerDayVar +=
                    ((i.cost / 100) * i.percent) /
                    ((fullDividendsDate.getTime() - i.investDate) / 86400000); //perDay
            });
            res += dividendsPerDayVar;
        });
        return res;
    }, [contract]);

    console.log(currentUser);

    if (!currentUser) return <></>;
    const days = Math.ceil((new Date().getTime() - currentUser.registerDate) / 1000 / 60 / 60 / 24);

    return (
        <WidgetContainer className={className} title="Statistics">
            <div className={cl.widget}>
                <div className={cl.text}>
                    <TextV className={cl.key}>Date of registration</TextV>
                    <TextV className={cl.value} variant="subtitle">
                        {cuteFormateDateOnlyNumbers(currentUser.registerDate)}
                    </TextV>
                </div>
                {currentUser.upliner && (
                    <div className={cl.text}>
                        <TextV className={cl.key}>Inviter</TextV>
                        <TextV className={cl.value} variant="subtitle">
                            {currentUser.upliner}
                        </TextV>
                    </div>
                )}
                <div className={cl.text}>
                    <TextV className={cl.key}>Days in the project</TextV>
                    <TextV className={cl.value} variant="subtitle">
                        {days}
                    </TextV>
                </div>
                <div className={cl.text}>
                    <TextV className={cl.key}>Income per day</TextV>
                    <TextV className={cl.value} variant="subtitle">
                        {getFormatedPrice(divPerDay)}
                    </TextV>
                </div>
            </div>
        </WidgetContainer>
    );
};

export default UserStats;
