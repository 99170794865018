const months = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
export const cuteFormateDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${year} ${month} ${day > 9 ? day : '0' + day}`;
};

export const cuteFormateDateReversed = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${day > 9 ? day : '0' + day} ${month} ${year}`;
};

export const cuteFormateDateWithTime = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${hour > 9 ? hour : '0' + hour}:${
        minutes > 9 ? minutes : '0' + minutes
    }  ${year} ${month} ${day}`;
};

export const cuteFormateDateWithTimeRu = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${hour > 9 ? hour : '0' + hour}:${
        minutes > 9 ? minutes : '0' + minutes
    }, ${day} ${month}  ${year}`;
};

export const cuteFormateDateOnlyNumbers = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${day > 9 ? day : '0' + day}.${month + 1 > 9 ? month + 1 : '0' + (month + 1)}.${year}`;
};

export const cuteTimerFormat = (diffTimestamp: number) => {
    if (diffTimestamp < 0) return '00:00:00';

    const hours = Math.floor(diffTimestamp / 60 / 60);
    const minutes = Math.floor((diffTimestamp - hours * 3600) / 60);
    const sec = Math.floor(diffTimestamp - hours * 3600 - minutes * 60);

    return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${
        sec > 9 ? sec : '0' + sec
    }`;
};

/* возвращает обкект с нужными полями */
export const cuteTimerFormatObj = (diffTimestamp: number) => {
    if (diffTimestamp < 0)
        return {
            hours: 0,
            minutes: 0,
            sec: 0,
        };

    const hours = Math.floor(diffTimestamp / 60 / 60);
    const minutes = Math.floor((diffTimestamp - hours * 3600) / 60);
    const sec = Math.floor(diffTimestamp - hours * 3600 - minutes * 60);

    return {
        hours,
        minutes,
        sec,
    };
};

/* возвращает обкект с нужными полями */
export const cuteTimerFormatObjDays = (diffTimestamp: number) => {
    if (diffTimestamp < 0)
        return {
            hours: 0,
            days: 0,
        };

    const days = Math.floor(diffTimestamp / 60 / 60 / 24);
    const hours = Math.floor((diffTimestamp - days * 3600 * 24) / 60 / 60);

    return {
        hours,
        days,
    };
};

export const normalizeCountForm = (number: number, words_arr: string[]) => {
    number = Math.abs(number);
    if (Number.isInteger(number)) {
        let options = [2, 0, 1, 1, 1, 2];
        return words_arr[
            number % 100 > 4 && number % 100 < 20 ? 2 : options[number % 10 < 5 ? number % 10 : 5]
        ];
    }
    return words_arr[1];
};
