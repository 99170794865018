import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Input from '../../shared/ui/Input';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';
import { getFormatedPrice } from '../../shared/utils/price';

const ProfileBalance = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    return (
        <div className={`${className} ${cl.balance}`}>
            <TextV variant="subtitle" className={cl.title}>
                Balance
            </TextV>
            <div className={cl.money}>
                <div className={cl.money_el}>
                    <TextV className={cl.money_title}>Money</TextV>
                    <TextV className={cl.money_value} variant="subtitle">
                        {getFormatedPrice(currentUser.balance)}
                    </TextV>
                </div>
                <div className={cl.money_el}>
                    <TextV className={cl.money_title}>Income</TextV>
                    <TextV className={cl.money_value} variant="subtitle">
                        {getFormatedPrice(currentUser.profit)}
                    </TextV>
                </div>
            </div>
            <Input
                label="Wallet"
                startValue={currentUser.wallet ? currentUser.wallet : 'Not tied down yet'}
                readOnly
                className={cl.input}
            />
            <div className={cl.btns}>
                <GradientButton onClick={() => navigate('/deposit ')} className={cl.btn}>
                    <TextV variant="small">Deposit</TextV>
                </GradientButton>
                <GradientButton onClick={() => navigate('/withdraw')} className={cl.btn}>
                    <TextV variant="small">Withdraw</TextV>
                </GradientButton>
            </div>
        </div>
    );
};

export default ProfileBalance;
