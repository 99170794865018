import { Outlet } from 'react-router-dom';

import cl from './index.module.scss';
import TextV from '../../../shared/ui/TextV';
import TransactionsHistory from '../../../widgets/admin/TransactionsHistory';

const TransactionHistoryPage = () => {
    return (
        <div className={cl.page}>
            <TextV variant="h3" container="h1" className={cl.title}>
                Transactions
            </TextV>
            <TransactionsHistory>
                <Outlet />
            </TransactionsHistory>
        </div>
    );
};

export default TransactionHistoryPage;
