import { useState } from 'react';
import Checkbox from '../../shared/ui/Checkbox';
import Input from '../../shared/ui/Input';
import Logo from '../../shared/ui/Logo';
import cl from './index.module.scss';
import TextV from '../../shared/ui/TextV';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import { WithClassName } from '../../shared/types';
import { loginUser } from '../../features/Session/api';
import { useAppDispatch } from '../../app/store';
import { setCurrentUser } from '../../features/Session/model/slice';
import { Link, useNavigate } from 'react-router-dom';

const validateObj = {
    login: {
        success: true,
        message: '',
    },
    password: {
        success: true,
        message: '',
    },
};

const LoginForm = ({ className = '' }: WithClassName) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [save, setSave] = useState(true);
    const [validation, setValidation] = useState(validateObj);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let successValidate = true;
        const newValidateObj = {
            login: {
                success: true,
                message: '',
            },
            password: {
                success: true,
                message: '',
            },
        };

        if (login.length < 4) {
            newValidateObj.login.success = false;
            successValidate = false;
        }
        if (password.length < 4) {
            newValidateObj.password.success = false;
            successValidate = false;
        }

        setValidation(newValidateObj);

        if (!successValidate) return;

        setIsLoading(true);

        const data = {
            login,
            password,
            remember: save,
        };
        const res = await loginUser(data);

        if (!res.status) {
            setValidation({
                ...newValidateObj,
                password: {
                    success: false,
                    message: 'Invalid login or password',
                },
            });
            setIsLoading(false);
            return;
        }
        dispatch(setCurrentUser(res.data));
        navigate('/main');
    };

    const wrapperValidationChange = (v: string, type: string) => {
        if (type == 'login') {
            setLogin(v);
            setValidation({
                ...validation,
                login: {
                    message: '',
                    success: true,
                },
            });
        }
        if (type == 'password') {
            setPassword(v);
            setValidation({
                ...validation,
                password: {
                    message: '',
                    success: true,
                },
            });
        }
    };

    return (
        <div className={`${cl.container} ${className}`}>
            <Logo className={cl.logo} />
            <form className={cl.form} onSubmit={handleLogin}>
                <TextV className={cl.title} variant="h2">
                    Login
                </TextV>
                <Input
                    label="Login"
                    placeholder="Enter login"
                    className={cl.input}
                    startValue={login}
                    onChange={(v) => wrapperValidationChange(v, 'login')}
                    error={!validation.login.success}
                />
                <Input
                    label={
                        !validation.password.success && validation.password.message.length > 1
                            ? validation.password.message
                            : 'Password'
                    }
                    placeholder="Enter password"
                    className={`${cl.input} ${cl.last_input}`}
                    startValue={password}
                    onChange={(v) => wrapperValidationChange(v, 'password')}
                    password
                    error={!validation.password.success}
                />
                <Checkbox
                    title="Remember me"
                    checked={save}
                    handleCheck={(v) => setSave(v)}
                    className={cl.check}
                />
                <div className={cl.links}>
                    <Link className={cl.link} to="/registration">
                        <TextV>No account?</TextV>
                    </Link>
                    <Link className={cl.link} to="/recovery">
                        <TextV>Forgot password?</TextV>
                    </Link>
                </div>
                <GradientButton className={cl.button} formButton>
                    <TextV variant="h4">{isLoading ? 'Authorizing...' : 'Sign in'}</TextV>
                </GradientButton>
            </form>
        </div>
    );
};

export default LoginForm;
