import { Link } from 'react-router-dom';
import { WithClassName } from '../../types';
import TextV from '../TextV';
import cl from './index.module.scss';

const Logo = ({ className = '' }: WithClassName) => {
    return (
        <Link to="/" className={cl.a}>
            <div className={`${cl.logo} ${className}`}>
                <img src="/logo.png" alt="logo" />
                <TextV variant="h1" container="h1">
                    Intelfin
                </TextV>
            </div>
        </Link>
    );
};

export default Logo;
