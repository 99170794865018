import { WithClassName } from '../../../shared/types';
import cl from './index.module.scss';
import { Message } from '../../../entities/Application/model/types';
import MessageBox from '../../../entities/Application/ui/MessageBox';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../Session/model/selectors';
import { getUserAvatar } from '../../../entities/User/lib/img';

interface MessagesListProps extends WithClassName {
    data: Message[];
}

const MessagesList = ({ className = '', data }: MessagesListProps): JSX.Element => {
    const messageRef = useRef<HTMLDivElement>(null);
    const currentUser = useSelector(getCurrentUser);

    useLayoutEffect(() => {
        if (!messageRef.current) return;

        messageRef.current.scrollTo({
            left: 0,
            top: messageRef.current.scrollHeight,
            behavior: 'smooth',
        });
    }, [messageRef, data]);

    return (
        <div className={`${cl.messages_wrapper} ${className}`} ref={messageRef}>
            <div className={cl.messages}>
                {data.map((m, i) => (
                    <MessageBox
                        message={m}
                        key={i}
                        needName={i === 0 || data[i - 1].isAdmin !== m.isAdmin}
                        isCurrentUserMessage={!m.isAdmin}
                        isLastForUser={data.length - 1 === i || data[i + 1].isAdmin !== m.isAdmin}
                        avatar={
                            currentUser && !m.isAdmin
                                ? getUserAvatar(currentUser.avatar)
                                : getUserAvatar(null)
                        }
                    />
                ))}
            </div>
        </div>
    );
};

export default MessagesList;
