import { useSelector } from 'react-redux';
import FinPagination from '../../../features/FinPagination/ui';
import { WithClassName } from '../../../shared/types';
import TextV from '../../../shared/ui/TextV';
import { getFormatedPrice } from '../../../shared/utils/price';
import cl from './index.module.scss';
import { getAdminUsers } from '../../../entities/User/model/admin/selectors';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/store';
import { setAdminUsers } from '../../../entities/User/model/admin/async';
import { getUserAvatar } from '../../../entities/User/lib/img';

const perPage = 5;

const Users = ({ className = '' }: WithClassName) => {
    const { data, page, total } = useSelector(getAdminUsers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (total == 0) dispatch(setAdminUsers({ page: 1, perPage }));
    }, []);

    const handleChangePage = (p: number) => {
        dispatch(setAdminUsers({ page: p, perPage }));
    };

    const totalPages = Math.ceil(total / perPage);

    return (
        <div className={`${cl.widget} ${className}`}>
            <TextV className={cl.title} variant="h3">
                Пользователи
            </TextV>
            <div className={cl.table_wrapper}>
                <table className={cl.table}>
                    <tbody>
                        <tr className={cl.table_up}>
                            <th>
                                <TextV>Имя</TextV>
                            </th>
                            <th>
                                <TextV>Инвестировано</TextV>
                            </th>
                            <th>
                                <TextV>ID</TextV>
                            </th>
                        </tr>
                        {data.map((el) => (
                            <tr className={cl.table_row} key={el.id}>
                                <td>
                                    <div className={cl.user_name}>
                                        <div className={cl.img}>
                                            <img src={getUserAvatar(el.avatar)} alt="logo" />
                                        </div>
                                        <TextV variant="subtitle">{el.username}</TextV>
                                    </div>
                                </td>
                                <td>
                                    <TextV variant="subtitle">{getFormatedPrice(el.invest)}</TextV>
                                </td>
                                <td>
                                    <TextV variant="subtitle">{el.id}</TextV>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalPages > 0 && (
                <FinPagination
                    curPage={page}
                    count={totalPages}
                    onChangePage={handleChangePage}
                    className={cl.pagination}
                />
            )}
        </div>
    );
};

export default Users;
