import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { WithChildren, WithClassName } from '../../types';
import TextV from '../TextV';
import ArrowBottom from '../icons/ArrowBottom';
import cl from './index.module.scss';

interface FinAccordionProps extends WithClassName, WithChildren {
    title: string;
}

const FinAccordion = ({ children, className = '', title }: FinAccordionProps): JSX.Element => {
    return (
        <Accordion className={`${cl.accordion} ${className}`}>
            <AccordionSummary
                expandIcon={<ArrowBottom />}
                className={cl.accordion_summary}
                classes={{ content: cl.accordion_content }}>
                <TextV>{title}</TextV>
            </AccordionSummary>
            <AccordionDetails className={cl.accordion_details}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default FinAccordion;
