import { OperationStatus, WithClassName } from '../../../shared/types';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';

interface TransactionStatusProps extends WithClassName {
    status: OperationStatus;
}

const TransactionStatus = ({ status, className = '' }: TransactionStatusProps): JSX.Element => {
    switch (status) {
        case 'finish':
            return (
                <TextV variant="subtitle" className={`${cl.success} ${className}`}>
                    Completed
                </TextV>
            );
        case 'false':
            return (
                <TextV variant="subtitle" className={`${cl.error} ${className}`}>
                    Rejected
                </TextV>
            );
        case 'waiting':
            return (
                <TextV variant="subtitle" className={`${cl.process} ${className}`}>
                    In the process
                </TextV>
            );
        default:
            const neverCheck: never = status;
            return <></>;
    }
};

export default TransactionStatus;
