import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../features/Session/model/selectors';
import { useAppDispatch } from '../../../app/store';
import {
    addApplication,
    addMessage,
    changeStatus,
    closeChat,
    setApplications,
    setSocket,
} from './slice';

const WebSoketLisinter = () => {
    const socket = useRef<WebSocket | null>(null);

    const dispatch = useAppDispatch();

    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        let closed = false;
        const connect = () => {
            console.log('...conections');

            socket.current = new WebSocket('wss://intelfin.io/api/support');
            socket.current.onopen = function (e) {
                dispatch(setSocket(socket.current));
                // отправляем запрос на получение чатов
                socket.current != null &&
                    socket.current.send(
                        JSON.stringify({
                            typeWS: 'listTickets',
                        }),
                    );
            };

            socket.current.onmessage = function (event) {
                const data = JSON.parse(event.data);
                console.log('MESSAGE');
                console.log(data);

                switch (data.type) {
                    case 'listTickets':
                        dispatch(setApplications(data.data));
                        break;
                    case 'newTicket':
                        dispatch(addApplication(data.data));
                        break;
                    case 'chatMessage':
                        dispatch(
                            addMessage({ id: data.data.ticketId, message: data.data.messages }),
                        );
                        break;
                    case 'closeTicket':
                        dispatch(closeChat(data.data.ticketId));
                        break;
                    case 'enterChat':
                        dispatch(changeStatus(data.data.ticketId));
                        break;
                    default:
                        console.log(data);
                        break;
                }
            };

            socket.current.onclose = function (event) {
                if (event.wasClean) {
                    console.log(
                        `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`,
                    );
                } else {
                    console.log('[close] Соединение прервано');
                }
                if (!closed) {
                    dispatch(setSocket(null));
                    setTimeout(() => {
                        socket.current != null && socket.current.close();
                        connect();
                    }, 100);
                }
            };

            socket.current.onerror = function (error) {
                console.log(`[error]`);
                console.log(error);
            };
        };

        connect();
        return () => {
            closed = true;
            socket.current != null && socket.current.close();
        };
    }, [currentUser]);

    return (
        <>
            <></>
        </>
    );
};

export default WebSoketLisinter;
