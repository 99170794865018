import { useRef, useState } from 'react';
import Input from '../../shared/ui/Input';
import Logo from '../../shared/ui/Logo';
import cl from './index.module.scss';
import TextV from '../../shared/ui/TextV';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import { WithClassName } from '../../shared/types';
import Info from '../../shared/ui/Info';
import { CSSTransition } from 'react-transition-group';
import { recoveryPassword, sendRecoveryToEmail } from '../../features/Session/api';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import { setCurrentUser } from '../../features/Session/model/slice';

const validationObjStep1 = {
    success: true,
    message: '',
};

const validationObjStep2 = {
    code: {
        success: true,
        message: '',
    },
    password: {
        success: true,
        message: '',
    },
    password2: {
        success: true,
        message: '',
    },
};

const RecoverPasswordForm = ({ className = '' }: WithClassName) => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isStep2, setIsStep2] = useState(false);
    const [validationStep1, setvalidationStep1] = useState(validationObjStep1);
    const [validationStep2, setvalidationStep2] = useState(validationObjStep2);
    const step2Ref = useRef(null);

    const [isLoadgin, setIsLoadgin] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handlePass = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isStep2) {
            let successValidateStep1 = true;
            const newValidationObjStep1 = {
                success: true,
                message: '',
            };
            if (email.length < 4) {
                newValidationObjStep1.success = false;
                successValidateStep1 = false;
            }
            if (
                !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
                    email,
                )
            ) {
                newValidationObjStep1.success = false;
                newValidationObjStep1.message = 'Неверный формат почты';
                successValidateStep1 = false;
            }
            setvalidationStep1(newValidationObjStep1);
            if (!successValidateStep1) return;
            setIsLoadgin(true);
            const res = await sendRecoveryToEmail(email);
            setIsLoadgin(false);
            if (!res.status) {
                if (res.message == 'user.not.found') {
                    setvalidationStep1({
                        message: 'Пользователь с таким e-mail не найден',
                        success: false,
                    });
                }
                if (res.message == 'email.send.failed') {
                    setvalidationStep1({
                        message: 'Ошибка при отправке email',
                        success: false,
                    });
                }
                if (res.message == 'recovery.too.fast') {
                    setvalidationStep1({
                        message: 'Слишком много попыток',
                        success: false,
                    });
                }
                return;
            }

            setIsStep2(true);
        } else {
            console.log('CLICK');

            const newValidationObjStep2 = {
                code: {
                    success: true,
                    message: '',
                },
                password: {
                    success: true,
                    message: '',
                },
                password2: {
                    success: true,
                    message: '',
                },
            };

            let validSuc = true;

            if (code.length < 1) {
                newValidationObjStep2.code.message = 'Код не может быть пустым';
                newValidationObjStep2.code.success = false;
                validSuc = false;
            }
            if (password.length < 1) {
                newValidationObjStep2.password.message = 'Пароль не может быть пустым';
                newValidationObjStep2.password.success = false;
                validSuc = false;
            }
            if (password2 !== password) {
                newValidationObjStep2.password2.message = 'Пароли не совпадают';
                newValidationObjStep2.password2.success = false;
                validSuc = false;
            }

            setvalidationStep2(newValidationObjStep2);

            if (!validSuc) return;

            const data = {
                email: email,
                password,
                recoveryCode: Number(code.replaceAll(' ', '')),
            };

            setIsLoadgin(true);
            const res = await recoveryPassword(data);

            setIsLoadgin(false);
            if (!res.status) {
                if (res.message == 'user.not.found') {
                    setvalidationStep2((prev) => {
                        return {
                            ...prev,
                            code: {
                                message: 'Пользователь с таким e-mail не найден',
                                success: false,
                            },
                        };
                    });
                }
                if (res.message == 'recovery.code.wrong') {
                    setvalidationStep2((prev) => {
                        return {
                            ...prev,
                            code: {
                                message: 'Неверный код',
                                success: false,
                            },
                        };
                    });
                }
                if (res.message == 'recovery.too.many.tries') {
                    setvalidationStep2((prev) => {
                        return {
                            ...prev,
                            code: {
                                message: 'Слишком много попыток',
                                success: false,
                            },
                        };
                    });
                }
                return;
            }

            dispatch(setCurrentUser(res.data));
            navigate('/main');
        }
    };

    const onChangeStep1 = (v: string) => {
        setvalidationStep1(validationObjStep1);
        setEmail(v);
    };

    const handleChangeInput = (type: 'code' | 'password' | 'password2', v: string) => {
        switch (type) {
            case 'code':
                setCode(v);
                setvalidationStep2((prev) => {
                    return {
                        ...prev,
                        code: {
                            message: '',
                            success: true,
                        },
                    };
                });

                break;
            case 'password':
                setPassword(v);
                setvalidationStep2((prev) => {
                    return {
                        ...prev,
                        password: {
                            message: '',
                            success: true,
                        },
                    };
                });
                break;
            case 'password2':
                setPassword2(v);
                setvalidationStep2((prev) => {
                    return {
                        ...prev,
                        password2: {
                            message: '',
                            success: true,
                        },
                    };
                });
                break;
            default:
                break;
        }
    };

    return (
        <div className={`${cl.container} ${className}`}>
            <Logo className={cl.logo} />
            <form className={cl.form} onSubmit={handlePass}>
                <TextV className={cl.title} variant="h2">
                    Восстановление пароля
                </TextV>
                {!isStep2 && (
                    <Info className={cl.info}>
                        <TextV variant="small">
                            Для подтверждения Вашей личности, на&nbsp;Ваш адрес электронной почты
                            будет направлено сообщение с&nbsp;кодом. Этот код необходимо ввести
                            в&nbsp;окно ниже. Так мы сможем убедиться, что&nbsp;Вы владелец
                            аккаунта.
                        </TextV>
                    </Info>
                )}
                <Input
                    label={
                        !validationStep1.success && validationStep1.message.length > 1
                            ? validationStep1.message
                            : 'E-Mail'
                    }
                    placeholder="Введите E-Mail"
                    className={`${cl.input} ${!isStep2 ? cl.last_input : ''}`}
                    startValue={email}
                    onChange={(v) => onChangeStep1(v)}
                    readOnlyWithoutClass={isStep2}
                    error={!validationStep1.success}
                />
                <CSSTransition
                    nodeRef={step2Ref}
                    in={isStep2}
                    timeout={300}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit>
                    <div className={cl.step_2} ref={step2Ref}>
                        <Input
                            label={
                                validationStep2.code.success ? 'Код' : validationStep2.code.message
                            }
                            error={!validationStep2.code.success}
                            placeholder="Введите Код"
                            className={cl.input}
                            startValue={code}
                            onChange={(v) => handleChangeInput('code', v)}
                        />
                        <Input
                            label={
                                validationStep2.password.success
                                    ? 'Введите новый пароль'
                                    : validationStep2.password.message
                            }
                            error={!validationStep2.password.success}
                            placeholder="Введите новый пароль"
                            className={cl.input}
                            password
                            startValue={password}
                            onChange={(v) => handleChangeInput('password', v)}
                        />
                        <Input
                            label={
                                validationStep2.password2.success
                                    ? 'Повторите новый пароль'
                                    : validationStep2.password2.message
                            }
                            error={!validationStep2.password2.success}
                            placeholder="Повторите новый пароль"
                            className={`${cl.input} ${isStep2 ? cl.last_input : ''}`}
                            startValue={password2}
                            password
                            onChange={(v) => handleChangeInput('password2', v)}
                        />
                    </div>
                </CSSTransition>
                <GradientButton formButton className={isStep2 ? cl.button : cl.button2}>
                    <TextV variant="h4">
                        {isLoadgin ? 'Загрузка...' : isStep2 ? 'Восстановить пароль' : 'Далее'}
                    </TextV>
                </GradientButton>
            </form>
        </div>
    );
};

export default RecoverPasswordForm;