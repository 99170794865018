import { useNavigate } from 'react-router-dom';
import GoogleAuthButton from '../../features/GoogleAuth/ui/GoogleAuthButton';
import TelegramAutnButton from '../../features/TelegramAutn/ui/TelegramAutnButton';
import ColorButton from '../../shared/ui/Buttons/ColorButton';
import Info from '../../shared/ui/Info';
import TextV from '../../shared/ui/TextV';
import Profile from '../../widgets/Profile';
import ProfileBalance from '../../widgets/ProfileBalance';
import ProfileReferals from '../../widgets/ProfileReferals';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';

const ProfilePage = () => {
    const navigator = useNavigate();

    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    console.log(currentUser);

    return (
        <div className={cl.page}>
            <div className={cl.text}>
                <TextV variant="h3" container="h1" className={cl.title}>
                    Profile
                </TextV>
            </div>
            <div className={cl.profile_page}>
                <Profile className={cl.profile} />
                <div className={cl.auth}>
                    <GoogleAuthButton className={cl.auth_button} />
                </div>
                <div className={cl.bottom_widgets}>
                    <ProfileBalance className={cl.widget} />
                    <ProfileReferals className={cl.widget} />
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
