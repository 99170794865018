import { Outlet } from 'react-router-dom';
import TextV from '../../shared/ui/TextV';
import History from '../../widgets/History';
import cl from './index.module.scss';

const HistoryPage = () => {
    return (
        <div className={cl.page}>
            <TextV variant="h3" container="h1" className={cl.title}>
                History
            </TextV>
            <History>
                <Outlet />
            </History>
        </div>
    );
};

export default HistoryPage;
