import { WithClassName } from '../../shared/types';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';

const ContractsTable = ({ className = '' }: WithClassName): JSX.Element => {
    return (
        <div className={`${cl.contracts} ${className}`}>
            <TextV variant="subtitle" className={cl.title}>
                Contracts:
            </TextV>
            <table className={cl.table}>
                <tr>
                    <th>
                        <TextV>Status</TextV>
                    </th>
                    <th>
                        <TextV>Personal asset</TextV>
                    </th>
                    <th>
                        <TextV>Turnover structure</TextV>
                    </th>
                    <th>
                        <TextV>Percentage in month</TextV>
                    </th>
                </tr>
                <tr>
                    <td>
                        <TextV>0-S</TextV>
                    </td>
                    <td>
                        <TextV>100$</TextV>
                    </td>
                    <td>
                        <TextV>0$</TextV>
                    </td>
                    <td>
                        <TextV>10%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>1-S</TextV>
                    </td>
                    <td>
                        <TextV>250$</TextV>
                    </td>
                    <td>
                        <TextV>250$</TextV>
                    </td>
                    <td>
                        <TextV>10%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>2-S</TextV>
                    </td>
                    <td>
                        <TextV>500$</TextV>
                    </td>
                    <td>
                        <TextV>1000$</TextV>
                    </td>
                    <td>
                        <TextV>11%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>3-S</TextV>
                    </td>
                    <td>
                        <TextV>1000$</TextV>
                    </td>
                    <td>
                        <TextV>5000$</TextV>
                    </td>
                    <td>
                        <TextV>12%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>4-S</TextV>
                    </td>
                    <td>
                        <TextV>2500$</TextV>
                    </td>
                    <td>
                        <TextV>15000$</TextV>
                    </td>
                    <td>
                        <TextV>13%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>5-S</TextV>
                    </td>
                    <td>
                        <TextV>5000$</TextV>
                    </td>
                    <td>
                        <TextV>80000$</TextV>
                    </td>
                    <td>
                        <TextV>14%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>6-S</TextV>
                    </td>
                    <td>
                        <TextV>5000$</TextV>
                    </td>
                    <td>
                        <TextV>150000$</TextV>
                    </td>
                    <td>
                        <TextV>15%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>7-S</TextV>
                    </td>
                    <td>
                        <TextV>10000$</TextV>
                    </td>
                    <td>
                        <TextV>200000$</TextV>
                    </td>
                    <td>
                        <TextV>16%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>8-S</TextV>
                    </td>
                    <td>
                        <TextV>10000$</TextV>
                    </td>
                    <td>
                        <TextV>380000$</TextV>
                    </td>
                    <td>
                        <TextV>17%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>9-S</TextV>
                    </td>
                    <td>
                        <TextV>20000$</TextV>
                    </td>
                    <td>
                        <TextV>550000$</TextV>
                    </td>
                    <td>
                        <TextV>18%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>10-S</TextV>
                    </td>
                    <td>
                        <TextV>20000$</TextV>
                    </td>
                    <td>
                        <TextV>950000$</TextV>
                    </td>
                    <td>
                        <TextV>19%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>11-S</TextV>
                    </td>
                    <td>
                        <TextV>30000$</TextV>
                    </td>
                    <td>
                        <TextV>1500000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>12-S</TextV>
                    </td>
                    <td>
                        <TextV>30000$</TextV>
                    </td>
                    <td>
                        <TextV>1800000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>13-S</TextV>
                    </td>
                    <td>
                        <TextV>50000$</TextV>
                    </td>
                    <td>
                        <TextV>2600000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>14-S</TextV>
                    </td>
                    <td>
                        <TextV>50000$</TextV>
                    </td>
                    <td>
                        <TextV>3200000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>15-S</TextV>
                    </td>
                    <td>
                        <TextV>100000$</TextV>
                    </td>
                    <td>
                        <TextV>6000000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>16-S</TextV>
                    </td>
                    <td>
                        <TextV>100000$</TextV>
                    </td>
                    <td>
                        <TextV>9000000$</TextV>
                    </td>
                    <td>
                        <TextV>20%</TextV>
                    </td>
                </tr>
            </table>
            <table className={cl.table}>
                <tr>
                    <th>
                        <TextV>Status</TextV>
                    </th>
                    <th>
                        <TextV>Referral fees</TextV>
                    </th>
                    <th>
                        <TextV>Terms and conditions</TextV>
                    </th>
                    <th>
                        <TextV>Rewards</TextV>
                    </th>
                </tr>
                <tr>
                    <td>
                        <TextV>0-S</TextV>
                    </td>
                    <td>
                        <TextV>3%</TextV>
                    </td>
                    <td>
                        <TextV>-</TextV>
                    </td>
                    <td>
                        <TextV>0$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>1-S</TextV>
                    </td>
                    <td>
                        <TextV>3% 1%</TextV>
                    </td>
                    <td>
                        <TextV>x1 - 100$</TextV>
                    </td>
                    <td>
                        <TextV>0$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>2-S</TextV>
                    </td>
                    <td>
                        <TextV>3,5% 1%</TextV>
                    </td>
                    <td>
                        <TextV>x3 - 100$</TextV>
                    </td>
                    <td>
                        <TextV>10$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>3-S</TextV>
                    </td>
                    <td>
                        <TextV>4% 1,5% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x3 - 500$</TextV>
                    </td>
                    <td>
                        <TextV>50$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>4-S</TextV>
                    </td>
                    <td>
                        <TextV>4,5% 2% 0,5%</TextV>
                    </td>
                    <td>
                        <TextV>x3 - 1000$</TextV>
                    </td>
                    <td>
                        <TextV>150$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>5-S</TextV>
                    </td>
                    <td>
                        <TextV>4,75% 2,25% 0,75% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 4-S, x1 3-S</TextV>
                    </td>
                    <td>
                        <TextV>500$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>6-S</TextV>
                    </td>
                    <td>
                        <TextV>4,75% 2,25% 0,75% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 4-S, x2 3-S</TextV>
                    </td>
                    <td>
                        <TextV>750$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>7-S</TextV>
                    </td>
                    <td>
                        <TextV>5% 2,5% 1% 0,5%</TextV>
                    </td>
                    <td>
                        <TextV>x2 4-S, x1 3-S</TextV>
                    </td>
                    <td>
                        <TextV>1000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>8-S</TextV>
                    </td>
                    <td>
                        <TextV>5% 2,5% 1% 0,5%</TextV>
                    </td>
                    <td>
                        <TextV>x3 4-S</TextV>
                    </td>
                    <td>
                        <TextV>1700$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>9-S</TextV>
                    </td>
                    <td>
                        <TextV>5,5% 2,75% 1,25% 0,75% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 5-S, x2 4-S</TextV>
                    </td>
                    <td>
                        <TextV>3000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>10-S</TextV>
                    </td>
                    <td>
                        <TextV>5,5% 2,75% 1,25% 0,75% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 6-S, x1 5-S, x1 4-S</TextV>
                    </td>
                    <td>
                        <TextV>5000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>11-S</TextV>
                    </td>
                    <td>
                        <TextV>6% 3% 1,5% 1% 0,5% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 7-S, x1 5-S, x1 4-S</TextV>
                    </td>
                    <td>
                        <TextV>8000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>12-S</TextV>
                    </td>
                    <td>
                        <TextV>6% 3% 1,5% 1% 0,5% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 7-S, x1 6-S, x1 5-S</TextV>
                    </td>
                    <td>
                        <TextV>10000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>13-S</TextV>
                    </td>
                    <td>
                        <TextV>6,5% 3,25% 1,75% 1,5% 0,75% 0,25% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 8-S, x1 7-S, x1 6-S</TextV>
                    </td>
                    <td>
                        <TextV>13000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>14-S</TextV>
                    </td>
                    <td>
                        <TextV>6,5% 3,25% 1,75% 1,5% 0,75% 0,25% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 9-S, x1 8-S, x1 7-S</TextV>
                    </td>
                    <td>
                        <TextV>17000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>15-S</TextV>
                    </td>
                    <td>
                        <TextV>7% 3,5% 2% 1,75% 1% 0,25% 0,25% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 10-S, x1 9-S, x1 8-S</TextV>
                    </td>
                    <td>
                        <TextV>25000$</TextV>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextV>16-S</TextV>
                    </td>
                    <td>
                        <TextV>7% 3,5% 2% 1,75% 1% 0,25% 0,25% 0,25%</TextV>
                    </td>
                    <td>
                        <TextV>x1 11-S, x1 9-S, x1 8-S</TextV>
                    </td>
                    <td>
                        <TextV>30000$</TextV>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default ContractsTable;
