import { WithClassName } from '../../../types';
import TextV from '../../TextV';
import cl from './index.module.scss';

interface ContactLinkProps extends WithClassName {
    title: string;
    img: string;
    alt?: string;
    subtitle?: string;
    href: string;
}

const ContactLink = ({ className = '', title, img, alt, subtitle, href }: ContactLinkProps) => {
    return (
        <a className={`${cl.contact} ${className}`} href={href}>
            <div className={cl.bg}></div>
            <div className={cl.img}>
                <img src={img} alt={alt ? alt : ''} />
            </div>
            <TextV className={cl.text}>{title}</TextV>
            {subtitle && <TextV className={cl.subtitle}>{subtitle}</TextV>}
        </a>
    );
};

export default ContactLink;
