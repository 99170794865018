import axios from 'axios';
import { User } from '../model/types';
import { WithStatus } from '../../../shared/types';
import { AdminHistoryType } from '../model/admin/types';

const url = 'https://intelfin.io/api';

export const getMainInformation = async () => {
    const { data } = await axios.post(`${url}/admin/main`, {}, { withCredentials: true });

    return data;
};

export const createNews = async (dto: FormData) => {
    const { data } = await axios.post(`${url}/admin/blog`, dto, { withCredentials: true });

    return data;
};

export const createEvent = async (dto: FormData) => {
    const { data } = await axios.post(`${url}/admin/events`, dto, { withCredentials: true });

    return data;
};


export const getAdminUser = async (offset: number, count: number) => {
    const { data } = await axios.post(
        `${url}/admin/users`,
        { offset, count },
        { withCredentials: true },
    );

    return data;
};

export const getAdminTransactions = async (
    type: AdminHistoryType,
    offset: number,
    count: number,
) => {
    const { data } = await axios.post(
        `${url}/admin/transaction`,
        { type, offset, count },
        { withCredentials: true },
    );

    return data;
};

export const adminWithdrawAprove = async (id: string, accept: boolean) => {
    const { data } = await axios.post(
        `${url}/admin/transaction/withdraw`,
        { id, accept },
        { withCredentials: true },
    );

    return data;
};
