const ArrowBottom = (): JSX.Element => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <g id="ph:caret-down-bold">
                <path
                    id="Vector"
                    d="M14.3763 6.93857L9.06376 12.2511C8.98973 12.3254 8.90176 12.3843 8.8049 12.4245C8.70804 12.4647 8.60419 12.4854 8.49931 12.4854C8.39443 12.4854 8.29058 12.4647 8.19372 12.4245C8.09686 12.3843 8.00889 12.3254 7.93485 12.2511L2.62235 6.93857C2.47265 6.78887 2.38855 6.58583 2.38855 6.37412C2.38855 6.16241 2.47265 5.95937 2.62235 5.80966C2.77206 5.65996 2.9751 5.57586 3.18681 5.57586C3.39852 5.57586 3.60156 5.65996 3.75126 5.80966L8.49997 10.5584L13.2487 5.809C13.3984 5.6593 13.6014 5.5752 13.8131 5.5752C14.0248 5.5752 14.2279 5.6593 14.3776 5.809C14.5273 5.9587 14.6114 6.16174 14.6114 6.37345C14.6114 6.58516 14.5273 6.7882 14.3776 6.93791L14.3763 6.93857Z"
                />
            </g>
        </svg>
    );
};

export default ArrowBottom;
