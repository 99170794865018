import { createAsyncThunk } from '@reduxjs/toolkit';
import { Event } from './types';
import { fetchEvents } from '../api';

export const setEvents = createAsyncThunk<
    { total: number; offset: number; data: Event[] } | null,
    { offset: number; count: number }
>('media/events', async (props) => {
    const res = await fetchEvents(props.offset, props.count);
    if (!res.status) return null;

    return {
        total: res.data.total as number,
        data: res.data.events as Event[],
        offset: props.offset as number,
    };
});
