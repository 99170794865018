import CreateEventForm from '../../../widgets/admin/CreateEventForm';
import cl from './index.module.scss';

const CreateEvent = () => {
    return (
        <div className={cl.page}>
            <CreateEventForm />
        </div>
    );
};

export default CreateEvent;
