import { getUserHistory } from './../api/index';
import { RootState } from '../../../app/store/rootReducer';
import { HistoryType } from './types';

export const getSelectedReferalLine = (state: RootState) => state.user.referals.currentLine;

export const getSelectedUserReferals = (state: RootState) => {
    const currentLine = state.user.referals.currentLine;
    return state.user.referals[`line${currentLine}`];
};

export const getCurrentUserHistory = (type: HistoryType) => (state: RootState) =>
    state.user.history[type];
