import NewApplication from '../../widgets/NewApplication';
import cl from './index.module.scss';

const CreateApplication = () => {
    return (
        <div className={cl.page}>
            <NewApplication className={cl.new_application} />
        </div>
    );
};

export default CreateApplication;
