import { useSelector } from 'react-redux';
import { WithClassName } from '../../../shared/types';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import { getCurrentUser } from '../../Session/model/selectors';
import { cuteFormateDate } from '../../../shared/utils/date';

const ProfileBio = ({ className = '' }: WithClassName) => {
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    return (
        <div className={`${className} ${cl.bio}`}>
            <div className={cl.bio_up}>
                <TextV variant="subtitle">{currentUser.username}</TextV>
            </div>
            <div className={cl.bio_texts}>
                {currentUser.upliner && (
                    <TextV>
                        <>Invited: {currentUser.upliner}</>
                    </TextV>
                )}
                <TextV>
                    <>Registration: {cuteFormateDate(currentUser.registerDate)}</>
                </TextV>
            </div>
        </div>
    );
};

export default ProfileBio;
