import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import SocalMediaItem from './SocalMediaItem';
import cl from './index.module.scss';

const data = [
    {
        img: '/assets/img/landing/media/m1.svg',
        title: 'TrustIndex',
        link: 'https://www.trustindex.io/reviews/intelfin.io ',
    },
    {
        img: '/assets/img/landing/media/m2.svg',
        title: 'ProvenExpert',
        link: 'https://www.provenexpert.com/intelfin/',
    },
    {
        img: '/assets/img/landing/media/m3.svg',
        title: 'SourceForge',
        link: 'https://sourceforge.net/software/product/Intelfin/ ',
    },
    {
        img: '/assets/img/landing/media/m4.svg',
        title: 'ProductHunt',
        link: 'https://www.producthunt.com/products/intelfin-global-platform/reviews ',
    },
    {
        img: '/assets/img/landing/media/m5.svg',
        title: 'Reviews.io',
        link: 'https://www.reviews.io/company-reviews/store/intelfin.io ',
    },
    {
        img: '/assets/img/landing/media/m6.svg',
        title: 'SaasHub',
        link: 'https://www.saashub.com/intelfin-io-reviews',
    },
    {
        img: '/assets/img/landing/media/m7.svg',
        title: 'TrustProfile',
        link: 'https://www.trustprofile.com/webshop/Intelfin-Global_4008087/reviews',
    },
];

const SocalMedia = ({ className = '' }: WithClassName) => {
    return (
        <div className={`${cl.media} ${className}`}>
            {data.map((el) => (
                <SocalMediaItem className={cl.item} {...el} />
            ))}
        </div>
    );
};

export default SocalMedia;
