import { useState } from 'react';
import AddFileButton from '../../../features/AddFile/ui/AddFileButton';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import Input from '../../../shared/ui/Input';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import cl from './index.module.scss';
import { createEvent } from '../../../entities/User/api/admin';
import { useNavigate } from 'react-router-dom';

const minValue = new Date();

const CreateEventForm = ({ className = '' }: WithClassName) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [img, setImg] = useState<File | null>(null);
    const [theme, setTheme] = useState('');
    const [date, setDate] = useState<dayjs.Dayjs | undefined | null>(dayjs());

    const navigate = useNavigate();

    const handeSend = async () => {
        setShowErrors(false);

        if (!title.length || !content.length || !theme.length) {
            setShowErrors(true);
            return;
        }
        if (!img || !date) return;

        const data = new FormData();
        data.append('title', title);
        data.append('descr', content);
        data.append('theme', theme);
        data.append('date', date.toString());
        data.append('image', img);

        const res = await createEvent(data);
        if (!res.status) return;
        navigate(`/events/${res.data.id}`);
    };

    return (
        <div className={`${cl.form} ${className}`}>
            <TextV variant="h3" container="h2" className={cl.title}>
                Создать мероприятие
            </TextV>
            <div className={cl.inputs}>
                <div className={cl.inputs_up}>
                    <Input
                        className={cl.input}
                        startValue={title}
                        onChange={setTitle}
                        placeholder="Заголовок"
                        error={showErrors && !title.length}
                    />
                    <AddFileButton
                        className={cl.blue_attachment}
                        label={img ? 'Изображение прикреплено' : 'Установить заставку'}
                        accept="image/png, image/jpeg"
                        file={img}
                        handleChange={(f) => setImg(f)}
                        showFileName={false}
                    />
                </div>
                <div className={cl.dop_inputs}>
                    <Input
                        className={`${cl.input} ${cl.location}`}
                        startValue={theme}
                        onChange={setTheme}
                        placeholder="Тема"
                        error={showErrors && !theme.length}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            className={cl.date}
                        />
                    </LocalizationProvider>
                </div>
                <Input
                    className={cl.textarea}
                    startValue={content}
                    onChange={setContent}
                    textarea
                    placeholder="Текст"
                    error={showErrors && !content.length}
                />
            </div>
            <div className={cl.bths}>
                <GradientButton onClick={handeSend} className={cl.btn}>
                    <TextV variant="small">Выложить</TextV>
                </GradientButton>
            </div>
        </div>
    );
};

export default CreateEventForm;
