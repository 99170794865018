import axios from 'axios';
import { HistoryType, User } from '../model/types';
import { WithStatus } from '../../../shared/types';

const url = 'https://intelfin.io/api';

export const getUser = async () => {
    const { data } = await axios.post<{ data: User } & WithStatus>(
        `${url}/user/profile`,
        {},
        { withCredentials: true },
    );

    return data;
};

export const getReferrals = async (offset: number, count: number, line: number) => {
    const { data } = await axios.post(
        `${url}/user/referrals`,
        { offset, count, line },
        { withCredentials: true },
    );

    return data;
};

export const changePhoto = async (newPhoto: FormData) => {
    const { data } = await axios.post(`${url}/user/profile/avatar`, newPhoto, {
        withCredentials: true,
    });

    return data;
};

export const verificationUser = async (dataFrom: any) => {
    const config = {
        onUploadProgress: function (progressEvent: any) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(percentCompleted);
        },
        withCredentials: true,
    };
    const { data } = await axios.post(`${url}/user/verification`, dataFrom, config);

    return data;
};

interface getUserHistoryProps {
    type: HistoryType;
    offset: number;
    count: number;
}

export const getUserHistory = async (props: getUserHistoryProps) => {
    const { data } = await axios.post(`${url}/finances/history`, props, {
        withCredentials: true,
    });

    return data;
};

export const userTransferTo = async (amount: number, username: string, code: number) => {
    const { data } = await axios.post(
        `${url}/finances/transfer`,
        { amount, username, code },
        {
            withCredentials: true,
        },
    );

    return data;
};
export const userTransferToVerify = async (amount: number, username: string) => {
    const { data } = await axios.post(
        `${url}/finances/transfer/verify`,
        { amount, username },
        {
            withCredentials: true,
        },
    );

    return data;
};

export const userWithDraw = async (amount: number, wallet: string, code: number) => {
    const { data } = await axios.post(
        `${url}/finances/withdraw`,
        { amount, wallet, code },
        {
            withCredentials: true,
        },
    );

    return data;
};
export const userWithDrawVerify = async (amount: number, wallet: string) => {
    const { data } = await axios.post(
        `${url}/finances/withdraw/verify`,
        { amount, wallet },
        {
            withCredentials: true,
        },
    );

    return data;
};