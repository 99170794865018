import { WithClassName } from '../../../../shared/types';
import TextV from '../../../../shared/ui/TextV';
import cl from './index.module.scss';
import { useId, useState } from 'react';

interface AddFileButton extends WithClassName {
    accept: string;
    file?: File | null;
    handleChange?: (file: File | null) => void;
    label?: string;
    showFileName?: boolean;
}

const AddFileButton = ({
    className = '',
    accept,
    file = null,
    handleChange,
    label,
    showFileName = true,
}: AddFileButton) => {
    const id = useId();
    const [localFile, setLocalFile] = useState<File | null>(file);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files == null) {
            setLocalFile(null);
            return;
        }
        if (handleChange) handleChange(e.currentTarget.files[0]);
        setLocalFile(e.currentTarget.files[0]);
    };

    return (
        <div className={` ${className}`}>
            <label htmlFor={id} className={cl.button}>
                <input
                    type="file"
                    onChange={handleFileChange}
                    id={id}
                    className={cl.input}
                    accept={accept}
                />
                <TextV variant="small">{label ? label : 'Attach file'}</TextV>
                <img src="/assets/icons/attach.png" alt="Attach a file" />
            </label>
            {localFile && showFileName && (
                <TextV variant="small" className={cl.file}>
                    {localFile.name}
                </TextV>
            )}
        </div>
    );
};

export default AddFileButton;
