import { combineReducers } from '@reduxjs/toolkit';
import sessionSlice from '../../features/Session/model/slice';
import contractSlice from '../../entities/Contract/model/slice';
import userSlice from '../../entities/User/model/slice';
import adminSlice from '../../entities/User/model/admin/slice';
import blogSlice from '../../entities/News/model/slice';
import mediaSlise from '../../entities/Event/model/slice';
import chatSlise from '../../entities/Application/model/slice';

export const rootReducer = combineReducers({
    session: sessionSlice,
    contract: contractSlice,
    user: userSlice,
    admin: adminSlice,
    blog: blogSlice,
    media: mediaSlise,
    chats: chatSlise,
});

export type RootState = ReturnType<typeof rootReducer>;
