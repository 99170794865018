import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoryOperation, HistoryType, UserReferal } from './types';

interface UserSlice {
    referals: {
        currentLine: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        line1: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line2: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line3: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line4: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line5: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line6: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line7: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
        line8: {
            total: number;
            currentPage: number;
            data: UserReferal[];
        };
    };
    history: {
        deposit: {
            currentPage: number;
            data: HistoryOperation[];
            total: number;
        };
        withdraw: {
            currentPage: number;
            data: HistoryOperation[];
            total: number;
        };
        team: {
            currentPage: number;
            data: HistoryOperation[];
            total: number;
        };
        dividends: {
            currentPage: number;
            data: HistoryOperation[];
            total: number;
        };
        invests: {
            currentPage: number;
            data: HistoryOperation[];
            total: number;
        };
    };
}

const initialState: UserSlice = {
    referals: {
        currentLine: 1,
        line1: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line2: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line3: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line4: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line5: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line6: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line7: {
            total: 0,
            currentPage: 1,
            data: [],
        },
        line8: {
            total: 0,
            currentPage: 1,
            data: [],
        },
    },
    history: {
        deposit: {
            currentPage: 1,
            data: [],
            total: 0,
        },
        withdraw: {
            currentPage: 1,
            data: [],
            total: 0,
        },
        team: {
            currentPage: 1,
            data: [],
            total: 0,
        },
        dividends: {
            currentPage: 1,
            data: [],
            total: 0,
        },
        invests: {
            currentPage: 1,
            data: [],
            total: 0,
        },
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setReferals: (
            state,
            action: PayloadAction<{ referals: UserReferal[]; line: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>,
        ) => {
            state.referals[`line${action.payload.line}`].data = action.payload.referals;
        },

        setCurrentLine: (state, action: PayloadAction<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>) => {
            state.referals.currentLine = action.payload;
        },
        setReferalsTotal: (state, action: PayloadAction<{ total: number; line: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>) => {
            state.referals[`line${action.payload.line}`].total = action.payload.total;
        },
        setReferalsPage: (state, action: PayloadAction<{ page: number; line: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>) => {
            state.referals[`line${action.payload.line}`].currentPage = action.payload.page;
        },

        setHistory: (
            state,
            action: PayloadAction<{
                type: HistoryType;
                page: number;
                data: HistoryOperation[];
                total: number;
            }>,
        ) => {
            state.history[action.payload.type] = {
                currentPage: action.payload.page,
                data: action.payload.data,
                total: action.payload.total,
            };
        },
    },
});

export const { setReferals, setCurrentLine, setReferalsTotal, setReferalsPage, setHistory } =
    userSlice.actions;

export default userSlice.reducer;
