import NavigationDefault from './NavigationDefault';
import NavigationLanding from './NavigationLanding';

interface NavigationProps {
    type?: 'default' | 'landing';
}

const Navigation = ({ type = 'default' }: NavigationProps): JSX.Element => {
    if (type == 'default') return <NavigationDefault />;
    if (type == 'landing') return <NavigationLanding />;

    return <></>;
};

export default Navigation;
