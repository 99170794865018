import ApplicationList from '../../widgets/ApplicationList';
import { Outlet } from 'react-router-dom';
import cl from './index.module.scss';

const ApplicationsPage = () => {
    return (
        <div className={cl.page}>
            <ApplicationList className={cl.list} />
            <div className={cl.chat_widget}>
                <Outlet />
            </div>
        </div>
    );
};

export default ApplicationsPage;
