import { useId } from 'react';
import { WithChildren, WithClassName } from '../../../types';
import cl from './index.module.scss';

interface GradientButtonProps extends WithChildren, WithClassName {
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    direction?: 'LEFT' | 'TOP' | 'RIGHT' | 'LEFT_MIDLE' | 'RIGHT_MIDLE';
    disabled?: boolean;
    formButton?: boolean;
}

const classes = {
    LEFT: cl['button--left'],
    LEFT_MIDLE: cl['button--left--midle'],
    RIGHT_MIDLE: cl['button--right--midle'],
    RIGHT: cl['button--right'],
    TOP: cl['button--top'],
};

const GradientButton = ({
    children,
    className,
    onClick,
    disabled = false,
    direction = 'LEFT',
    formButton = false,
}: GradientButtonProps) => {
    const id = useId();
    const handleClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled) return;

        if (onClick) {
            onClick(e);
        }
    };

    return formButton ? (
        <label
            htmlFor={id}
            className={`${cl.button} ${classes[direction]} ${className ? className : ''}`}>
            <input type="submit" value="" className={cl.hidden} id={id} />
            {children}
        </label>
    ) : (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={`${cl.button} ${classes[direction]} ${className ? className : ''}`}>
            {children}
        </button>
    );
};

export default GradientButton;
