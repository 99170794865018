import { useId, useState } from 'react';
import cl from './index.module.scss';
import { WithClassName } from '../../types';
import TextV from '../TextV';

interface CheckboxProps extends WithClassName {
    title?: string;
    checked?: boolean;
    handleCheck?: (val: boolean) => void;
}

const Checkbox = ({
    title,
    checked = false,
    handleCheck,
    className = '',
}: CheckboxProps): JSX.Element => {
    const id = useId();

    const handleChange = () => {
        if (handleCheck) handleCheck(!checked);
    };

    return (
        <label htmlFor={id} className={`${cl.label} ${className}`}>
            <div className={`${cl.checkmark} ${checked ? cl['checkmark--checked'] : ''}`}></div>
            <input type="checkbox" id={id} checked={checked} onChange={handleChange} />
            {title && <TextV className={cl.text}>{title}</TextV>}
        </label>
    );
};

export default Checkbox;
