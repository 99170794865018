import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from './types';
import { setEvents } from './async';

interface MediaSlise {
    events: {
        total: number;
        data: Event[];
        offset: number;
    };
}

const initialState: MediaSlise = {
    events: {
        total: 0,
        data: [],
        offset: 0,
    },
};

const mediaSlise = createSlice({
    name: 'media',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setEvents.fulfilled, (state, action) => {
            if (action.payload) state.events = action.payload;
        });
        builder.addCase(setEvents.rejected, (_, action) => {
            console.log(action);
        });
    },
});

export default mediaSlise.reducer;
