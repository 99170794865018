import { useMemo, useState } from 'react';
import cl from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Application } from '../../../entities/Application/model/types';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import ApplicationCard from '../../../entities/Application/ui/admin/ApplicationCard';
import { useSelector } from 'react-redux';
import { getApplications } from '../../../entities/Application/model/selectors';

const ApplicationList = ({ className = '' }: WithClassName): JSX.Element => {
    const [sort, setSort] = useState<'all' | 'closed' | 'open'>('all');

    const applications = useSelector(getApplications);

    const sortedApplications = useMemo(() => {
        if (sort === 'closed') return applications.filter((el) => !el.status);

        if (sort === 'open') return applications.filter((el) => el.status);

        return applications;
    }, [sort, applications]);

    return (
        <div className={`${cl.application_list} ${className}`}>
            <div className={cl.radios}>
                <GradientButton
                    onClick={() => setSort('all')}
                    className={`${cl.radio} ${sort != 'all' ? cl['radio--none-cheked'] : ''}`}>
                    <TextV variant="small">Все</TextV>
                </GradientButton>
                <GradientButton
                    onClick={() => setSort('open')}
                    className={`${cl.radio} ${sort != 'open' ? cl['radio--none-cheked'] : ''}`}>
                    <TextV variant="small">Открытые</TextV>
                </GradientButton>
                <GradientButton
                    onClick={() => setSort('closed')}
                    className={`${cl.radio} ${sort != 'closed' ? cl['radio--none-cheked'] : ''}`}>
                    <TextV variant="small">Закрытые</TextV>
                </GradientButton>
            </div>
            <div className={cl.list}>
                {sortedApplications.map((el) => (
                    <ApplicationCard application={el} key={el.id} />
                ))}
            </div>
        </div>
    );
};

export default ApplicationList;
