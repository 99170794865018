import { useSelector } from 'react-redux';
import { WithClassName } from '../../shared/types';
import TextV from '../../shared/ui/TextV';
import WidgetContainer from '../../shared/ui/WidgetContainer';
import cl from './index.module.scss';
import { getCurrentUser } from '../../features/Session/model/selectors';
import { getUserAvatar } from '../../entities/User/lib/img';

const UserInfo = ({ className = '' }: WithClassName): JSX.Element => {
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    return (
        <WidgetContainer className={className} title="User">
            <div className={cl.widget}>
                <div className={cl.img}>
                    <img
                        src={getUserAvatar(currentUser.avatar)}
                        alt="user photo"
                    />
                </div>
                <TextV variant="subtitle" className={cl.name}>
                    {currentUser.username}
                </TextV>
            </div>
        </WidgetContainer>
    );
};

export default UserInfo;
