import { WithChildren, WithClassName } from '../../types';
import cl from './index.module.scss';

interface ContainerProps extends WithChildren, WithClassName {}

const Container = ({ children, className }: ContainerProps): JSX.Element => {
    return <div className={`${cl.container} ${className ? className : ''}`}>{children}</div>;
};

export default Container;
