import { useState } from 'react';
import { WithClassName } from '../../../../shared/types';
import ColorButton from '../../../../shared/ui/Buttons/ColorButton';
import TextV from '../../../../shared/ui/TextV';
import ExitModal from '../ExitModal';
import cl from './index.module.scss';

const ExitButton = ({ className = '' }: WithClassName) => {
    const [isShowModal, setIsShowModal] = useState(false);

    const handleClose = () => setIsShowModal(false);

    return (
        <>
            <ColorButton
                onClick={() => setIsShowModal(true)}
                className={`${className} ${cl.button}`}>
                <TextV variant="small" className={cl.button_text}>
                    Logout
                </TextV>
                <img src="/assets/icons/exit.svg" alt="exit from profile" />
            </ColorButton>
            <ExitModal isShow={isShowModal} handleClose={handleClose} />
        </>
    );
};

export default ExitButton;
