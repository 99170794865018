import Info from '../../shared/ui/Info';
import TextV from '../../shared/ui/TextV';
import ContactSocial from '../../widgets/ContactSocial';
import ContactSupport from '../../widgets/ContactSupport';
import cl from './index.module.scss';

const ContactPage = () => {
    return (
        <div className={cl.page}>
            <TextV variant="h3" container="h1" className={cl.title}>
                Contacts
            </TextV>
            <ContactSupport className={cl.support} />
            <ContactSocial className={cl.social} />
            <Info className={cl.info}>
                <TextV variant="small">
                    <>
                        Visit the page <a href="#">Information Center</a> most commonly
                        by the questions you ask. This will help you find the information you need more efficiently.
                    </>
                </TextV>
            </Info>
        </div>
    );
};

export default ContactPage;
