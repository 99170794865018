import { useCallback, useEffect, useRef, useState } from 'react';

import cl from '../index.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/store';
import { getUserHistory } from '../../../../entities/User/api';
import { getCurrentUserHistory } from '../../../../entities/User/model/selectors';
import { setHistory } from '../../../../entities/User/model/slice';
import FinPagination from '../../../../features/FinPagination/ui';
import { getCurrentUser } from '../../../../features/Session/model/selectors';
import TransactionStatus from '../../../../features/TransactionStatus/ui';
import TextV from '../../../../shared/ui/TextV';
import { cuteFormateDateWithTime, cuteFormateDateWithTimeRu } from '../../../../shared/utils/date';
import { getFormatedPrice } from '../../../../shared/utils/price';
import { AdminHistoryDeposit, AdminHistoryType } from '../../../../entities/User/model/admin/types';
import { getAdminTransactions } from '../../../../entities/User/api/admin';
import { getAdminTransactionsHistory } from '../../../../entities/User/model/admin/selectors';
import { setAdminHistory } from '../../../../entities/User/model/admin/async';

const type: AdminHistoryType = 'deposit';

const perPage = 10;

const HistoryDepositsAdmin = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);

    const currentUser = useSelector(getCurrentUser);
    const dispatch = useAppDispatch();
    const { data, total, page } = useSelector(
        getAdminTransactionsHistory<AdminHistoryDeposit>(type),
    );

    useEffect(() => {
        if (total == 0) dispatch(setAdminHistory({ page: 1, perPage, type }));
    }, []);

    const totalPages = Math.ceil(total / perPage);

    const onChangePage = async (p: number) => {
        dispatch(setAdminHistory({ page: p, perPage, type }));
    };

    /*scroll */
    const trackRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const handleScrollContent = () => {
        const thumbEle = thumbRef.current;
        const contentEle = contentRef.current;
        if (!thumbEle || !contentEle) {
            return;
        }
        thumbEle.style.left = `${(contentEle.scrollLeft * 100) / contentEle.scrollWidth}%`;
    };

    const handleClickTrack = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const trackEle = trackRef.current;
        const contentEle = contentRef.current;
        if (!trackEle || !contentEle) {
            return;
        }
        const bound = trackEle.getBoundingClientRect();
        const percentage = (e.clientX - bound.left) / bound.width;
        contentEle.scrollLeft = percentage * (contentEle.scrollWidth - contentEle.clientWidth);
    };

    useEffect(() => {
        handleWidth();
        window.addEventListener('resize', handleWidth);
        return () => {
            window.removeEventListener('resize', handleWidth);
        };
    }, []);

    const handleWidth = () => {
        const thumbEle = thumbRef.current;
        const contentEle = contentRef.current;
        if (!thumbEle || !contentEle) {
            return;
        }
        contentEle.scrollLeft = contentEle.offsetWidth / 2;

        thumbEle.style.left = '50%';
        thumbEle.style.width = `${(contentEle.offsetWidth / contentEle.scrollWidth) * 100}%`;
    };

    const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const ele = thumbRef.current;
        const contentEle = contentRef.current;
        if (!ele || !contentEle) {
            return;
        }
        const startPos = {
            left: contentEle.scrollLeft,
            x: e.clientX,
            y: e.clientY,
        };

        const handleMouseMove = (e: MouseEvent) => {
            const dx = e.clientX - startPos.x;
            const dy = e.clientY - startPos.y;
            const scrollRatio = contentEle.clientWidth / contentEle.clientWidth;
            contentEle.scrollLeft = startPos.left + dx / scrollRatio;
            updateCursor(ele);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor(ele);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        const ele = thumbRef.current;
        const contentEle = contentRef.current;
        if (!ele || !contentEle) {
            return;
        }
        const touch = e.touches[0];
        const startPos = {
            left: contentEle.scrollLeft,
            x: touch.clientX,
            y: touch.clientY,
        };

        const handleTouchMove = (e: TouchEvent) => {
            const touch = e.touches[0];
            const dx = touch.clientX - startPos.x;
            const dy = touch.clientY - startPos.y;
            const scrollRatio = contentEle.clientWidth / contentEle.scrollWidth;
            contentEle.scrollLeft = startPos.left + dx / scrollRatio;
            updateCursor(ele);
        };

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            resetCursor(ele);
        };

        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const updateCursor = (ele: HTMLDivElement) => {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
        document.body.style.cursor = 'grabbing';
        document.body.style.userSelect = 'none';
    };

    const resetCursor = (ele: HTMLDivElement) => {
        ele.style.cursor = 'grab';
        ele.style.userSelect = '';
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
    };
    if (!currentUser) return <></>;

    return (
        <>
            {data.length > 0 ? (
                <div className={cl.table_container} ref={contentRef} onScroll={handleScrollContent}>
                    <table className={`${cl.table} ${cl.table_deposits}`}>
                        <tbody>
                            <tr className={cl.table_up}>
                                <td>
                                    <TextV className={`${cl.name} ${cl.row_value_up}`}>Имя</TextV>
                                </td>
                                <td>
                                    <TextV className={`${cl.id} ${cl.row_value_up}`}>
                                        ID Транзакции
                                    </TextV>
                                </td>
                                <td>
                                    <TextV className={`${cl.sum} ${cl.row_value_up}`}>Сумма</TextV>
                                </td>
                                <td>
                                    <TextV className={`${cl.time} ${cl.row_value_up}`}>Время</TextV>
                                </td>
                            </tr>

                            {data.map((el, i) => (
                                <tr className={cl.row} key={i}>
                                    <td>
                                        <div className={cl.user}>
                                            <div className={cl.img}>
                                                <img src="/avatar.png" alt="profile photo" />
                                            </div>
                                            <TextV variant="subtitle">Darrell Steward</TextV>
                                        </div>
                                    </td>
                                    <td>
                                        <TextV variant="subtitle">
                                            11111111111111111111111111111111
                                        </TextV>
                                    </td>
                                    <td>
                                        <TextV variant="subtitle">{getFormatedPrice(1000)}</TextV>
                                    </td>
                                    <td>
                                        <TextV variant="subtitle">
                                            {cuteFormateDateWithTimeRu(new Date().getTime())}
                                        </TextV>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <TextV className={cl.empty}>Тут пока пусто...</TextV>
            )}
            <div className={cl.scrollbar}>
                <div className={cl.scrollbar__track} ref={trackRef} onClick={handleClickTrack} />
                <div
                    className={cl.scrollbar__thumb}
                    ref={thumbRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                />
            </div>
            {totalPages > 1 && (
                <FinPagination
                    count={totalPages}
                    curPage={page}
                    onChangePage={onChangePage}
                    className={cl.pagination}
                />
            )}
        </>
    );
};

export default HistoryDepositsAdmin;
