import { useEffect, useRef } from 'react';
import ColorButton from '../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Container from '../../shared/ui/Container';
import TextV from '../../shared/ui/TextV';
import Header from '../../widgets/Header';
import EventsSlider from '../../widgets/EventsSlider';
import NewsSlider from '../../widgets/NewsSlider';
import cl from './index.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Configurate from '../../app/Configurate';
import SocalMedia from '../../widgets/SocalMedia';

function IndexPage() {
    const navigate = useNavigate();

    const nodeRef = useRef<HTMLElement>(null);

    const handleScrollTo = () => {
        if (!nodeRef || !nodeRef.current) return;

        nodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const location = useLocation();
    useEffect(() => {
        const hash = location.hash;

        if (!hash) return;

        const el = document.getElementById(hash.replace('#', ''));
        if (!el) return;

        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);

    return (
        <div className="App">
            <Configurate />
            <Header type="landing" />
            <Container>
                <main className={cl.main}>
                    <div className={cl.r1}></div>
                    <div className={cl.r2}></div>
                    <img src="/logo.png" alt="logo" />
                    <TextV variant="h1" container="h1" className={cl.title}>
                        <>
                            <span>Intelfin</span>
                            <br />
                            Your reliable partner
                        </>
                    </TextV>
                    <div className="" onClick={handleScrollTo}>
                        <TextV className={cl.desc} variant="subtitle">
                            Immerse yourself in the world of making money with us
                        </TextV>
                    </div>
                    <div className={cl.arrow} onClick={handleScrollTo}>
                        <img src="/assets/icons/arrow.svg" alt="arrow" />
                    </div>
                </main>
                <section className={cl.multi} id="platform">
                    <div className={cl.multi_text}>
                        <TextV variant="h2" container="h2" className={cl.multi_title}>
                            Intelfin multiplatform
                        </TextV>
                        <TextV>
                            The financial world is too complex for constant self-analysis.
                            Centralized investment firms and the banking system are too
                            uncertain - exposing their users to many risks
                        </TextV>
                        <TextV>
                            Utilizing blockchain and artificial intelligence, the multi-platform
                            Intelfin implements a fully automated, highly responsive
                            system. The platform performs automated transactions and helps
                            aspiring and practicing investors, financial managers and companies
                            easily and confidently generate decent returns in the digital asset markets.
                        </TextV>
                        <TextV>
                            We strive to move the cryptocurrency space away from the power of
                            of large financial institutions and be accessible to ordinary people. Even
                            those with no investment experience can capitalize on the differences in the
                            of market prices by utilizing our algorithms and the full power of Hybrid
                            Intelligence
                        </TextV>
                    </div>
                    <div className={cl.multi_tituls}>
                        <div className={cl.multi_tituls_imgs}>
                            <img src="/assets/img/landing/t1.png" alt="achievement" />
                            <img src="/assets/img/landing/t2.png" alt="achievement" />
                            <img src="/assets/img/landing/t3.png" alt="achievement" />
                            <img src="/assets/img/landing/t4.png" alt="achievement" />
                            <img src="/assets/img/landing/t5.png" alt="achievement" />
                            <img src="/assets/img/landing/t6.png" alt="achievement" />
                            <img src="/assets/img/landing/t7.png" alt="achievement" />
                            <img src="/assets/img/landing/t8.png" alt="achievement" />
                            <img src="/assets/img/landing/t9.png" alt="achievement" />
                        </div>
                        <TextV>
                            <>
                                Achievements <span>Intelfin</span>
                            </>
                        </TextV>
                    </div>
                </section>
                
                <section className={cl.tools} ref={nodeRef} id="tools">
                    <TextV variant="h2" container="h2">
                        Intelfin tools
                    </TextV>
                    <div className={cl.tools__list}>
                        <div className={cl.tool}>
                            <img
                                src="/assets/img/landing/sfer-purple.png"
                                alt="Arbitrage trading bot"
                            />
                            <TextV container="h3" variant="h3" className={cl.tool__title}>
                                Arbitrage trading bot
                            </TextV>
                            <TextV className={cl.tool_desc}>
                                The arbitrage bot will help you buy and sell assets in fractions of a second, making a profit on the price difference of altcoins on different exchanges.
                            </TextV>
                        </div>

                        <div className={cl.tool}>
                            <img
                                src="/assets/img/landing/sfer-blue.png"
                                alt="Hybrid trading bot"
                            />
                            <TextV container="h3" variant="h3" className={cl.tool__title}>
                                Hybrid trading bot
                            </TextV>
                            <TextV className={cl.tool_desc}>
                                The hybrid bot combines several strategies at once and, depending on the market situation, runs the appropriate algorithms
                            </TextV>
                        </div>

                        <div className={cl.tool}>
                            <img src="/assets/img/landing/sfer-green.png" alt="Liquidity pools" />
                            <TextV container="h3" variant="h3" className={cl.tool__title}>
                                Inter-exchange arbitrage
                            </TextV>
                            <TextV className={cl.tool_desc}>
                                Inter-exchange arbitrage involves the simultaneous buying and selling of the same asset on different exchanges to profit from price differences.
                            </TextV>
                        </div>

                        <div className={cl.tool}>
                            <img src="/assets/img/landing/sfer-orange.png" alt="Analitics" />
                            <TextV container="h3" variant="h3" className={cl.tool__title}>
                                Intra-exchange arbitrage
                            </TextV>
                            <TextV className={cl.tool_desc}>
                                Intra-exchange arbitrage, or single exchange arbitrage, is the use of price anomalies within a single trading platform.
                            </TextV>
                        </div>
                    </div>
                </section>

                <NewsSlider className={cl.section__list} />

                <section className={cl.media}>
                    <TextV variant="h2" container="h2" className={cl.media_title}>
                        Partnerships
                    </TextV>
                    <div className={cl.media_imgs}>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m1.png" alt="USA" />
                                <TextV className={cl.media_img_title}>USA</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m2.png" alt="Brazil" />
                                <TextV className={cl.media_img_title}>Brazil</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m3.png" alt="Indonesia" />
                                <TextV className={cl.media_img_title}>Indonesia</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m4.png" alt="Dubai" />
                                <TextV className={cl.media_img_title}>Dubai</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m5.png" alt="Kenya" />
                                <TextV className={cl.media_img_title}>Kenya</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m6.png" alt="Turkey" />
                                <TextV className={cl.media_img_title}>Turkey</TextV>
                            </div>
                        </div>
                        <div className={cl.media_img} onClick={() => navigate('/partnerships')}>
                            <div className={cl.media_img_content}>
                                <img src="/assets/img/landing/m7.png" alt="Russia" />
                                <TextV className={cl.media_img_title}>Russia</TextV>
                            </div>
                        </div>
                    </div>
                    <SocalMedia />
                </section>

                {/* <EventsSlider className={cl.section__list} /> */}
            </Container>
        </div>
    );
}

export default IndexPage;
