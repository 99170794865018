import Select, { SelectChangeEvent } from '@mui/material/Select';
import { WithClassName } from '../../../shared/types';
import cl from './index.module.scss';
import MenuItem from '@mui/material/MenuItem';
import ArrowSelect from '../../../shared/ui/icons/ArrowSelect';

interface FinSelectProps extends WithClassName {
    onChange: (value: string) => void;
    values: string[];
    currentValue: string;
}

const FinSelect = ({ currentValue, values, onChange, className }: FinSelectProps): JSX.Element => {
    const handleChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        onChange(value);
    };

    return (
        <Select
            value={currentValue}
            onChange={handleChange}
            displayEmpty
            IconComponent={ArrowSelect}
            MenuProps={{
                disableScrollLock: true,
            }}
            classes={{
                root: `${cl.select} ${className}`,
                icon: cl.icon,
                iconOpen: cl.iconOpen,
                select: cl.select_content,
            }}>
            {values.map((el, i) => (
                <MenuItem value={el} className={cl.menuitem} key={i}>
                    {el}
                </MenuItem>
            ))}
        </Select>
    );
};

export default FinSelect;
