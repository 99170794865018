import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WithChildren } from '../../../../shared/types';
import { getCurrentUser } from '../../model/selectors';
import Configurate from '../../../../app/Configurate';

const OnlyNotAuthorizedRoute = ({ children }: WithChildren) => {
    const navigate = useNavigate();
    const user = useSelector(getCurrentUser);
    console.log(user);

    useEffect(() => {
        if (user) navigate('/main');
    }, [user]);

    if (user) return <></>;

    return (
        <>
            <Configurate />
            {children}
        </>
    );
};

export default OnlyNotAuthorizedRoute;
