import { RootState } from '../../../../app/store/rootReducer';
import { AdminHistoryType } from './types';

export const getAdminMain = (state: RootState) => state.admin.main;

export const getAdminUsers = (state: RootState) => state.admin.users;

export const getAdminTransactionsHistory =
    <T>(type: AdminHistoryType) =>
    (state: RootState) =>
        state.admin.history[type] as T;
