import { useEffect, useState } from 'react';
import Input from '../../shared/ui/Input';
import Logo from '../../shared/ui/Logo';
import cl from './index.module.scss';
import TextV from '../../shared/ui/TextV';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import { WithClassName } from '../../shared/types';
import { registerUser, registerUserVerify } from '../../features/Session/api';
import { useAppDispatch } from '../../app/store';
import { setCurrentUser } from '../../features/Session/model/slice';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ColorButton from '../../shared/ui/Buttons/ColorButton';

const validateObj = {
    login: {
        success: true,
        message: '',
    },
    email: {
        success: true,
        message: '',
    },
    password: {
        success: true,
        message: '',
    },
    password2: {
        success: true,
        message: '',
    },
    inviter: {
        success: true,
        message: '',
    },
};

const RegisterForm = ({ className = '' }: WithClassName) => {
    const [searchParams] = useSearchParams();
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [inviter, setInviter] = useState('');
    const [hasRef, setHasRef] = useState(false);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [currentStep, setCurrentStep] = useState<1 | 2>(1);

    const [code, setCode] = useState('');
    const [codeValidation, setCodeValidation] = useState('');

    useEffect(() => {
        const ref = searchParams.get('ref');
        if (ref) {
            console.log(ref);

            setHasRef(true);
            setInviter(ref);
        }
    }, []);

    const [validation, setValidation] = useState(validateObj);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleReg = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let successValidate = true;
        const newValidateObj = {
            login: {
                success: true,
                message: '',
            },
            email: {
                success: true,
                message: '',
            },
            password: {
                success: true,
                message: '',
            },
            password2: {
                success: true,
                message: '',
            },
            inviter: {
                success: true,
                message: '',
            },
        };

        if (login.length < 4) {
            newValidateObj.login.success = false;
            newValidateObj.login.message = 'Login cannot be less than 4 characters';
            successValidate = false;
        }
        if (email.length < 4) {
            newValidateObj.email.success = false;
            newValidateObj.email.message = 'Mail cannot be less than 4 characters';
            successValidate = false;
        }
        if (
            !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
                email,
            )
        ) {
            newValidateObj.email.success = false;
            newValidateObj.email.message = 'Incorrect mail format';
            successValidate = false;
        }
        if (password.length < 4) {
            newValidateObj.password.success = false;
            newValidateObj.password.message = 'Password cannot be less than 4 characters';
            successValidate = false;
        }
        if (inviter.length < 4) {
            newValidateObj.inviter.success = false;
            newValidateObj.inviter.message = 'Inviter cannot be less than 4 characters';
            successValidate = false;
        }
        if (password !== password2) {
            newValidateObj.password2.success = false;
            newValidateObj.password2.message = "The passwords don't match";
            successValidate = false;
        }

        setValidation(newValidateObj);
        if (!successValidate) return;

        const data = {
            username: login,
            email,
            ref: inviter,
        };

        console.log('data step1');
        console.log(data);

        setIsButtonLoading(true);
        const res = await registerUserVerify(data);
        setIsButtonLoading(false);
        if (!res.status) {
            if (res.message == 'usernameOrEmail.already.used') {
                setValidation({
                    ...validation,
                    email: {
                        message: 'A user with this login or mail already exists in the system',
                        success: false,
                    },
                    login: {
                        message: 'User with this login or mail is already logged in',
                        success: false,
                    },
                });
                return;
            }
            if (res.message == 'upliner.not.found') {
                setValidation({
                    ...validation,
                    inviter: {
                        message: 'Invitee with this ID was not found',
                        success: false,
                    },
                });
                return;
            }
        }
        console.log(res);
        setCurrentStep(2);
    };

    const handleSendAgaing = async () => {
        const data = {
            username: login,
            email,
            ref: inviter,
        };

        setIsButtonLoading(true);
        const res = await registerUserVerify(data);
        setIsButtonLoading(false);
        if (!res.status) return;
    };

    const wrapperValidationChange = (v: string, type: string) => {
        if (type == 'login') {
            setLogin(v);
            setValidation({
                ...validation,
                login: {
                    message: '',
                    success: true,
                },
            });
        }
        if (type == 'email') {
            setEmail(v);
            setValidation({
                ...validation,
                email: {
                    message: '',
                    success: true,
                },
            });
        }
        if (type == 'password') {
            setPassword(v);
            setValidation({
                ...validation,
                password: {
                    message: '',
                    success: true,
                },
                password2: {
                    message: '',
                    success: true,
                },
            });
        }
        if (type == 'password2') {
            setPassword2(v);
            setValidation({
                ...validation,
                password2: {
                    message: '',
                    success: true,
                },
            });
        }
        if (type == 'inviter') {
            setInviter(v);
            setValidation({
                ...validation,
                inviter: {
                    message: '',
                    success: true,
                },
            });
        }
    };

    const handleSendCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            username: login,
            email,
            password,
            code: Number(code.replaceAll(' ', '')),
            ref: inviter,
        };

        if (code.length === 0) {
            setCodeValidation('The code cannot be empty');
            return;
        }

        console.log('data step2');
        console.log(data);

        setIsButtonLoading(true);
        const res = await registerUser(data);
        console.log(res);

        setIsButtonLoading(false);
        if (!res.status) {
            if ((res.message = 'verification.code.invalid')) setCodeValidation('Incorrect code');
            return;
        }

        dispatch(setCurrentUser(res.data));
        navigate('/main');
    };

    const validationChange = (v: string) => {
        setCode(v);
        setCodeValidation('');
    };
    return (
        <div className={`${cl.container} ${className}`}>
            <Logo className={cl.logo} />
            {currentStep == 1 ? (
                <form className={cl.form} onSubmit={handleReg}>
                    <TextV className={cl.title} variant="h2">
                        Registration
                    </TextV>
                    <Input
                        label={validation.login.success ? 'Login' : validation.login.message}
                        placeholder="Enter login"
                        className={cl.input}
                        startValue={login}
                        onChange={(v) => wrapperValidationChange(v, 'login')}
                        error={!validation.login.success}
                    />
                    <Input
                        label={validation.email.success ? 'E-Mail' : validation.email.message}
                        placeholder="Enter E-Mail"
                        className={cl.input}
                        startValue={email}
                        onChange={(v) => wrapperValidationChange(v, 'email')}
                        error={!validation.email.success}
                    />
                    <Input
                        label={validation.password.success ? 'Password' : validation.password.message}
                        placeholder="Enter your password"
                        className={cl.input}
                        startValue={password}
                        onChange={(v) => wrapperValidationChange(v, 'password')}
                        error={!validation.password.success}
                        password
                    />
                    <Input
                        label={
                            validation.password2.success
                                ? 'Repeat password'
                                : validation.password2.message
                        }
                        placeholder="Repeat password"
                        className={cl.input}
                        startValue={password2}
                        onChange={(v) => wrapperValidationChange(v, 'password2')}
                        password
                        error={!validation.password2.success}
                    />
                    <Input
                        label={
                            validation.inviter.success ? 'Inviter' : validation.inviter.message
                        }
                        placeholder="Enter Inviter ID"
                        className={`${cl.input} ${cl.last_input}`}
                        startValue={inviter}
                        onChange={(v) => (hasRef ? null : wrapperValidationChange(v, 'inviter'))}
                        error={!validation.inviter.success}
                        readOnlyWithoutClass={hasRef}
                    />
                    <Link className={cl.link} to="/login">
                        <TextV>Already have an account?</TextV>
                    </Link>
                    <GradientButton formButton className={cl.button} disabled={isButtonLoading}>
                        <TextV variant="h4">
                            {isButtonLoading ? 'Loading...' : 'Sign up'}
                        </TextV>
                    </GradientButton>
                </form>
            ) : (
                <form className={cl.form} onSubmit={handleSendCode}>
                    <TextV className={cl.title} variant="h2">
                        Registration
                    </TextV>
                    <TextV className={cl.descr}>A confirmation code has been emailed to you</TextV>
                    <Input
                        label={codeValidation.length == 0 ? 'Code' : codeValidation}
                        placeholder="Enter Code"
                        className={`${cl.input} ${cl.last_input}`}
                        startValue={code}
                        onChange={validationChange}
                        error={codeValidation.length > 0}
                    />
                    <div className={cl.btns}>
                        <GradientButton formButton className={cl.button} disabled={isButtonLoading}>
                            <TextV variant="h4">{isButtonLoading ? 'Loading...' : 'Login'}</TextV>
                        </GradientButton>
                        <ColorButton onClick={handleSendAgaing}>
                            <TextV variant="h4">Send the code again</TextV>
                        </ColorButton>
                    </div>
                </form>
            )}
        </div>
    );
};

export default RegisterForm;
