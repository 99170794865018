import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract } from './types';

interface SliceContract {
    contract: Contract | null;
}

const initialState: SliceContract = {
    contract: null,
};

const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setContract: (state, action: PayloadAction<Contract>) => {
            state.contract = action.payload;
        },
    },
});

export const { setContract } = contractSlice.actions;

export default contractSlice.reducer;
