import { useEffect, useState } from 'react';
import { Application, Message } from '../../entities/Application/model/types';
import AddFileButton from '../../features/AddFile/ui/AddFileButton';
import MessagesList from '../../features/MessageList/ui';
import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Input from '../../shared/ui/Input';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getSocket } from '../../entities/Application/model/selectors';

interface ChatProps extends WithClassName {
    application: Application;
}

const Chat = ({ application, className = '' }: ChatProps): JSX.Element => {
    const [message, setMessage] = useState('');

    const socket = useSelector(getSocket);

    useEffect(() => {
        if (!application.read && socket) {
            const data = {
                typeWS: 'enterChat',
                ticketId: application.id,
            };
            socket.send(JSON.stringify(data));
        }
    }, [application.id]);

    const handleSendValue = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!socket) return;
        const data = {
            typeWS: 'chatMessage',
            ticketId: application.id,
            message,
        };
        socket.send(JSON.stringify(data));
        setMessage('');
    };

    return (
        <div className={`${cl.chat} ${className}`}>
            <TextV variant="h2" className={cl.title}>
                {application.title}
            </TextV>
            <MessagesList data={application.messages} className={cl.messages} />
            {application.status ? (
                <form className={cl.panel} onSubmit={handleSendValue}>
                    <Input
                        className={cl.input}
                        startValue={message}
                        placeholder="Messagee"
                        textarea
                        onChange={(v) => setMessage(v)}
                    />
                    <div className={cl.bths}>
                        <AddFileButton accept="image/png, image/jpeg" />
                        <GradientButton formButton className={`${cl.btn} ${socket ? '' : cl.wait}`}>
                            <TextV variant="small">{socket ? 'Send' : 'Sync'}</TextV>
                        </GradientButton>
                    </div>
                </form>
            ) : (
                <TextV className={cl.closed}>Application closed</TextV>
            )}
        </div>
    );
};

export default Chat;
