import { WithChildren, WithClassName } from '../../types';
import cl from './index.module.scss';

interface RadianBoxProps extends WithChildren, WithClassName {
    active?: boolean;
}

const RadianBox = ({ children, active = false, className = '' }: RadianBoxProps) => {
    return <div className={`${cl.box} ${active ? cl.active : ''} ${className}`}>{children}</div>;
};

export default RadianBox;
