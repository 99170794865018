import { useRef, useState } from 'react';
import { WithClassName } from '../../../shared/types';
import Input from '../../../shared/ui/Input';
import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import TextV from '../../../shared/ui/TextV';

interface InputCopyProps extends WithClassName {
    value: string;
    label: string;
}

const InputCopy = ({ className = '', value, label }: InputCopyProps): JSX.Element => {
    const [isShowCopy, setIsShowCopy] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const markerRef = useRef(null);

    const handleClickCopy = () => {
        if (timeoutId) clearTimeout(timeoutId);
        setIsShowCopy(true);
        navigator.clipboard.writeText(value);
        const t = setTimeout(() => {
            setIsShowCopy(false);
        }, 4000);
        setTimeoutId(t);
    };

    return (
        <div className={`${cl.wrapper} ${className}`}>
            <Input className={cl.input} startValue={value} label={label} readOnly />
            <div className={cl.copy} aria-label="copy" onClick={() => handleClickCopy()}>
                <img src="/assets/icons/copy.svg" alt="copy" />
            </div>
            <CSSTransition
                in={isShowCopy}
                classNames="fade"
                timeout={300}
                nodeRef={markerRef}
                mountOnEnter
                unmountOnExit>
                <div className={cl.text} ref={markerRef}>
                    <TextV variant="small">Copied from!</TextV>
                </div>
            </CSSTransition>
        </div>
    );
};

export default InputCopy;
