import { useSelector } from 'react-redux';
import cl from './index.module.scss';
import { getCurrentUser } from '../../../model/selectors';
import { Link } from 'react-router-dom';
import { getUserAvatar } from '../../../../../entities/User/lib/img';

const Panel = () => {
    const currentUser = useSelector(getCurrentUser);

    if (!currentUser) return <></>;

    return (
        <div className={cl.panel}>
            <img src="/assets/icons/flag.svg" alt="Russia" className={cl.flag} />
            <Link to="/profile">
                <div className={cl.user}>
                    <img src={getUserAvatar(currentUser.avatar)} alt="logo" />
                </div>
            </Link>
        </div>
    );
};

export default Panel;
