import { useState } from 'react';
import { WithClassName } from '../../../shared/types';
import ColorButton from '../../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../features/Session/model/selectors';
import QRCode from 'react-qr-code';

const TopUp = ({ className = '' }: WithClassName) => {
    const [isShowCopy, setIsShowCopy] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const currentUser = useSelector(getCurrentUser);

    if (!currentUser) return <></>;

    const handleClickCopy = () => {
        if (timeoutId) clearTimeout(timeoutId);
        setIsShowCopy(true);
        navigator.clipboard.writeText(currentUser.walletIn);
        const t = setTimeout(() => {
            setIsShowCopy(false);
        }, 4000);
        setTimeoutId(t);
    };

    return (
        <div className={`${cl.widget} ${className}`}>
            <div className={cl.qr_code}>
                <QRCode value={currentUser.walletIn} className={cl.qr} />
            </div>
            <div className={cl.content}>
                <div className={cl.input}>
                    <TextV className={cl.label} variant="small">
                        Wallet
                    </TextV>
                    <div className={cl.input_content}>
                        <div className={cl.input_network}>
                            <TextV variant="small">trc-20</TextV>
                        </div>
                        <TextV className={cl.wallet} variant="small">
                            {currentUser.walletIn}
                        </TextV>
                        <div className={`${cl.copy} ${cl.desctop}`} onClick={handleClickCopy}>
                            <TextV variant="small">
                                {isShowCopy ? 'Copied!' : 'Copy'}
                            </TextV>
                        </div>
                    </div>
                    <div className={`${cl.copy} ${cl.mobile}`} onClick={handleClickCopy}>
                        <TextV variant="small">{isShowCopy ? 'Copied!' : 'Copy'}</TextV>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopUp;
