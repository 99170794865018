import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Line from '../../shared/ui/Line';
import TextV from '../../shared/ui/TextV';
import WidgetContainer from '../../shared/ui/WidgetContainer';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';

const UserReferals = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    return (
        <WidgetContainer className={className} title="Structure">
            <div className={cl.refs}>
                <div className={cl.data}>
                    <TextV className={cl.referal_title}>Referrals</TextV>
                    <TextV className={cl.referal_value} variant="subtitle">
                        {currentUser.referrals}
                    </TextV>
                </div>
                {/* <div className={cl.lines}>
                    <Line
                        title="Линия 1"
                        value={`${currentUser.referrals[0]}`}
                        className={cl.line}
                    />
                    <Line
                        title="Линия 2"
                        value={`${currentUser.referrals[1]}`}
                        className={cl.line}
                    />
                    <Line
                        title="Линия 3"
                        value={`${currentUser.referrals[2]}`}
                        className={cl.line}
                    />
                </div> */}
                <GradientButton onClick={() => navigate('/team')} className={cl.btn}>
                    <TextV variant="small">Read More</TextV>
                </GradientButton>
            </div>
        </WidgetContainer>
    );
};

export default UserReferals;
