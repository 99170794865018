import { WithClassName } from '../../../../shared/types';
import TextV from '../../../../shared/ui/TextV';
import { Message } from '../../model/types';
import cl from './index.module.scss';

interface MessageBoxProps extends WithClassName {
    message: Message;
    needName: boolean;
    isCurrentUserMessage: boolean;
    isLastForUser: boolean;
    avatar: string;
}

const MessageBox = ({
    message,
    needName,
    isCurrentUserMessage,
    isLastForUser,
    avatar,
    className = '',
}: MessageBoxProps): JSX.Element => {
    return (
        <div
            className={`${cl.message} ${isCurrentUserMessage ? cl['message--current'] : ''} ${
                isLastForUser ? cl['message--last'] : ''
            }${className}`}>
            {needName && (
                <TextV className={cl.name}>{isCurrentUserMessage ? 'You' : 'Admin'}</TextV>
            )}
            <div className={cl.wrapper_message}>
                <div className={cl.message_content}>
                    <TextV>{message.text}</TextV>
                </div>
                <div className={cl.user_img}>
                    <img src={avatar} alt="" />
                </div>
            </div>
        </div>
    );
};

export default MessageBox;
