import TextV from '../../shared/ui/TextV';
import MapWidget from '../../widgets/MapWidget';
import cl from './index.module.scss';

const MediaPage = () => {
    return (
        <div className={cl.page}>
            <div className={cl.text}>
                <TextV variant="h3" container="h1" className={cl.title}>
                    Partnerships
                </TextV>
                <TextV variant="small" className={cl.sub}>
                    Mentions of our partners
                </TextV>
            </div>
            <MapWidget />
        </div>
    );
};

export default MediaPage;
