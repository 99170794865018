import { useState } from 'react';
import { WithClassName } from '../../shared/types';
import cl from './index.module.scss';
import InputMoney from '../../shared/ui/InputMoney';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import TextV from '../../shared/ui/TextV';
import { getFormatedPrice } from '../../shared/utils/price';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';
import { getUserRangInfoBySum } from '../../features/UserRangs/lib/utils';
import React from 'react';
import { createContract, getActiveContracts } from '../../entities/Contract/api';
import { useAppDispatch } from '../../app/store';
import { setContract } from '../../entities/Contract/model/slice';
import { getUser } from '../../entities/User/api';
import { setCurrentUser } from '../../features/Session/model/slice';
import { getContract } from '../../entities/Contract/model/selectors';

const ContractInvest = ({ className }: WithClassName) => {
    const [sumInvest, setSumInvest] = useState('0');
    const [validateError, setValidateError] = useState(false);
    const currentUser = useSelector(getCurrentUser);
    const [isLoading, setIsLoading] = useState(false);
    const dispatсh = useAppDispatch();
    const contract = useSelector(getContract);

    const contractCost = contract ? contract.cost : 0;

    if (!currentUser) return <></>;
    const sumNumber = parseInt(sumInvest.replaceAll(/\s+/g, ''));

    const handleClick = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (sumNumber < 100) {
            setValidateError(true);
            return;
        }
        setIsLoading(true);
        await createContract(sumNumber);
        const contract = getActiveContracts();
        const user = getUser();
        const res = await Promise.all([contract, user]);

        dispatсh(setContract(res[0]));
        dispatсh(setCurrentUser(res[1].data));
        setSumInvest('0');
        setIsLoading(false);
    };

    const handleChange = (v: string) => {
        setValidateError(false);
        setSumInvest(v);
    };

    const totalDeposit = sumNumber + contractCost;
    const percent = contract != null ? contract.percent : 10
    const profitability = currentUser.balance - sumNumber;
    const profit = totalDeposit / 100 * percent;

    return (
        <div className={`${cl.widget} ${className}`}>
            <div className={cl.calc}>
                <form className={cl.calc_up} onSubmit={handleClick}>
                    <InputMoney
                        className={cl.input}
                        startValue={sumInvest}
                        label={validateError ? 'Minimum amount - 100' : 'Investment amount'}
                        error={validateError}
                        onChange={(v) => handleChange(v)}
                    />
                    <GradientButton disabled={isLoading} formButton className={cl.button}>
                        <TextV variant="small">
                            {isLoading ? 'Investing...' : 'Invest'}
                        </TextV>
                    </GradientButton>
                </form>
                <div className={cl.calc_infos}>
                    <div className={cl.info}>
                        <TextV variant="small" className={cl.info_label}>
                            Balance
                        </TextV>
                        <TextV variant="h2" className={cl.info_value}>
                            {getFormatedPrice(profitability)}
                        </TextV>
                    </div>
                    <div className={cl.info}>
                        <TextV variant="small" className={cl.info_label}>
                            Total deposit amount
                        </TextV>
                        <TextV variant="h2" className={cl.info_value}>
                            {getFormatedPrice(totalDeposit)}
                        </TextV>
                    </div>
                    <div className={cl.info}>
                        <TextV variant="small" className={cl.info_label}>
                            Net income
                        </TextV>
                        <TextV variant="h2" className={cl.info_value}>
                            {getFormatedPrice(profit)}
                        </TextV>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ContractInvest);
