import { useSelector } from 'react-redux';
import InputCopy from '../../features/InputCopy/ui';
import ProfileBio from '../../features/ProfileBio/ui';
import ProfileImg from '../../features/ProfileImg/ui';
import { getCurrentUser } from '../../features/Session/model/selectors';
import ExitButton from '../../features/Session/ui/ExitButton';
import { WithClassName } from '../../shared/types';
import Input from '../../shared/ui/Input';
import cl from './index.module.scss';

const Profile = ({ className = '' }: WithClassName) => {
    const currentUser = useSelector(getCurrentUser);

    if (!currentUser) return <></>;

    const link = `${window.location.origin}/registration?ref=${currentUser.userId}`;

    return (
        <div className={`${cl.profile} ${className}`}>
            <div className={cl.bio}>
                <ProfileImg className={cl.bio_img} />
                <div className={cl.bio_texts}>
                    <div className={cl.bio_texts_main}>
                        <ProfileBio className={cl.bio_texts_main_bio} />
                        <ExitButton />
                    </div>
                    {/* <ProfileStatus className={cl.bio_texts_status} /> */}
                </div>
            </div>
            <div className={cl.links}>
                <Input
                    label="E-mail"
                    startValue={currentUser.email}
                    readOnly
                    className={cl.input}
                />
                <InputCopy label="Your link" value={link} className={cl.input} />
            </div>
        </div>
    );
};

export default Profile;
