import { WithClassName } from '../../shared/types';
import ContactLink from '../../shared/ui/Contact/ContactLink';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';

const ContactSocial = ({ className }: WithClassName) => {
    return (
        <div className={`${cl.contact} ${className}`}>
            <TextV className={cl.title} variant="subtitle">
                <>
                    <img src="/assets/icons/chat.svg" alt="chat" /> Social media
                </>
            </TextV>
            <div className={cl.grid}>
                <ContactLink
                    title="Facebook"
                    img="/assets/icons/Facebook.svg"
                    alt="Facebook"
                    href="https://www.facebook.com/intelfingenius"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/Instagram.svg"
                    alt="Instagram"
                    title="Instagram"
                    href="https://www.instagram.com/intelfin.global/"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/LinkedIn.svg"
                    alt="LinkedIn"
                    title="LinkedIn"
                    href="https://www.linkedin.com/company/intelfin-global"
                    className={cl.contact}
                />
                <ContactLink
                    title="Twitter"
                    img="/assets/icons/Twitter.svg"
                    alt="Twitter"
                    href="https://twitter.com/intelfin_global"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/Medium.svg"
                    alt="Medium"
                    title="Medium"
                    href="https://intelfinglobal.medium.com/"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/telegram.svg"
                    alt="Telegram"
                    title="Telegram"
                    href="https://t.me/intelfin_group"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/TradingView.svg"
                    alt="TradingView"
                    title="TradingView"
                    href="https://www.tradingview.com/u/IntelfinGlobal/"
                    className={cl.contact}
                />
                <ContactLink
                    img="/assets/icons/Reddit.svg"
                    alt="Reddit"
                    title="Reddit"
                    href="https://www.reddit.com/r/Intelfinglobal/"
                    className={cl.contact}
                />
            </div>
        </div>
    );
};

export default ContactSocial;
