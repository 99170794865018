import { Link } from 'react-router-dom';
import { WithClassName } from '../../../../shared/types';
import TextV from '../../../../shared/ui/TextV';
import { Application } from '../../model/types';
import cl from './index.module.scss';

interface ApplicationCard extends WithClassName {
    application: Application;
}

const ApplicationCard = ({ application, className = '' }: ApplicationCard) => {
    console.log(application);

    return (
        <Link
            to={`/support/${application.id}`}
            className={`${cl.application} ${
                !application.read ? cl['application--new'] : ''
            }${className}`}>
            <div className={cl.application_up}>
                <TextV className={cl.id}>
                    <>#{application.chatId}</>
                </TextV>
                <TextV className={cl.status}>{application.status ? 'Open' : 'Closed'}</TextV>
            </div>
            <TextV className={cl.application_bottom}>{application.title}</TextV>
        </Link>
    );
};

export default ApplicationCard;
