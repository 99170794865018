import { WithClassName } from '../../shared/types';
import ContactMail from '../../shared/ui/Contact/ContactMail';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';

const ContactSupport = ({ className }: WithClassName) => {
    return (
        <div className={`${cl.contact} ${className}`}>
            <TextV className={cl.title} variant="subtitle">
                <>
                    <img src="/assets/icons/question.svg" alt="question" />
                    Support
                </>
            </TextV>
            <div className={cl.grid}>
                <ContactMail
                    title="Information Center"
                    mail="info@intelfin.io"
                    img="/assets/icons/info.svg"
                    alt="info"
                    className={cl.contact}
                />
                <ContactMail
                    mail="support@intelfin.io"
                    img="/assets/icons/support.svg"
                    alt="Tech support"
                    title="Tech support"
                    className={cl.contact}
                />
                <ContactMail
                    mail="invest@intelfin.io"
                    img="/assets/icons/student.svg"
                    alt="Institutional investor"
                    title="Institutional investor"
                    className={cl.contact}
                />
                <ContactMail
                    mail="partnership@intelfin.io"
                    img="/assets/icons/portfel.svg"
                    alt="Development"
                    title="Development and partnership"
                    className={cl.contact}
                />
            </div>
        </div>
    );
};

export default ContactSupport;
