import { useSelector } from 'react-redux';
import { WithClassName } from '../../../shared/types';
import NavigationWithSub from './NavigationWithSub';
import cl from './index.module.scss';
import { getCurrentUser } from '../../Session/model/selectors';
import { Link } from 'react-router-dom';

const routesUser = [
    {
        text: 'Cabinet',
        to: '/profile',
    },
    {
        text: 'Referrals',
        to: '/team',
    },
    {
        text: 'History',
        to: '/history',
    },
    {
        text: 'Support',
        to: '/support',
    },
];

const routesInfo = [
    {
        text: 'About the company',
        to: '/#platform',
    },
    {
        text: 'Marketing',
        to: '/marketing',
    },
    {
        text: 'Partnerships',
        to: '/partnerships',
    },
    {
        text: 'Contact us',
        to: '/contacts',
    }
    // {
    //     text: 'Vacancies',
    //     to: '/vacancies',
    // },
    // {
    //     text: 'Presentation',
    //     to: '/#',
    // },
];

const NavigationDefault = ({ className }: WithClassName) => {
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser)
        return (
            <nav className={`${cl.nav} ${cl.nav__default}${className ? className : ''}`}>
                <Link to="/login">User</Link>
                <Link to="/login">Company products</Link>
                <NavigationWithSub title="Information Center" mainHref="/contacts" subMenu={routesInfo} />
            </nav>
        );

    return (
        <nav className={`${cl.nav} ${cl.nav__default}${className ? className : ''}`}>
            <Link to="/main">
                Main
            </Link>
            <NavigationWithSub title="User" mainHref="/profile" subMenu={routesUser} />
            <Link to="/contract">
                Contract
            </Link>
            <NavigationWithSub title="Information Center" mainHref="/contacts" subMenu={routesInfo} />
        </nav>
    );
};

export default NavigationDefault;
