import { WithClassName } from '../../../shared/types';
import ColorButton from '../../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import InputMoney from '../../../shared/ui/InputMoney';
import { useState } from 'react';
import InputSmall from '../../../shared/ui/InputSmall';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../features/Session/model/selectors';
import { getUser, userWithDraw, userWithDrawVerify } from '../../../entities/User/api';
import { setCurrentUser } from '../../../features/Session/model/slice';
import { useAppDispatch } from '../../../app/store';

const WithDraw = ({ className = '' }: WithClassName) => {
    const currentUser = useSelector(getCurrentUser);

    const [sum, setSum] = useState('0');
    const [code, setCode] = useState('');
    const [wallet, setWallet] = useState(currentUser?.wallet ? currentUser.wallet : '');
    const [walletError, setWalletError] = useState('');
    const [sumError, setSumError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [success, setSuccess] = useState('');

    const [emailTimer, setEmailTimer] = useState<NodeJS.Timeout | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispath = useAppDispatch();

    const handleClickSend = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSuccess('');
        setWalletError('');
        setSumError('');
        let validate = true;
        const sumNumber = Number(sum.replace(/\D/g, ''));

        if (!/T[A-Za-z1-9]{33}/.test(wallet)) {
            validate = false;
            setWalletError('Кошелёк указан неверно');
        }
        if (sumNumber < 2) {
            validate = false;
            setSumError('Минимальная сумма вывода - 2$');
        }
        if (code.length < 1) {
            validate = false;
            setCodeError('Нужно указать код');
        }
        if (!validate) return;

        const codeNumber = Number(code.replaceAll(' ', ''));
        const res = await userWithDraw(sumNumber, wallet, codeNumber);

        if (!res.status) {
            if (res.message === 'transfer.self') {
                setWalletError('Вы не можете перевести себе');
            }
            if (res.message === 'transfer.amount.min') {
                setSumError('Минимальная сумма - 2$');
            }
            if (res.message === 'not.verified') {
                setWalletError('Вы не верефицированны');
            }
            if (res.message === 'transfer.balance.low') {
                setSumError('Недостаточно средств');
            }
            if (res.message === 'transfer.user.not.found') {
                setWalletError('Пользователь не найден');
            }
            if (res.message === 'twoFactor.invalid') {
                setCodeError('Неверный код');
            }
            if (res.message === 'verification.code.invalid') {
                setCodeError('Неверный код');
            }
            if (res.message === 'email.send.failed') {
                setCodeError('Ошибка при отправке email');
            }
            return;
        }
        const user = await getUser();
        if (user.status) dispath(setCurrentUser(user.data));

        setSum('');
        setCode('');
        setWalletError('');
        setSumError('');
        setCodeError('');
        setSuccess('Заявка отправлена');
    };

    const handleSendEmail = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    ) => {
        if (!e) return;

        e.preventDefault();
        e.stopPropagation();

        setSuccess('');
        setWalletError('');
        setSumError('');

        let validate = true;
        const sumNumber = Number(sum.replace(/\D/g, ''));

        if (!/T[A-Za-z1-9]{33}/.test(wallet)) {
            validate = false;
            setWalletError('Кошелёк указан неверно');
        }
        if (sumNumber < 100) {
            validate = false;
            setSumError('Минимальная сумма вывода - 100');
        }
        if (!validate) return;

        setIsLoading(true);
        const res = await userWithDrawVerify(sumNumber, wallet);
        setIsLoading(false);

        console.log('SENDEMAIL');
        console.log(res);

        if (!res.status) {
            if (res.message === 'transfer.self') {
                setWalletError('Вы не можете перевести себе');
            }
            if (res.message === 'transfer.amount.min') {
                setSumError('Минимальная сумма - 2$');
            }
            if (res.message === 'not.verified') {
                setWalletError('Вы не верефицированны');
            }
            if (res.message === 'transfer.balance.low') {
                setSumError('Недостаточно средств');
            }
            if (res.message === 'transfer.user.not.found') {
                setWalletError('Пользователь не найден');
            }
            if (res.message === 'twoFactor.invalid') {
                setCodeError('Неверный код');
            }
            if (res.message === 'verification.code.invalid') {
                setCodeError('Неверный код');
            }
            if (res.message === 'email.send.failed') {
                setCodeError('Ошибка при отправке email');
            }
            return;
        }

        setWalletError('');
        setSumError('');

        if (emailTimer) clearTimeout(emailTimer);

        const t = setTimeout(() => {
            setEmailTimer(null);
        }, 4000);

        setEmailTimer(t);
    };

    if (!currentUser) return <></>;

    return (
        <form className={`${cl.widget} ${className}`} onSubmit={handleClickSend}>
            <div className={cl.inputs}>
                {currentUser.wallet ? (
                    <div className={cl.input}>
                        <TextV
                            className={`${cl.label} ${success.length > 0 ? cl.success : ''}`}
                            variant="small">
                            {success.length > 0 ? success : 'Ваш кошелек'}
                        </TextV>
                        <div className={cl.input_content}>
                            <div className={cl.input_network}>
                                <TextV variant="small">trc-20</TextV>
                            </div>
                            <TextV className={cl.wallet} variant="small">
                                {wallet}
                            </TextV>
                        </div>
                    </div>
                ) : (
                    <div className={cl.placeholder_wallet}>
                        <div className={cl.input_network}>
                            <TextV variant="small">trc-20</TextV>
                        </div>
                        <InputSmall
                            className={cl.input_wallet}
                            label={
                                success.length
                                    ? success
                                    : walletError.length
                                    ? walletError
                                    : 'Ваш кошелек'
                            }
                            error={walletError.length > 0}
                            success={success.length > 0}
                            startValue={code}
                            onChange={(v) => {
                                setWalletError('');
                                setWallet(v);
                            }}
                        />
                    </div>
                )}
                <InputMoney
                    label={sumError.length ? sumError : 'Сумма вывода'}
                    error={sumError.length > 0}
                    startValue={sum}
                    onChange={(v) => {
                        setSumError('');
                        setSum(v);
                    }}
                    small
                />
                {currentUser.twoFactorSecretEnable ? (
                    <InputSmall
                        label={codeError ? codeError : 'Код Google Auth'}
                        startValue={code}
                        onChange={(v) => {
                            setCodeError('');
                            setCode(v);
                        }}
                        error={codeError.length > 0}
                    />
                ) : (
                    <div className={cl.mail}>
                        <InputSmall
                            className={cl.main_input}
                            label={codeError ? codeError : 'Код с почты'}
                            startValue={code}
                            onChange={(v) => {
                                setCodeError('');
                                setCode(v);
                            }}
                            error={codeError.length > 0}
                        />
                        <GradientButton
                            disabled={emailTimer !== null}
                            className={cl.email_btn}
                            onClick={(e) => handleSendEmail(e)}>
                            <TextV variant="small">
                                {isLoading
                                    ? 'Отправляю...'
                                    : emailTimer
                                    ? 'Отправлено'
                                    : 'Отправить код'}
                            </TextV>
                        </GradientButton>
                    </div>
                )}
            </div>

            <div className={cl.btns}>
                <GradientButton formButton className={cl.button}>
                    <TextV variant="small">Готово</TextV>
                </GradientButton>
                <ColorButton onClick={() => console.log('click')} className={cl.button}>
                    <TextV variant="small">Отмена</TextV>
                </ColorButton>
            </div>
        </form>
    );
};

export default WithDraw;