import { WithChildren, WithClassName } from '../../types';
import cl from './index.module.scss';

export type TextVariant = 'title' | 'subtitle' | 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'small';

interface TextVProps extends WithClassName, WithChildren {
    container?: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
    variant?: TextVariant;
}

const classObj = {
    title: '',
    subtitle: cl.subtitle,
    default: cl.default,
    h1: cl.h1,
    h2: cl.h2,
    h3: cl.h3,
    h4: cl.h4,
    small: cl.small,
};

const TextV = ({
    children,
    className,
    container = 'p',
    variant = 'default',
}: TextVProps): JSX.Element => {
    const finalClassName = `${classObj[variant]} ${className ? className : ''}`;

    switch (container) {
        case 'h1':
            return <h1 className={finalClassName}>{children}</h1>;
        case 'h2':
            return <h2 className={finalClassName}>{children}</h2>;
        case 'h3':
            return <h3 className={finalClassName}>{children}</h3>;
        case 'h4':
            return <h4 className={finalClassName}>{children}</h4>;
        case 'p':
            return <p className={finalClassName}>{children}</p>;

        default:
            const neverCheck: never = container;
            return <></>;
    }
};

export default TextV;
