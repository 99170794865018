import { useState } from 'react';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import Input from '../../shared/ui/Input';
import ColorButton from '../../shared/ui/Buttons/ColorButton';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import { WithClassName } from '../../shared/types';
import AddFileButton from '../../features/AddFile/ui/AddFileButton';
import { useSelector } from 'react-redux';
import { getSocket } from '../../entities/Application/model/selectors';
import { useNavigate } from 'react-router-dom';

const NewApplication = ({ className = '' }: WithClassName) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    const socket = useSelector(getSocket);

    const navigate = useNavigate();
    console.log(socket);

    const handeSend = () => {
        setShowErrors(false);

        if (!subject.length || !message.length) {
            setShowErrors(true);
            return;
        }

        if (!socket) return;

        const data = {
            typeWS: 'createTicket',
            title: subject,
            message,
        };

        socket.send(JSON.stringify(data));
        navigate('/support');
    };

    return (
        <div className={`${cl.form} ${className}`}>
            <TextV variant="h3" container="h2" className={cl.title}>
                New application
            </TextV>
            <div className={cl.inputs}>
                <Input
                    className={cl.input}
                    startValue={subject}
                    onChange={setSubject}
                    placeholder="Message subject"
                    error={showErrors && !subject.length}
                />
                <Input
                    className={cl.textarea}
                    startValue={message}
                    onChange={setMessage}
                    textarea
                    placeholder="Message"
                    error={showErrors && !message.length}
                />
            </div>
            <div className={cl.bths}>
                <AddFileButton accept="image/png, image/jpeg" />
                <GradientButton onClick={handeSend} className={cl.btn}>
                    <TextV variant="small">Send</TextV>
                </GradientButton>
            </div>
        </div>
    );
};

export default NewApplication;
