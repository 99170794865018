import { useAppDispatch } from '../../../../app/store';
import ColorButton from '../../../../shared/ui/Buttons/ColorButton';
import ModalContainer from '../../../../shared/ui/ModalContainer/ui';
import TextV from '../../../../shared/ui/TextV';
import { logoutUser } from '../../api';
import { setCurrentUser } from '../../model/slice';
import cl from './index.module.scss';

interface ExitModalProps {
    isShow: boolean;
    handleClose: () => void;
}

const ExitModal = ({ isShow, handleClose }: ExitModalProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const handleClickExit = async () => {
        const res = await logoutUser(false);
        handleClose();
        dispatch(setCurrentUser(null));
    };
    const handleClickExitAll = async () => {
        const res = await logoutUser(false);
        handleClose();
        dispatch(setCurrentUser(null));
    };

    return (
        <ModalContainer isShowModal={isShow} handleClose={handleClose}>
            <div className={cl.content}>
                <TextV className={cl.title} variant="h3">
                    Log out
                </TextV>
                <TextV className={cl.desc} variant="small">
                    Are you sure you want to log out of the account?
                </TextV>
                <div className={cl.btns}>
                    <ColorButton
                        onClick={handleClickExit}
                        className={`${cl.buttonSolo} ${cl.button}`}>
                        <TextV variant="small" className={cl.button_text}>
                            Log out
                        </TextV>
                        <img src="/assets/icons/exit.svg" alt="exit from profile" />
                    </ColorButton>
                    <ColorButton
                        onClick={handleClickExitAll}
                        className={`${cl.buttonAll} ${cl.button}`}>
                        <TextV variant="small" className={cl.button_text}>
                            Log out from all devices
                        </TextV>
                        <img src="/assets/icons/exitAll.svg" alt="exit from profile" />
                    </ColorButton>
                </div>
            </div>
        </ModalContainer>
    );
};

export default ExitModal;
