import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';

const Media = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    return (
        <div className={`${cl.widget} ${className}`}>
            <TextV variant="h3" className={cl.title}>
                Мероприятия
            </TextV>

            <GradientButton onClick={() => navigate('/admin/media')} className={cl.button}>
                <TextV variant="small">Создать пост</TextV>
            </GradientButton>
        </div>
    );
};

export default Media;
