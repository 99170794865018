import { useId, useState } from 'react';
import { WithClassName } from '../../../shared/types';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../Session/model/selectors';
import { changePhoto } from '../../../entities/User/api';
import { useAppDispatch } from '../../../app/store';
import { setCurrentUser } from '../../Session/model/slice';
import { getUserAvatar } from '../../../entities/User/lib/img';

const ProfileImg = ({ className = '' }: WithClassName) => {
    const [file, setFile] = useState<File | null>(null);
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useAppDispatch();

    const editId = useId();

    const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files);

        if (!e.target.files) return;
        setFile(e.target.files[0]);

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);
        const res = await changePhoto(formData);
        console.log(res);

        if (!res.status) return;
        console.log(res);

        dispatch(
            setCurrentUser({ ...res.data, avatar: `${res.data.avatar}?${new Date().getTime()}` }),
        );
    };

    if (!currentUser) return <></>;

    return (
        <div className={`${className} ${cl.wrapper}`}>
            <div className={cl.img}>
                <img src={getUserAvatar(currentUser.avatar)} alt="logo" />
            </div>
            <label className={cl.edit} aria-label="Edit profile image" htmlFor={editId}>
                <input
                    type="file"
                    id={editId}
                    onChange={(e) => handleUpload(e)}
                    accept="image/*"
                    className={cl.hidden}
                />
                <img src="/assets/icons/edit.svg" alt="edit profile img" />
            </label>
        </div>
    );
};

export default ProfileImg;
