import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { WithChildren, WithClassName } from '../../../shared/types';
import cl from './index.module.scss';
import { Swiper } from 'swiper/react';

interface SwiperBlurProps extends WithChildren, WithClassName {}

const SwiperBlur = ({ children, className }: SwiperBlurProps): JSX.Element => {
    const [swiper, setSwiper] = useState<any>(null);
    const blurLeftRef = useRef<HTMLDivElement>(null);
    const blurRightRef = useRef<HTMLDivElement>(null);
    const [isShowBlurLeft, setIsShowBlurLeft] = useState(false);
    const [isShowBlurRight, setIsShowBlurRight] = useState(true);

    const hadleChange = () => {
        if (!swiper) return;

        setIsShowBlurLeft(!swiper.isBeginning);
        setIsShowBlurRight(!swiper.isEnd);
    };

    return (
        <div className={`${cl.container} ${cl.className}`}>
            <CSSTransition
                in={isShowBlurLeft}
                nodeRef={blurLeftRef}
                classNames={'fade'}
                timeout={300}
                mountOnEnter={true}
                unmountOnExit={true}>
                <div className={cl.blur_left} ref={blurLeftRef}></div>
            </CSSTransition>
            <Swiper
                slidesPerView={'auto'}
                className={cl.slider}
                spaceBetween={20}
                onSwiper={(swiper: any) => setSwiper(swiper)}
                onSlideChange={() => hadleChange()}
                breakpoints={{
                    490: {
                        spaceBetween: 20,
                    },
                    960: {
                        spaceBetween: 26,
                    },
                    1200: {
                        spaceBetween: 40,
                    },
                    1920: {
                        spaceBetween: 50,
                    },
                }}>
                {children}
            </Swiper>
            <CSSTransition
                in={isShowBlurRight}
                nodeRef={blurRightRef}
                classNames={'fade'}
                timeout={300}
                mountOnEnter={true}
                unmountOnExit={true}>
                <div className={cl.blur_right} ref={blurRightRef}></div>
            </CSSTransition>
        </div>
    );
};

export default SwiperBlur;
