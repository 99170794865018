import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getAdminMain } from '../../../entities/User/model/admin/selectors';

const Support = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    const mainData = useSelector(getAdminMain);
    return (
        <div className={`${cl.widget} ${className}`}>
            <TextV variant="h3" className={cl.title}>
                Техническая поддержка
            </TextV>
            <div className={cl.inputs}>
                <div className={cl.read}>
                    <TextV className={cl.input_label} variant="small">
                        Всего заявок
                    </TextV>
                    <TextV className={cl.input_value} variant="subtitle">
                        {mainData.totalSupport.toLocaleString()}
                    </TextV>
                </div>
                <div className={cl.read}>
                    <TextV className={cl.input_label} variant="small">
                        Новых заявок
                    </TextV>
                    <TextV className={cl.input_value} variant="subtitle">
                        {mainData.totalNewSupport.toLocaleString()}
                    </TextV>
                </div>
            </div>
            <GradientButton onClick={() => navigate('/admin/support')} className={cl.button}>
                <TextV variant="small">Подробнее</TextV>
            </GradientButton>
        </div>
    );
};

export default Support;
