import { createBrowserRouter } from 'react-router-dom';
import IndexPage from '../../pages/IndexPage';
import LoginPage from '../../pages/LoginPage';
import RegisterPage from '../../pages/RegisterPage';
import RecoverPasswordPage from '../../pages/RecoverPasswordPage';
import FAQsPage from '../../pages/FAQsPage';
import PageContainer from '../../pages/helpers/PageContainer';
import MediaPage from '../../pages/MediaPage';
import MediaPageItem from '../../pages/MediaPage/MediaPageItem';
import ProfilePage from '../../pages/ProfilePage';
import ContactPage from '../../pages/ContactPage';
import ApplicationsPage from '../../pages/ApplicationsPage';
import ApplicationChatPage from '../../pages/ApplicationsPage/ApplicationChatPage';
import CreateApplication from '../../pages/CreateApplication';
import BlogPage from '../../pages/BlogPage';
import NewsPage from '../../pages/NewsPage';
import EventsPage from '../../pages/EventsPage';
import EventPage from '../../pages/EventPage';
import MarketingPage from '../../pages/MarketingPage';
import HistoryPage from '../../pages/HistoryPage';
import TeamPage from '../../pages/TeamPage';
import DashBoardPage from '../../pages/DashBoardPage';
import ContractPage from '../../pages/ContractPage';
import TransactionsPage from '../../pages/transactions/DepositPage';
import HistoryDeposits from '../../widgets/History/HistoryDeposits';
import HistoryDividents from '../../widgets/History/HistoryDividents';
import HistoryInvests from '../../widgets/History/HistoryInvests';
import HistoryTeam from '../../widgets/History/HistoryTeam';
import HistoryWithdraws from '../../widgets/History/HistoryWithdraws';
import DepositPage from '../../pages/transactions/DepositPage';
import WithdrawPage from '../../pages/transactions/WithdrawPage';
import OnlyNotAuthorizedRoute from '../../features/Session/lib/OnlyNotAuthorizedRoute';
import AdminPage from '../../pages/admin/Index';
import CreateNews from '../../pages/admin/CreateNews';
import CreateEvent from '../../pages/admin/CreateEvent';
import ApplicationsAdminPage from '../../pages/admin/ApplicationsAdminPage';
import ApplicationAdminChatPage from '../../pages/admin/ApplicationsAdminPage/ApplicationAdminChatPage';
import OnlyAuthorizedRoute from '../../features/Session/lib/OnlyAuthorizedRoute';
import OnlyAdminRoute from '../../features/Session/lib/OnlyAdminRoute';
import TransactionHistoryPage from '../../pages/admin/TransactionHistoryPage';
import HistoryDepositsAdmin from '../../widgets/admin/TransactionsHistory/HistoryDepositsAdmin';
import HistoryWithdrawsAdmin from '../../widgets/admin/TransactionsHistory/HistoryWithdrawsAdmin';
import VacanciesPage from '../../pages/VacanciesPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <IndexPage />,
    },
    {
        path: '/login',
        element: (
            <OnlyNotAuthorizedRoute>
                <LoginPage />
            </OnlyNotAuthorizedRoute>
        ),
    },
    {
        path: '/registration',
        element: (
            <OnlyNotAuthorizedRoute>
                <RegisterPage />
            </OnlyNotAuthorizedRoute>
        ),
    },
    {
        path: '/recovery',
        element: (
            <OnlyNotAuthorizedRoute>
                <RecoverPasswordPage />
            </OnlyNotAuthorizedRoute>
        ),
    },
    {
        path: '/',
        element: <PageContainer />,
        children: [
            // {
            //     path: '/info',
            //     element: <FAQsPage />,
            // },
            {
                path: '/partnerships',
                element: <MediaPage />,
            },
            // {
            //     path: '/media/:mediaName',
            //     element: <MediaPageItem />,
            // },
            {
                path: '/profile',
                element: (
                    <OnlyAuthorizedRoute>
                        <ProfilePage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/contacts',
                element: <ContactPage />,
            },
            {
                path: '/support',
                element: (
                    <OnlyAuthorizedRoute>
                        <ApplicationsPage />
                    </OnlyAuthorizedRoute>
                ),
                children: [
                    {
                        path: '/support/:id',
                        element: <ApplicationChatPage />,
                    },
                ],
            },
            {
                path: '/support/mob/:id',
                element: (
                    <OnlyAuthorizedRoute>
                        <ApplicationChatPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/support/new',
                element: (
                    <OnlyAuthorizedRoute>
                        <CreateApplication />
                    </OnlyAuthorizedRoute>
                ),
            },
            // {
            //     path: '/vacancies',
            //     element: <VacanciesPage />,
            // },
            // {
            //     path: '/blog',
            //     element: <BlogPage />,
            // },
            // {
            //     path: '/blog/:newsId',
            //     element: <NewsPage />,
            // },
            // {
            //     path: '/events',
            //     element: <EventsPage />,
            // },
            // {
            //     path: '/events/:eventId',
            //     element: <EventPage />,
            // },
            {
                path: '/marketing',
                element: (
                    <OnlyAuthorizedRoute>
                        <MarketingPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/history',
                element: (
                    <OnlyAuthorizedRoute>
                        <HistoryPage />
                    </OnlyAuthorizedRoute>
                ),
                children: [
                    {
                        path: '/history/deposits',
                        element: <HistoryDeposits />,
                    },
                    {
                        path: '/history/withdraws',
                        element: <HistoryWithdraws />,
                    },
                    {
                        path: '/history/team',
                        element: <HistoryTeam />,
                    },
                    {
                        path: '/history/invests',
                        element: <HistoryInvests />,
                    },
                    {
                        path: '/history/dividents',
                        element: <HistoryDividents />,
                    },
                ],
            },
            {
                path: '/team',
                element: (
                    <OnlyAuthorizedRoute>
                        <TeamPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/main',
                element: (
                    <OnlyAuthorizedRoute>
                        <DashBoardPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/contract',
                element: (
                    <OnlyAuthorizedRoute>
                        <ContractPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/deposit',
                element: (
                    <OnlyAuthorizedRoute>
                        <DepositPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/withdraw',
                element: (
                    <OnlyAuthorizedRoute>
                        <WithdrawPage />
                    </OnlyAuthorizedRoute>
                ),
            },
            {
                path: '/admin',
                element: (
                    <OnlyAdminRoute>
                        <AdminPage />
                    </OnlyAdminRoute>
                ),
            },
            {
                path: '/admin/blog',
                element: (
                    <OnlyAdminRoute>
                        <CreateNews />
                    </OnlyAdminRoute>
                ),
            },
            {
                path: '/admin/media',
                element: (
                    <OnlyAdminRoute>
                        <CreateEvent />
                    </OnlyAdminRoute>
                ),
            },
            {
                path: '/admin/transaction',
                element: (
                    <OnlyAdminRoute>
                        <TransactionHistoryPage />
                    </OnlyAdminRoute>
                ),
                children: [
                    {
                        path: '/admin/transaction/withdraw',
                        element: <HistoryWithdrawsAdmin />,
                    },
                    {
                        path: '/admin/transaction/deposit',
                        element: <HistoryDepositsAdmin />,
                    },
                ],
            },
            {
                path: '/admin/support',
                element: (
                    <OnlyAdminRoute>
                        <ApplicationsAdminPage />
                    </OnlyAdminRoute>
                ),
                children: [
                    {
                        path: '/admin/support/:id',
                        element: <ApplicationAdminChatPage />,
                    },
                ],
            },
            {
                path: '/admin/support/mob/:id',
                element: (
                    <OnlyAdminRoute>
                        <ApplicationAdminChatPage />
                    </OnlyAdminRoute>
                ),
            },
        ],
    },
]);

export default router;
