import { useEffect, useState } from 'react';
import FinPagination from '../../../features/FinPagination/ui';
import TransactionStatus from '../../../features/TransactionStatus/ui';
import TextV from '../../../shared/ui/TextV';
import { cuteFormateDateWithTime } from '../../../shared/utils/date';
import { getFormatedPrice } from '../../../shared/utils/price';
import cl from '../index.module.scss';
import { getUserHistory } from '../../../entities/User/api';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/store';
import { getCurrentUserHistory } from '../../../entities/User/model/selectors';
import { setHistory } from '../../../entities/User/model/slice';
import { HistoryType } from '../../../entities/User/model/types';
import { getCurrentUser } from '../../../features/Session/model/selectors';

const type: HistoryType = 'deposit';
const perPage = 10;

const HistoryDeposits = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    const { currentPage, data, total } = useSelector(getCurrentUserHistory(type));

    const currentUser = useSelector(getCurrentUser);
    const dispatch = useAppDispatch();

    const totalPages = Math.ceil(total / perPage);

    //при первой загрузке смотрим, есть ли данные, и если нет - делаем запрос
    useEffect(() => {
        if (total == 0) onChangePage(1);
    }, []);

    //при изменении страницы запрашиваем данные
    const onChangePage = async (p: number) => {
        setIsLoading(true);
        const res = await getUserHistory({
            type: type,
            offset: (p - 1) * perPage,
            count: perPage,
        });
        setIsLoading(false);
        if (!res.status) return;
        const obj = {
            type: type,
            page: p,
            data: res.data.history,
            total: res.data.total,
        };
        dispatch(setHistory(obj));
    };
    if (!currentUser) return <></>;
    return (
        <>
            {isLoading && total == 0 ? (
                <TextV className={cl.loading}>Loading...</TextV>
            ) : data.length > 0 ? (
                <table className={cl.table}>
                    <tbody>
                        <tr className={cl.table_up}>
                            <td>
                                <TextV className={`${cl.name} ${cl.row_value_up}`}>Source</TextV>
                            </td>
                            <td>
                                <TextV className={`${cl.sum} ${cl.row_value_up}`}>Amount</TextV>
                            </td>
                            <td>
                                <TextV className={`${cl.time} ${cl.row_value_up}`}>Time</TextV>
                            </td>
                            <td>
                                <TextV className={`${cl.status} ${cl.row_value_up}`}>Status</TextV>
                            </td>
                        </tr>

                        {data.map((el, i) => (
                            <tr className={cl.row} key={i}>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {currentUser.username}
                                    </TextV>
                                </td>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {getFormatedPrice(el.amount)}
                                    </TextV>
                                </td>
                                <td>
                                    <TextV className={cl.row_value} variant="subtitle">
                                        {cuteFormateDateWithTime(el.date)}
                                    </TextV>
                                </td>
                                <td>
                                    <TransactionStatus status={el.status} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <TextV className={cl.empty}>There's nothing here yet</TextV>
            )}
            {totalPages > 1 && (
                <FinPagination
                    count={totalPages}
                    curPage={currentPage}
                    onChangePage={onChangePage}
                    className={cl.pagination}
                />
            )}
        </>
    );
};

export default HistoryDeposits;
