import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminTransactions, getAdminUser, getMainInformation } from '../../api/admin';
import { AdminHistoryType, AdminUsers, WithPagination } from './types';

export const updateAdminMain = createAsyncThunk('admin/main', async () => {
    const res = await getMainInformation();
    if (!res.status) return null;
    return res.data;
});

export const setAdminUsers = createAsyncThunk<AdminUsers | null, { page: number; perPage: number }>(
    'admin/users',
    async (props) => {
        const res = await getAdminUser((props.page - 1) * props.perPage, props.perPage);

        if (!res.status) return null;
        return {
            page: props.page,
            total: res.data.total,
            data: res.data.users,
        } as AdminUsers;
    },
);

export const setAdminHistory = createAsyncThunk<
    {
        type: AdminHistoryType;
        content: WithPagination & { data: any };
    } | null,
    { page: number; perPage: number; type: AdminHistoryType }
>('admin/history', async (props) => {
    const res = await getAdminTransactions(
        props.type,
        (props.page - 1) * props.perPage,
        props.perPage,
    );

    if (!res.status) return null;
    return {
        type: props.type,
        content: {
            page: props.page,
            total: res.data.total,
            data: res.data.data,
        },
    };
});
