import { useRef, useState } from 'react';
import { WithClassName } from '../../../shared/types';
import GradientButton from '../../../shared/ui/Buttons/GradientButton';
import { CSSTransition } from 'react-transition-group';
import cl from './index.module.scss';
import TextV from '../../../shared/ui/TextV';

interface SocalMediaItemProps extends WithClassName {
    img: string;
    title: string;
    link: string;
}

const SocalMediaItem = ({ img, link, title, className = '' }: SocalMediaItemProps) => {
    const [isShowTip, setisShowTip] = useState(false);
    const tipRef = useRef(null);
    return (
        <a
            href={link}
            target="_blank"
            className={`${cl.item} ${className}`}
            onMouseEnter={() => setisShowTip(true)}
            onMouseLeave={() => setisShowTip(false)}>
            <GradientButton className={cl.button}>
                <img src={img} alt="" />
            </GradientButton>
            <CSSTransition
                in={isShowTip}
                nodeRef={tipRef}
                timeout={300}
                classNames="fade"
                mountOnEnter
                unmountOnExit>
                <div className={cl.tip_wrapper} ref={tipRef}>
                    <div className={cl.tip}>
                        <img src={img} alt="" />
                        <TextV variant="subtitle">{title}</TextV>
                    </div>
                </div>
            </CSSTransition>
        </a>
    );
};

export default SocalMediaItem;
