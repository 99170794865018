import SocalAuthes from '../../features/SocalAuthes';
import LoginForm from '../../widgets/LoginForm';
import cl from './index.module.scss';

const LoginPage = () => {
    return (
        <div className={cl.wrapper}>
            <LoginForm className={cl.form} />
            {/* <SocalAuthes className={cl.socal} /> */}
        </div>
    );
};

export default LoginPage;
