import TextV from '../../shared/ui/TextV';
import DashBoardPromo from '../../widgets/DashBoardPromo';
import UserBalance from '../../widgets/UserBalance';
import UserBlog from '../../widgets/UserBlog';
import UserEvents from '../../widgets/UserEvents';
import UserInfo from '../../widgets/UserInfo';
import UserReferals from '../../widgets/UserReferals';
import UserStats from '../../widgets/UserStats';
import cl from './index.module.scss';

const DashBoardPage = () => {
    return (
        <div className={cl.page}>
            <TextV className={cl.title} variant="h3" container="h1">
                Main
            </TextV>
            <div className={cl.board}>
                <div className={cl.users}>
                    <div className={cl.users_main}>
                        <UserInfo className={cl.info} />
                        <UserBalance className={cl.balance} />
                    </div>
                    <UserStats className={cl.stats} />
                </div>
                <div className={cl.struc_and_scroll}>
                    <UserReferals className={cl.refs} />
                    {/* <div className={cl.scroll}>
                        <UserBlog className={cl.blog} />
                        <UserEvents className={cl.events} />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default DashBoardPage;
