import NavigationMobileDefault from './NavigationMobileDefault';
import NavigationMobileLanding from './NavigationMobileLanding';

interface NavigationMobileProps {
    type?: 'default' | 'landing';
    onClick?: () => void;
}

const NavigationMobile = ({ onClick, type = 'default' }: NavigationMobileProps): JSX.Element => {
    if (type == 'default') return <NavigationMobileDefault onClick={onClick} />;
    if (type == 'landing') return <NavigationMobileDefault onClick={onClick} />;

    return <></>;
};

export default NavigationMobile;
