import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import TextV from '../../shared/ui/TextV';
import WidgetContainer from '../../shared/ui/WidgetContainer';
import { getFormatedPrice } from '../../shared/utils/price';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';

const UserBalance = ({ className = '' }: WithClassName): JSX.Element => {
    const navigate = useNavigate();

    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;

    return (
        <WidgetContainer className={className} title="Money">
            <div className={cl.widget}>
                <div className={cl.texts}>
                    <div className={cl.text}>
                        <TextV className={cl.key}>Balance</TextV>
                        <TextV className={cl.value} variant="subtitle">
                            {getFormatedPrice(currentUser.balance)}
                        </TextV>
                    </div>
                    <div className={cl.text}>
                        <TextV className={cl.key}>Profit</TextV>
                        <TextV className={cl.value} variant="subtitle">
                            {getFormatedPrice(currentUser.profit)}
                        </TextV>
                    </div>
                    <div className={cl.text}>
                        <TextV className={cl.key}>Invested</TextV>
                        <TextV className={cl.value} variant="subtitle">
                            {getFormatedPrice(currentUser.invest)}
                        </TextV>
                    </div>
                </div>
                <div className={cl.btns}>
                    <GradientButton onClick={() => navigate('/deposit')} className={cl.button}>
                        <TextV variant="small">Refill</TextV>
                    </GradientButton>
                    <GradientButton onClick={() => navigate('/withdraw')} className={cl.button}>
                        <TextV variant="small">Withdraw</TextV>
                    </GradientButton>
                </div>
            </div>
        </WidgetContainer>
    );
};

export default UserBalance;
