import { useNavigate } from 'react-router-dom';
import TabsSwitcher from '../../../features/TabsSwitcher/ui';
import TextV from '../../../shared/ui/TextV';
import cl from './index.module.scss';
import { useState } from 'react';
import WithDraw from '../../../widgets/transactions/WithDraw';

const tabs = [
    {
        label: 'Deposit',
        value: 'deposit',
    },
    {
        label: 'Withdraw',
        value: 'withdraw',
    },
];

const WithdrawPage = () => {
    const [activeTab, setActiveTab] = useState('withdraw');
    const navigate = useNavigate();

    const handleChangeTab = (v: string) => {
        setActiveTab(v);
        navigate(`/${v}`);
    };

    return (
        <div className="page">
            <TextV variant="h3" className={cl.title}>
                Transactions
            </TextV>
            <TabsSwitcher
                tabs={tabs}
                curentValue={activeTab}
                setValue={handleChangeTab}
                className={cl.tabs}
            />
            <WithDraw />
        </div>
    );
};

export default WithdrawPage;
