import { useId, useState } from 'react';
import TextV from '../TextV';
import { WithClassName } from '../../types';
import cl from './index.module.scss';

interface InputPropsMoney extends WithClassName {
    onChange?: (newValue: string) => void;
    startValue?: string;
    label?: string;
    error?: boolean;
    success?: boolean;
    readOnly?: boolean;
    small?: boolean;
}

const InputMoney = ({
    onChange,
    label,
    className = '',
    startValue = '',
    error = false,
    success = false,
    readOnly = false,
    small = false,
}: InputPropsMoney) => {
    const id = useId();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = Number(e.target.value.replace(/\D/g, '')).toLocaleString('ru');
        if (onChange) onChange(val);
    };

    return (
        <label
            htmlFor={id}
            className={`${cl.label} ${className} ${small ? cl.small : ''} ${
                error ? cl['label--error'] : ''
            } ${success ? cl['label--success'] : ''} ${readOnly ? cl.readonly : ''}`}>
            {label && (
                <TextV className={cl.text} variant="small">
                    {label}
                </TextV>
            )}
            <div className={cl.input_wrapper}>
                <input
                    type="text"
                    id={id}
                    value={startValue}
                    onChange={handleChange}
                    className={cl.input}
                    readOnly={readOnly}
                />
                <TextV variant={small ? 'small' : 'subtitle'} className={cl.input_sign}>
                    $
                </TextV>
            </div>
        </label>
    );
};

export default InputMoney;
