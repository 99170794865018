import WorldMap from '../../features/WorldMap/ui';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Info from '../../shared/ui/Info';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';

const MapWidget = () => {
    return (
        <div className={cl.widget}>
            <WorldMap></WorldMap>
        </div>
    );
};

export default MapWidget;
