import Team from '../../widgets/Team';
import cl from './index.module.scss';

const TeamPage = () => {
    return (
        <div className={cl.page}>
            <Team />
        </div>
    );
};

export default TeamPage;
