import { useNavigate } from 'react-router-dom';
import { WithClassName } from '../../shared/types';
import GradientButton from '../../shared/ui/Buttons/GradientButton';
import Line from '../../shared/ui/Line';
import TextV from '../../shared/ui/TextV';
import cl from './index.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../features/Session/model/selectors';

const ProfileReferals = ({ className = '' }: WithClassName) => {
    const navigate = useNavigate();
    const currentUser = useSelector(getCurrentUser);
    if (!currentUser) return <></>;
    return (
        <div className={`${className} ${cl.referal}`}>
            <TextV variant="subtitle" className={cl.title}>
                Referrals
            </TextV>
            <div className={cl.data}>
                <TextV className={cl.referal_title}>Referrals</TextV>
                <TextV className={cl.referal_value} variant="subtitle">
                    {currentUser.referrals}
                </TextV>
            </div>
            {/* <div className={cl.lines}>
                <Line title="Линия 1" value={`${currentUser.referrals[0]}`} className={cl.line} />
                <Line title="Линия 2" value={`${currentUser.referrals[1]}`} className={cl.line} />
                <Line title="Линия 3" value={`${currentUser.referrals[2]}`} className={cl.line} />
            </div> */}
            <GradientButton onClick={() => navigate('/team')} className={cl.btn}>
                <TextV variant="small">Read More</TextV>
            </GradientButton>
        </div>
    );
};

export default ProfileReferals;
