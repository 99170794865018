import { createAsyncThunk } from '@reduxjs/toolkit';
import { News } from './types';
import { fetchNews } from '../api';

export const setNews = createAsyncThunk<
    { total: number; offset: number; data: News[] } | null,
    { offset: number; count: number }
>('blog/news', async (props) => {
    const res = await fetchNews(props.offset, props.count);
    if (!res.status) return null;

    return {
        total: res.data.total as number,
        data: res.data.blog as News[],
        offset: props.offset as number,
    };
});
